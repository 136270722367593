/*

	JBT Styling specific to our application for consistency between the desktop app and the mobile app.

*/

// Core
@import "functions";
@import "mixins";
@import "variables";
@import "login";
@import "reboot";
@import "type";
@import "themes";
@import "buttons";
@import "slider";
//-- Angular Material
@import "mat/mat-card";
@import "mat/mat-dialog";
@import "mat/mat-drawer";
@import "mat/mat-form-field";
@import "mat/mat-sidenav";
@import "mat/mat-tab";
@import "mat/mat-table";
@import "mat/mat-toggle";
@import "mat/mat-expansion";
//-- cameras
@import "camera/camera-button";
@import "camera/camera-container";
@import "camera/camera-image";
//-- dashboard / widgets / gridster
@import "dashboard/gridster";
@import "dashboard/cdk";
@import "dashboard/widget-card";
//-- kendo UI
@import "kendoUI/kendoUI";
//-- File Upload
@import "file-upload/file-upload";
@import "file-upload/drop-area";
//-- asset-styles
@import "asset-styles";
//-- mobile-specific
@import "mobile-specific";
//-- toaster stying
@import "toaster";
//-- GSE styling
@import "gse";
//-- Card
@import "card";
//-- Summary Widget Styling
@import "summary/pca";
@import "summary/pbb";
@import "summary/gpu";
@import "summary/gse";
//-- Quick View Styling
@import "quick-view/pca";
@import "quick-view/pbb";
@import "quick-view/gpu";
