.tactical-widget-card-header {
    cursor: move;
    font-size: 0.9em;
    //height: 120px;
    min-height: 50px !important;
    padding: 2px;
    z-index: 5000;
}
.card-header.tactical-widget-card-header {
	padding: 5px;
}

.tactical-widget-card-header-short {
    cursor: move;
    font-size: 0.9em;
    height: 50px;
    min-height: 50px !important;
    padding: 8px;
    z-index: 5000;
}
.card-header.tactical-widget-card-header-short {
	padding: 5px;
}

.mat-card.card-top {
	background-color: rgba(0, 0, 0, 0.1);
	margin-left: 10px;
	margin-right: 10px;
	padding: 0px;
}


.card-wide-tile-lg {
	background-color: rgba(0, 0, 0, 0.1);
	height: 200px;
	width: 250px;
	margin: 5px;
}

.card-wide-tile-md.mat-card {
	background-color: rgba(0, 0, 0, 0.1);
	height: 200px;
	width: 250px;
	margin: 5px;
	padding: 3px;
}

.card-wide-tile-sm.mat-card {
	background-color: rgba(0, 0, 0, 0.1);
	height: 150px;
	width: 200px;
	margin: 2px;
	padding: 3px;
}


.card-wide-tile-xs.mat-card {
	background-color: rgba(0, 0, 0, 0.1);
	height: 100px;
	width: 150px;
	margin: 2px;
	padding: 3px;
}

.card-tile-lg {
	background-color: rgba(0, 0, 0, 0.1);
	height: 300px;
	width: 500px;
	margin: 10px;
}

.card-tile-md.mat-card {
	background-color: rgba(0, 0, 0, 0.1);
	height: 100px;
	width: 150px;
	margin: 5px;
	padding: 3px;
}

.card-tile-sm.mat-card  {
	background-color: rgba(0, 0, 0, 0.1);
	height: 100px;
	width: 150px;
	margin: 5px;
	padding: 3px;
}

.card-tile-xs.mat-card  {
	background-color: rgba(0, 0, 0, 0.1);
	height: 75px;
	width: 100px;
	margin: 2px;
	padding: 3px;
}

.card-tile-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.grid-card-container-center {
	display: grid;
	grid-template-areas:
	'title'
	'subtitle'
	'footer';
	gap: 2px;
  }

  .grid-card-title-center-xl {grid-area: title; text-align: center; align-items: center; justify-content: center; font-weight: 800; font-size: 28px; color:lightseagreen;}
  .grid-card-title-center-lg {grid-area: title; text-align: center; align-items: center; justify-content: center; font-weight: 800; font-size: 24px; color:lightseagreen;}
  .grid-card-title-center-md {grid-area: title; text-align: center; align-items: center; justify-content: center; font-weight: 800; font-size: 20px; color:lightseagreen;}
  .grid-card-title-center-sm {grid-area: title; text-align: center; align-items: center; justify-content: center; font-weight: 800; font-size: 16px; color:lightseagreen;}
  .grid-card-title-center-xs {grid-area: title; text-align: center; align-items: center; justify-content: center; font-weight: 800; font-size: 14px; color:lightseagreen;}
  .grid-card-title-center {grid-area: title; text-align: center; align-items: center; justify-content: center; font-weight: 800; font-size: 24px; color:lightseagreen;}
  .grid-card-subtitle-center {grid-area: subtitle; text-align: center; align-items: center; justify-content: center; font-size: x-small; color:white;}
  .grid-card-footer-center {grid-area: footer; text-align: center; align-items: center; justify-content: center; font-weight: 400; color:white;}


  .grid-wide-card-title-center-lg {grid-area: title; text-align: center; align-items: center; justify-content: center; font-weight: 800; font-size: 22px; color:lightseagreen;}
  .grid-wide-card-title-center-md {grid-area: title; text-align: center; align-items: center; justify-content: center; font-weight: 800; font-size: 20px; color:lightseagreen;}
  .grid-wide-card-title-center-sm {grid-area: title; text-align: center; align-items: center; justify-content: center; font-weight: 800; font-size: 16px; color:lightseagreen;}
  .grid-wide-card-title-center-xs {grid-area: title; text-align: center; align-items: center; justify-content: center; font-weight: 800; font-size: 14px; color:lightseagreen;}

.grid-container-top {
	display: grid;
	grid-template-areas:
	'one two three four five';
	gap: 2px;
  }

  .grid-title-1 {grid-area: one; text-align: center; align-items: center; justify-content: center; }
  .grid-title-2 {grid-area: two; text-align: center; align-items: center; justify-content: center; border-left: thin solid grey;}
  .grid-title-3 {grid-area: three; text-align: center; align-items: center; justify-content: center; border-left: thin solid grey;}
  .grid-title-4 {grid-area: four; text-align: center; align-items: center; justify-content: center; border-left: thin solid grey;}
  .grid-title-5 {grid-area: five; text-align: center; align-items: center; justify-content: center; border-left: thin solid grey;}

  span.grid-title { grid-area: text; text-align: center; align-items:flex-start;
    font-size: 18px;
	color:lightseagreen;
}

  span.grid-subtitle { grid-area: text; text-align: center; align-items:flex-start;
    font-size: 12px;
    font-weight: 400;
	color:white;
}


.grid-title-large-right {
	grid-area: summary;
	text-align: center;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	font-size: 50px;
	color:lightseagreen;
}

.grid-title-right {
	grid-area: summary;
	text-align: center;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	color:lightseagreen;
}

.grid-subtitle-right { grid-area: text; text-align: center; align-items:flex-start;
    font-size: 0.875rem;
    font-weight: 400;
	color:white;
}

span.card-tile-center-title-sm {
	text-align: center;
	align-items: center;
	justify-content: center;
	font-size: 50px;
	font-weight: 600;
	color:lightseagreen;
}

span.card-tile-center-subtitle-sm {
	text-align: center;
	align-items: center;
	justify-content: center;
    font-size: 20px;
    font-weight: 400;
	color:white;
}


span.grid-number-title-lg {
	font-weight: 600;
	font-size: 100px;
	color:lightseagreen;
}

span.grid-number-title-md {
	font-weight: 600;
	font-size: 50px;
	color:lightseagreen;
}

span.grid-number-title-sm {
	font-weight: 600;
	font-size: 30px;
	color:lightseagreen;
}


h4.title {
	text-align: center;
	font-weight: bold;
	margin: 5px;
}

kendo-chart.donut-lg {
	height: 130px;
}

kendo-chart.donut-md {
	height: 130px;
}

kendo-chart.donut-sm {
	height: 130px;
}

kendo-chart.donut-xs {
	height: 130px;
}

span.donut-center-text-lg {
	font-size: 24px;
	color: lightseagreen;
}

span.donut-center-text-md {
	font-size: 24px;
	color: lightseagreen;
}

span.donut-center-text-sm {
	font-size: 24px;
	color: lightseagreen;
}

span.donut-center-text-xs {
	font-size: 24px;
	color: lightseagreen;
}



.tactical-grid-container {
	display: grid;
	grid-template-areas:
	'left left right'
	'left left middle'
	'footer footer footer';
	gap: 2px;
  }


  .tactical-card-left.mat-card {
	background-color: rgba(0, 0, 0, 0.1);
	grid-area: left;
	text-align: center;
	align-items: center;
	justify-content: center;
	padding: 3px;
}
  .tactical-card-right.mat-card {
	background-color: rgba(0, 0, 0, 0.1);
	grid-area: right;
	text-align: center;
	align-items: center;
	justify-content: center;
	padding: 3px;
}
  .tactical-card-middle.mat-card {
	background-color: rgba(0, 0, 0, 0.1);
	grid-area: middle;
	text-align: center;
	align-items: center;
	justify-content: center;
	padding: 3px;
 }
  .tactical-card-footer.mat-card {
	background-color: rgba(0, 0, 0, 0.1);
	grid-area: footer;
	text-align: center;
	align-items: center;
	justify-content: center;
	padding: 3px;
}


.touranchor--is-active {
	position: relative;
	background: lightblue; //for highlighting inline elements
	z-index: 1001; //optional
}

.material-symbols-outlined {
	display: inline;
	margin-left: 5px;
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}


.grid-container-top {
	display: grid;
	grid-template-areas:
	'one two three four five';
	gap: 2px;
  }

  .grid-title-1 {grid-area: one; text-align: center; align-items: center; justify-content: center; }
  .grid-title-2 {grid-area: two; text-align: center; align-items: center; justify-content: center; border-left: thin solid grey;}
  .grid-title-3 {grid-area: three; text-align: center; align-items: center; justify-content: center; border-left: thin solid grey;}
  .grid-title-4 {grid-area: four; text-align: center; align-items: center; justify-content: center; border-left: thin solid grey;}
  .grid-title-5 {grid-area: five; text-align: center; align-items: center; justify-content: center; border-left: thin solid grey;}

  span.grid-title {
	grid-area: text;
	text-align: center;
	align-items:flex-start;
    font-size: 18px;
	color:lightseagreen;
}
span.grid-title-lg {
	grid-area: text;
	text-align: center;
	align-items:flex-start;
    font-size: 18px;
	color:lightseagreen;
}
span.grid-title-sm {
	grid-area: text;
	text-align: center;
	align-items:flex-start;
    font-size: 14px;
	color:lightseagreen;
}

span.grid-subtitle { grid-area: text; text-align: center; align-items:flex-start;
    font-size: 12px;
    font-weight: 400;
	//color: $body-color;
}

.grid-subtitle { grid-area: text; text-align: center; align-items:flex-start;
    font-size: 12px;
    font-weight: 400;
	//color: $body-color;
}

p {
	margin: 0px !important;
}

.grid-card-footer-center.p {
	margin: 0px !important;
}
