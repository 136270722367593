.version-name {
    font-size:large !important;
    color: white !important;
}

.version-description {
    font-style: italic !important;
    color: white !important;
    margin-bottom: 5px !important;
	p {
		display: inline;
	}
}


.environment-description {
    font-size: small !important;
    color:seagreen !important;
}

.white-content {
    .version-name, .version-description {
        background-color: white !important;
        color: black !important;
    }
}
