.asset {
    display: inline !important;
    text-align: center;
	cursor: pointer;
	padding: 5px !important;
	margin: 1px !important;
}

.out-of-service {
	display: inline !important;
    text-align: center;
	cursor: pointer;
	padding:0 5px !important;
	//min-width: 12px !important;
	margin: 1px !important;
}

.out-of-service-container {
	padding: 2px 0;
}

.asset-container {
	display: inline !important;
	text-align: center;
}


.unit-off {
background-color: darkgrey;
}

.unit-alarm {
	background-color: red;
}
.unit-on, .pca-unit-on-not-bridge-mode, .gpu-unit-on-and-switched-over {
	background-color:  limegreen;
}

.pca-unit-on-bridge-mode, .gpu-unit-on-and-not-switched-over {
	background-color:  mediumpurple;
}

.alarm-inactive {
	border: 2px solid lightsteelblue;
}

.alarm-active {
	border: 2px solid red;
}

.hmi-on {
	color: limegreen;
	font-family: 'Orbitron', sans-serif;
	font-weight: 900;
}

.hmi-off {
	color: darkgrey;
	font-family: 'Orbitron', sans-serif;
	font-weight: 900;
}

.hmi-gpu-unit-on-and-not-switched-over,
.hmi-pca-unit-on-bridge-mode {
	color: mediumpurple;
	font-family: 'Orbitron', sans-serif;
	font-weight: 900;
}

.hmi-alarm {
	color: red;
	font-family: 'Orbitron', sans-serif;
	font-weight: 800;
}


.white-content {
	.unit-off {
		background-color: lightgrey;
	}

	.unit-on, .pca-unit-on-not-bridge-mode, .gpu-unit-on-and-switched-over {
		background-color:  rgb(121, 255, 77);
	}

	.pca-unit-on-bridge-mode, .gpu-unit-on-and-not-switched-over {
		background-color:  #b3b3ff;
	}
}

