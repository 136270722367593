.widget-card-header {
    cursor: move;
    font-size:.9em;
    height:35px;
    min-height: 30px !important;
    padding: 5px;
    // z-index: 5000;
}

.widget-card-body {
    overflow-y: hidden;
    padding: 0;
    height: 100%;
  }

  .widget-card {
    height: 100%;
    overflow-y: hidden;
  }
