.company-logo {
    height: 30px;
    width: 30px;
}
.white-company-logo {
	background-image: $white-company-logo;
}

.color-company-logo {
	background-image: $color-company-logo;
}