gridster {
    //min-height: 1000px;
    //height: calc(100vh - 50px);
    // // width: 100vw;
    // overflow-x: auto;
    
    width: inherit !important;
    height: inherit !important;
    background: none !important;
    .gridster-column {
      border: 0px !important;
    }
    .gridster-row {
      border: 0px !important;
    }
  }
  
  // gridster-item {
  //   //background-color: rgb(39, 41, 61) !important;
  //   border-radius: 5px;
  // }
  
  .gridster-container {
    //height: calc(100vh - 50px);
    overflow: hidden;
  }
  
  
  gridster-item-inner {
    position: relative;
    //width: calc(100% - 5px);
    //height: calc(100% - 5px);
    margin: 5px;
  }
  
  // @media only screen and (min-device-width: 415px)
  // {

  //   gridster {
  //     min-height: 1000px;
  //     // // width: 100vw;
  //     // overflow-x: auto;
  //     background: none !important;
  //     .gridster-column {
  //       border: 0px !important;
  //     }
  //     .gridster-row {
  //       border: 0px !important;
  //     }
  //   }
  
  //   gridster-item-inner {
  //     position: relative;
  //     //width: calc(100vw - 5px);
  //     margin: 2px;
  //   }
  
  // }

  .no-access-to-widget {
    background-color: grey; 
    width: 100%; 
    height: 100%; 
    padding: 5px; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%); 
    cursor: no-drop
  }

  gridster-item{
      background:transparent !important;
    }
    
    // gridster-item.gridster-item-moving {
    //   cursor:move
    // }
    
    // gridster-item.gridster-item-moving,gridster-item.gridster-item-resizing {
    //   -webkit-transition:none;
    //   transition:none;
    //   z-index:2;
    //   box-shadow:0 0 5px 5px rgba(0,0,0,.2),0 6px 10px 0 rgba(0,0,0,.14),0 1px 18px 0 rgba(0,0,0,.12)
    // }
    
    // .gridster-item-resizable-handler
    // {
    //   position:absolute;
    //   z-index:2
    // }
    
    // .gridster-item-resizable-handler.handle-n
    // {
    //   cursor:ns-resize;
    //   height:10px;
    //   right:0;
    //   top:0;
    //   left:0
    // }
      
    // .gridster-item-resizable-handler.handle-e
    // {
    //   cursor:ew-resize;
    //   width:10px;
    //   bottom:0;
    //   right:0;
    //   top:0
	// }
	
	// .gridster-item-resizable-handler.handle-s
	// {
	// 	cursor:ns-resize;
	// 	height:10px;
	// 	right:0;
	// 	bottom:0;
	// 	left:0
	// }
	
	// .gridster-item-resizable-handler.handle-w
	// {
	// 	cursor:ew-resize;
	// 	width:10px;
	// 	left:0;
	// 	top:0;
	// 	bottom:0
	// }
	
	// .gridster-item-resizable-handler.handle-ne
	// {
	// 	cursor:ne-resize;
	// 	width:10px;
	// 	height:10px;
	// 	right:0;
	// 	top:0
	// }
	
	// .gridster-item-resizable-handler.handle-nw
	// {
	// 	cursor:nw-resize;
	// 	width:10px;
	// 	height:10px;
	// 	left:0;
	// 	top:0
	// }
	
	// .gridster-item-resizable-handler.handle-se
	// {
	// 	cursor:se-resize;
	// 	width:0;
	// 	height:0;
	// 	right:0;
	// 	bottom:0;
	// 	border-style:solid;
	// 	border-width:0 0 10px 10px;
	// 	border-color:transparent
	// }
	
	// .gridster-item-resizable-handler.handle-sw
	// {
	// 	cursor:sw-resize;
	// 	width:10px;
	// 	height:10px;
	// 	left:0;
	// 	bottom:0
	// }
	
	// gridster-item:hover .gridster-item-resizable-handler.handle-se
	// {
	// 	border-color:transparent transparent #ccc
	// }