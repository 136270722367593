.jbt-toaster-success {
    --background: #51A351;
    --color: white;
    --border-style:none;
}

.jbt-toaster-info {
    --background: #2F96B4;
    --color: white;
    --border-style:none;
}

.jbt-toaster-error {
    --background: #BD362F;
    --color: white;
    --border-style:none;
}

.jbt-toaster-warning {
    --background: #F89406;
    --color: white;
    --border-style:none;
}
