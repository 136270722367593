mat-drawer {
    // width: 50%;
    height: 100%;
    background: transparent !important;
}

mat-drawer-content {
    background: $card-black-background !important;
    height: 1000px;
    width: 100%;
}

.mat-list {
    background-color: transparent !important;
    text-align: center;
    margin: 0 !important;
    padding: 0 !important;
    // .mat-list-item-content {
    //   display: initial !important;
    //   padding: 0 !important;
    // }
 }

.mat-list-item {
    padding: 0 !important;
    margin: 0 !important;
    display:unset !important;
}

div.mat-list-item-content {
    background-color: transparent !important;
    //   display: initial !important;
    padding: 0 !important;
    margin: 0 !important;
    height: auto !important;
}

 .mat-list-base {
     padding: 0 !important;
 }

 .mat-list-item-padding {
     padding: 0 !important;
     margin: 0 !important;
 }
// .mat-drawer-inner-container {
//     margin-right: 2px !important;
// }

mat-sidenav {
	width: 220px;
	height: 100%;
	background: $card-black-background  !important;
}

mat-sidenav-content {
	background: $card-black-background  !important;
	height: 100%;
	width: 100%;
	padding-left: 220px;
}
