body .light-theme {
	.k-grid,
		.card-header,
		.card-body,
		.title,
		.form-control,
		label {
    //   background-color: #ffffff;
	color: #000;
      .ios {
        color: #000 !important;
      --ion-text-color: #000 !important;
      }

      /* Set text color of the entire app for Material Design only */
      .md {
          color: #222 !important;
        --ion-text-color: #222 !important;
      }
    }

		.mat-menu-content {
			background: #FFFFFF !important;
		}

		.mat-menu-item {
			color: rgba(0, 0, 0, .87)
		}
    ion-content, ion-tabs, ion-toolbar, ion-item, ion-list {
       /* Set the background of the entire app */
       --ion-background-color: #ffffff !important;
       --ion-toolbar-background: #ffffff !important;

        .ios {
            color: #000 !important;
          --ion-text-color: #000 !important;
          }

          /* Set text color of the entire app for Material Design only */
          .md {
              color: #222 !important;
            --ion-text-color: #222 !important;
          }
    }

    ion-icon {
      .ios {
        color: #000 !important;
        --ion-text-color: #000 !important;
      }

      /* Set text color of the entire app for Material Design only */
      .md {
        color: #222 !important;
        --ion-text-color: #222 !important;
      }
    }

    .mat-dialog-container {
        padding: 0px !important;
        background: #ffffff !important;

      .ios {
            color: #000 !important;
          --ion-text-color: #000 !important;
          }

          /* Set text color of the entire app for Material Design only */
      .md {
              color: #222 !important;
            --ion-text-color: #222 !important;
          }
    }

    .card {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        color: #222;
    }

    .fas, .tim-icons {
      color: #222;
    }
  }
}

body .dark-theme {
	.k-grid,
		.card-header,
		.card-body,
		.title,
		.tim-icons,
		.form-control,
		label {
      background-color: #000000 !important;
	color: #fff;

      .ios {
        color: #fff !important;
      --ion-text-color: #fff !important;
      }

      /* Set text color of the entire app for Material Design only */
      .md {
          color: #eee !important;
        --ion-text-color: #eee !important;
      }
    }

.mat-menu-content {
	background: #333333 !important;
}

.mat-menu-item {
	color: #FFFFFF !important;
}
    ion-content, ion-tabs, ion-toolbar, ion-item, ion-list {
      /* Set the background of the entire app */
      --ion-background-color: #000000 !important;
      --ion-toolbar-background: #000000 !important;

        .ios {
            --ion-text-color: #fff !important;
            color: #fff !important;
          }

          /* Set text color of the entire app for Material Design only */
          .md {
            --ion-text-color: #eee !important;
              color: #eee !important;
          }
    }

    .widget-card-header {
      --ion-background-color: #c0c0c0 !important;
      background-color: #202020 !important;
    }

    ion-icon {
      .ios {
        color: #fff !important;
        --ion-text-color: #fff !important;
      }

      /* Set text color of the entire app for Material Design only */
      .md {
        color: #eee !important;
        --ion-text-color: #eee !important;
      }
    }

    .mat-dialog-container {
      padding: 0px !important;
      --ion-background-color: #000000 !important;
      .ios {
            color: #fff;
          --ion-text-color: #fff;
          }

          /* Set text color of the entire app for Material Design only */
          .md {
              color: #eee;
            --ion-text-color: #eee;
        }

    }

    .card {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
          color: $opacity-8;
      }

      .fas, .tim-icons {
        color: $opacity-8;
      }
    }
}

.toolbar-title {
  font-size: 14px !important;
}

.day   { background: #eee; color: black; }
.night { background: #333; color: white; }

@media (prefers-color-scheme: dark) {
  :root {
    .day.dark-scheme   { background:  #333; color: white; }
    .night.dark-scheme { background: black; color:  #ddd; }
  }
}

@media (prefers-color-scheme: light) {
  .day.light-scheme   { background: white; color:  #555; }
  .night.light-scheme { background:  #eee; color: black; }
}

