.mat-form-field {
  .mat-input-element {
    color: rgba(255, 255, 255, 0.8);
  }
  .mat-form-field-label {
    color: rgba(255, 255, 255, 0.8);
  }
  .mat-form-field-underline {
    background-color: rgba(255, 255, 255, 0.8);
  }
  .mat-form-field-ripple {
    background-color: rgba(255, 255, 255, 0.8);
  }
  .mat-form-field-required-marker {
    color: rgba(255, 255, 255, 0.8);
  }
}

.mat-form-field.mat-focused {
  .mat-form-field-label {
    color: rgba(255, 255, 255, 0.8);
  }
  .mat-form-field-ripple {
    background-color: rgba(255, 255, 255, 0.8);
  }
  .mat-form-field-required-marker {
    color: rgba(255, 255, 255, 0.8);
  }
  .mat-input-element {
    color: rgba(255, 255, 255, 0.8);
  }
}

.mat-focused .placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.mat-select-value-text {
  color: rgba(255, 255, 255, 0.8);
}

.mat-select-panel {
  background-color: #27304c;
}
.mat-select-arrow {
  color: rgba(255, 255, 255, 0.8);
}

.mat-option {
  color: rgba(255, 255, 255, 0.8);
}

.mat-form-field .mat-form-field-infix {
  // padding-top: 0px !important;
  // border-top: 0px !important;
  // border-top-width: 0px !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
//   padding-top: 5px !important;
  background-color: #27304c;
}
.mat-form-field-wrapper {
//   padding-bottom: 0px !important;
}

.mat-form-field-underline {
  display: none;
}

.mat-form-field .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.8);
}

.white-content {
  .mat-focused .placeholder {
    color: rgba(26, 5, 5, 0.8);
  }

  .mat-select-panel {
    background-color: rgb(255, 255, 255);
  }
  .mat-select-arrow {
    color: rgba(0, 0, 0, 0.54);
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    // padding-top: 5px !important;
    background-color: rgb(255, 255, 255);
  }

  .mat-option {
    color: rgba(0, 0, 0, 0.54);
  }

  .mat-select-value-text {
    color: rgba(26, 5, 5, 0.8);
  }

  .placeholder {
    color: rgba(26, 5, 5, 0.8);
  }

  .mat-form-field .mat-form-field-underline {
    color: rgba(26, 5, 5, 0.8);
  }
  .mat-form-field {
    .mat-input-element {
      color: rgba(26, 5, 5, 0.8);
    }
    .mat-form-field-label {
      color: rgba(26, 5, 5, 0.8);
    }
    .mat-form-field-underline {
      background-color: rgba(26, 5, 5, 0.8);
    }
    .mat-form-field-ripple {
      background-color: rgba(26, 5, 5, 0.8);
    }
    .mat-form-field-required-marker {
      color: rgba(26, 5, 5, 0.8);
    }
  }

  .mat-form-field.mat-focused {
    .mat-form-field-label {
      color: rgba(26, 5, 5, 0.8);
    }
    .mat-form-field-ripple {
      background-color: rgba(26, 5, 5, 0.8);
    }
    .mat-form-field-required-marker {
      color: rgba(26, 5, 5, 0.8);
    }
    .mat-input-element {
      color: rgba(26, 5, 5, 0.8);
    }
  }
}
