mat-dialog-container {
	background: $card-black-background;
}

.mat-dialog-container {
	padding: 0px !important;
	background: unset !important;
}

.no-overflow>mat-dialog-container {
	overflow: hidden !important;
}

.mat-dialog {
	transition: none !important;
}

.close-icon {
	float: right !important;
	padding: 0px !important;
	margin-right: 20px !important;
	margin-left: 20px !important;
}


.white-content{
    .mat-menu-content{
        background: #FFFFFF;
    }
    .mat-menu-item{
        color: rgba(0,0,0,.87)
    }
}
.mat-menu-content{
    background: #616161e6;
}
.mat-menu-item{
    color: #FFFFFF
}
