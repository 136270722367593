.card {
	overflow: hidden;
}
.white-content {
	.jumbotron {
		background-color: #e9ecef !important;
	}
}

.jumbotron {
	background-color: #27304c !important;
}

.card-body:not(.universal-reporting, .user-settings, .user-issues, .file-upload, .login-form) {
	height: calc(100% - 36px);
	padding: 0px !important;
	padding-bottom: 10px !important;
}

.card-header:not(.login-form, .user-settings) {
	//-- only add padding at the bottom of the card header and a border at the bottom of the card header when it's not the login form. --Kirk T. Sherer, April 15, 2020.
	padding-bottom: 0.9px !important;
}

.user-settings {
	padding-bottom: 8px !important;
}

.card-header:not(.login-form) {
	background-color: lighten($black, 3%) !important;
	color: white !important;
}
.card-header:not(.login-form) .white-content h1,
.white-content h2,
.white-content h3,
.white-content h4,
.white-content h5,
.white-content h6,
.white-content p,
.white-content ol li,
.white-content ul li,
.white-content pre {
	color: lighten($black, 3%) !important;
}

.white-content {
	.card-header:not(.login-form) {
		//-- only add padding at the bottom of the card header and a border at the bottom of the card header when it's not the login form. --Kirk T. Sherer, April 15, 2020.
		//border-bottom: 0.1rem #4f4f4f solid !important;
		background-color: darken($white, 6%) !important;
		color: black !important;
	}
}

.col-form-label {
	text-align: right !important;
}

.card-subtitle {
	font-size: 0.7rem !important;
	padding-bottom: 7px !important;
	text-transform: unset !important;
}

.menu li:hover {
	background: grey;
}

.white-content .menu li:hover {
	background: rgb(165, 165, 165);
}



.white-content .mat-stepper-vertical, .mat-stepper-horizontal {
	background-color: white;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
	background-color: #27304c;
}

.white-content .mat-step-header .mat-step-label {
	color: rgba(0,0,0,.87) !important;
}

.mat-step-header .mat-step-label {
	color: white !important;
}

.white-content .mat-table{
	background-color: rgb(255,255,255);
}

.mat-table{
	background-color: #27304c ;
}

.white-content .mat-header-cell{
	color: rgba(0,0,0,.54)
}

.mat-header-cell{
	color: white;
}

.white-content .mat-cell, mat-footer-cell{
	color: rgba(0,0,0,.87)
}

.mat-cell, mat-footer-cell{
	color: white;
}



