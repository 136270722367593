textarea.form-control {
    max-height: 500px !important;
}
.mat-form-field-infix {
    border-top: unset !important;
}

.mat-autocomplete-panel.mat-autocomplete-visible {
    position: relative !important;
}
bs-datepicker-container,
bs-daterangepicker-container {
    z-index: 99999 !important;
}

.dynamic-form {
    line-height: 0.5 !important;
    overflow-y : none !important;
}