.k-theme-test-class,
.ktb-theme-id-default {
	opacity: 0;
}
.ktb-var-accent {
	color: #7bd2f6;
}
.ktb-var-base {
	color: #daecf4;
}
.ktb-var-background {
	color: #fff;
}
.ktb-var-border-radius {
	border-radius: 4px;
}
.ktb-var-normal-background {
	color: #fff;
}
.ktb-var-normal-gradient {
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
}
.ktb-var-normal-text-color {
	color: #003f59;
}
.ktb-var-hover-background {
	color: #7bd2f6;
}
.ktb-var-hover-gradient {
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
}
.ktb-var-hover-text-color {
	color: #003f59;
}
.ktb-var-selected-background {
	color: #13688c;
}
.ktb-var-selected-gradient {
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0, rgba(255, 255, 255, 0) 100%);
}
.ktb-var-selected-text-color {
	color: #fff;
}
.ktb-var-error {
	color: #db4240;
}
.ktb-var-warning {
	color: #ffb400;
}
.ktb-var-success {
	color: #37b400;
}
.ktb-var-info {
	color: #06c;
}
.ktb-var-series-a {
	color: #0069a5;
}
.ktb-var-series-b {
	color: #0098ee;
}
.ktb-var-series-c {
	color: #7bd2f6;
}
.ktb-var-series-d {
	color: #ffb800;
}
.ktb-var-series-e {
	color: #ff8517;
}
.ktb-var-series-f {
	color: #e34a00;
}
.k-grid-norecords-template {
	background-color: #fff;
	border: 1px solid #bbdceb;
}
.k-button {
	border-radius: 4px;
	border-color: #93c8df;
	color: #003f59;
	background-color: #daecf4;
	background-position: 50% 50%;
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
}
.k-button.k-state-default {
	border-color: #93c8df;
}
.k-button.k-state-hover,
.k-button:hover {
	color: #003f59;
	border-color: #8bc4dd;
	background-color: #55c5f3;
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
}
.k-button.k-state-active,
.k-button:active {
	color: #fff;
	background-color: #126183;
	border-color: #126183;
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0, rgba(255, 255, 255, 0) 100%);
}
.k-button.k-state-active:hover {
	color: #003f59;
	border-color: #77c9eb;
	background-color: #63caf4;
}
.k-button:focus:active {
	box-shadow: 0 0 2px 1px #7bd2f6;
}
.k-button.k-state-focused,
.k-button.k-state-focused.k-state-disabled,
.k-button:focus,
.k-button:focus:hover,
.k-state-disabled .k-button.k-state-focused {
	border-color: #68cbf5;
	box-shadow: 0 0 2px 1px #7bd2f6;
}
.k-button.k-state-disabled,
.k-button.k-state-disabled:active,
.k-button.k-state-disabled:focus,
.k-button.k-state-disabled:hover,
.k-button[disabled],
.k-button[disabled]:active,
.k-button[disabled]:focus,
.k-button[disabled]:hover,
.k-state-disabled .k-button,
.k-state-disabled .k-button:active,
.k-state-disabled .k-button:focus,
.k-state-disabled .k-button:hover {
	color: #003f59;
	border-color: #93c8df;
	background-color: #daecf4;
	box-shadow: none;
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
}
.k-primary {
	color: #003f59;
	border-color: #77c9eb;
	background-color: #7bd2f6;
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
}
.k-primary.k-state-default {
	border-color: #77c9eb;
}
.k-primary.k-state-hover,
.k-primary:hover {
	color: #003f59;
	border-color: #77c9eb;
	background-color: #63caf4;
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
}
.k-primary.k-state-active,
.k-primary:active {
	color: #fff;
	border-color: #126183;
	background-color: #126183;
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0, rgba(255, 255, 255, 0) 100%);
}
.k-primary:focus:active:not(.k-state-disabled):not([disabled]) {
	box-shadow: 0 0 2px 1px #13688c;
}
.k-primary.k-state-focused,
.k-primary.k-state-focused.k-state-disabled,
.k-primary:focus,
.k-primary:focus:hover,
.k-state-disabled .k-primary.k-state-focused {
	border-color: #5ac7f4;
	box-shadow: 0 0 2px 1px #13688c;
}
.k-primary.k-state-disabled,
.k-primary.k-state-disabled:hover,
.k-primary[disabled],
.k-primary[disabled]:hover,
.k-state-disabled .k-primary,
.k-state-disabled .k-primary:hover {
	color: #003f59;
	border-color: #77c9eb;
	background-color: #7bd2f6;
	box-shadow: none;
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
}
.k-button-group {
	border-radius: 4px;
}
.k-button-group .k-button {
	border-radius: 0;
}
.k-button-group .k-button:first-child,
.k-button-group .k-group-start {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}
.k-button-group .k-button:last-child,
.k-button-group .k-group-end {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}
.k-button-group .k-button:first-child:last-child,
.k-button-group .k-group-start.k-group-end {
	border-radius: 4px;
}
.k-rtl .k-button-group .k-button {
	border-radius: 0;
}
.k-rtl .k-button-group .k-button:first-child,
.k-rtl .k-button-group .k-group-start {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}
.k-rtl .k-button-group .k-button:last-child,
.k-rtl .k-button-group .k-group-end {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}
.k-rtl .k-button-group .k-button:first-child:last-child,
.k-rtl .k-button-group .k-group-start.k-group-end {
	border-radius: 4px;
}
.k-split-button {
	border-radius: 4px;
}
.k-split-button.k-state-border-down > .k-button,
.k-split-button.k-state-border-up > .k-button {
	color: #003f59;
	background-color: #55c5f3;
	border-color: #8bc4dd;
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
	box-shadow: none;
}
.k-split-button:focus {
	border-color: #68cbf5;
	outline: 0;
	box-shadow: 0 0 2px 1px #7bd2f6;
}
.k-split-button:focus > .k-button {
	background: 0 0;
	border-color: #68cbf5;
}
.k-split-button:focus:not(.k-state-disabled) > .k-button:hover,
.k-split-button:focus:not(.k-state-disabled) > .k-state-active {
	color: #003f59;
	background-color: #55c5f3;
	border-color: #68cbf5;
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
	box-shadow: none;
}
.k-split-button.k-state-disabled {
	color: #9fcee2;
	background: #daecf4;
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
}
.k-edit-buttons {
	border-color: #bbdceb;
	// background: #d9ecf5;
}
.k-button.k-bare.k-primary,
.k-button.k-bare.k-primary.k-state-active,
.k-button.k-bare.k-primary.k-state-hover,
.k-button.k-bare.k-primary:active,
.k-button.k-bare.k-primary:hover,
.k-button.k-bare.k-primary:hover.k-state-active,
.k-button.k-bare.k-primary:hover:active,
.k-button.k-flat.k-primary,
.k-button.k-flat.k-primary.k-state-active,
.k-button.k-flat.k-primary.k-state-hover,
.k-button.k-flat.k-primary:active,
.k-button.k-flat.k-primary:hover,
.k-button.k-flat.k-primary:hover.k-state-active,
.k-button.k-flat.k-primary:hover:active {
	color: #7bd2f6;
}
.k-card {
	border-color: #bbdceb;
	color: #003f59;
	background-color: #fff;
}
.k-card .k-card-callout {
	border-color: #bbdceb;
	background-color: #fff;
}
.k-card-header {
	border-color: #bbdceb;
	color: #00435e;
	// background-color: #d9ecf5;
}
.k-card-footer {
	border-color: #bbdceb;
	color: #00435e;
	// background-color: #d9ecf5;
}
.k-card-separator {
	border-color: #bbdceb;
}
.k-card-actions {
	border-color: #bbdceb;
}
.k-card.k-card-primary,
.k-card.k-state-primary {
	border-color: #bde9fb;
	color: #3e697b;
	background-color: #d7f2fc;
}
.k-card.k-card-info,
.k-card.k-state-info {
	border-color: #80b3e6;
	color: #036;
	background-color: #b3d1f0;
}
.k-card.k-card-success,
.k-card.k-state-success {
	border-color: #9bda80;
	color: #1c5a00;
	background-color: #c3e9b3;
}
.k-card.k-card-warning,
.k-card.k-state-warning {
	border-color: #ffda80;
	color: #805a00;
	background-color: #ffe9b3;
}
.k-card.k-card-error,
.k-card.k-state-error {
	border-color: #eda1a0;
	color: #6e2120;
	background-color: #f4c6c6;
}
.k-chat {
	border-color: #bbdceb;
	color: #003f59;
	background-color: #fff;
}
.k-chat .k-timestamp {
	text-transform: uppercase;
	opacity: 0.7;
}
.k-chat .k-author {
	font-weight: 700;
}
.k-chat .k-bubble {
	border-color: #daecf4;
	color: #003f59;
	background-color: #daecf4;
	transition: box-shadow 0.2s ease-in-out;
	-ms-flex-order: -1;
	order: -1;
}
.k-chat .k-alt .k-bubble {
	border-color: #7bd2f6;
	color: #000;
	background-color: #7bd2f6;
}
.k-chat .k-quick-reply {
	border-color: #7bd2f6;
	color: #7bd2f6;
	background-color: transparent;
}
.k-chat .k-quick-reply:hover {
	border-color: #7bd2f6;
	color: #000;
	background-color: #7bd2f6;
}
.k-chat .k-message-box {
	border-color: inherit;
	color: #003f59;
	background-color: #fff;
}
.k-chat .k-message-box .k-button:hover {
	color: #7bd2f6;
}
.k-chat .k-toolbar-box {
	border-color: inherit;
	color: #003f59;
	// background-color: #d9ecf5;
}
.k-chat .k-toolbar-box .k-button:hover {
	color: #7bd2f6;
	background: 0 0;
}
.k-chat .k-toolbar-box .k-scroll-button {
	background-color: #fff;
	box-shadow: 0 0 20px 5px #fff;
}
.k-chat .k-toolbar-box .k-scroll-button:hover {
	background-color: #fff;
}
.k-calendar {
	border-color: #bbdceb;
	color: #003f59;
	background-color: #fff;
}
.k-calendar > .k-header {
	border-color: #bbdceb;
	color: #00435e;
	// background-color: #d9ecf5;
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
}
.k-calendar th {
	border-color: #bbdceb;
	color: #003f59;
	background-color: #f5f5f5;
}
.k-calendar .k-alt {
	border-color: #bbdceb;
	color: #003f59;
	background-color: #f5f5f5;
}
.k-calendar .k-weekend {
	background-color: transparent;
}
.k-calendar .k-other-month {
	color: #9fcee2;
	background-color: transparent;
}
.k-calendar .k-out-of-range {
	pointer-events: none;
	visibility: hidden;
}
.k-calendar .k-today .k-link {
	color: #7bd2f6;
	box-shadow: inset 0 0 0 1px #7bd2f6;
}
.k-calendar td.k-state-hover .k-link {
	border-color: #8bc4dd;
	color: #003f59;
	background-color: #55c5f3;
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
}
.k-calendar td.k-state-selected .k-link {
	border-color: #146b90;
	color: #fff;
	background-color: #13688c;
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0, rgba(255, 255, 255, 0) 100%);
}
.k-calendar td.k-state-focused .k-link {
	box-shadow: inset 0 0 2px 1px #7bd2f6;
}
.k-calendar .k-footer .k-nav-today {
	color: #7bd2f6;
}
.k-calendar .k-footer .k-nav-today:hover {
	color: #68cbf5;
}
.k-calendar-range {
	border-color: #bbdceb;
	color: #003f59;
	background-color: #fff;
}
.k-calendar-range th {
	color: inherit;
	background-color: transparent;
	text-transform: uppercase;
}
.k-calendar-range td.k-state-hover .k-link {
	border-color: #8bc4dd;
	color: #003f59;
	background-color: #55c5f3;
}
.k-calendar-range td.k-state-selected .k-link {
	border-color: #146b90;
	color: #fff;
	background-color: #13688c;
}
.k-calendar-range td.k-state-focused .k-link {
	box-shadow: inset 0 0 2px 1px #7bd2f6;
}
.k-calendar-range td.k-range-end,
.k-calendar-range td.k-range-mid,
.k-calendar-range td.k-range-start {
	background-image: linear-gradient(transparent 1px, rgba(19, 104, 140, 0.3) 1px, rgba(19, 104, 140, 0.3) calc(100% - 1px), transparent calc(100% - 1px));
}
.k-edge .k-calendar-range td.k-range-end,
.k-edge .k-calendar-range td.k-range-mid,
.k-edge .k-calendar-range td.k-range-start,
.k-ie .k-calendar-range td.k-range-end,
.k-ie .k-calendar-range td.k-range-mid,
.k-ie .k-calendar-range td.k-range-start {
	background-image: none;
	background-color: rgba(19, 104, 140, 0.3);
}
.k-calendar-range td.k-range-split-start::after {
	background-image: linear-gradient(to left, rgba(19, 104, 140, 0.3), transparent);
}
.k-calendar-range td.k-range-split-end::after {
	background-image: linear-gradient(to right, rgba(19, 104, 140, 0.3), transparent);
}
.k-calendar-range .k-footer .k-nav-today {
	color: #7bd2f6;
	background: 0 0;
}
.k-pdf-viewer .k-toolbar .k-icon,
.k-pdf-viewer .k-toolbar .k-pager-input {
	color: #003f59;
}
.k-pdf-viewer .k-canvas {
	background-color: #fff;
}
.k-pdf-viewer .k-page {
	border-color: #bbdceb;
	color: #003f59;
	background-color: #fff;
	box-shadow: 0 0 15px #bbdceb;
}
.k-pdf-viewer .k-search-highlight {
	background-color: #4d4d4d;
}
.k-scrollview-nav > li.k-link {
	border-color: #93c8df;
	background-color: #daecf4;
	background-clip: padding-box;
}
.k-scrollview-nav > li.k-link.k-primary {
	border-color: #77c9eb;
	background-color: #7bd2f6;
}
.k-scrollview-elements {
	color: #fff;
}
.k-scrollview-next,
.k-scrollview-prev {
	color: inherit;
	background: rgba(0, 0, 0, 0);
	text-shadow: rgba(0, 0, 0, 0.3) 0 0 15px;
	opacity: 0.7;
	outline-width: 0;
	-webkit-tap-highlight-color: transparent;
}
.k-scrollview-next:hover,
.k-scrollview-prev:hover {
	color: #fff;
	opacity: 1;
}
.k-scrollview-next:hover span::before,
.k-scrollview-prev:hover span::before {
	background-color: transparent;
}
.k-switch-handle {
	background-clip: border-box;
}
.k-switch-on .k-switch-container {
	border-color: #93c8df;
	color: #7bd2f6;
	background-color: #fff;
}
.k-switch-on .k-switch-handle {
	border-color: #77c9eb;
	color: #7bd2f6;
	background-color: #7bd2f6;
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.25) 0, rgba(255, 255, 255, 0) 100%);
}
.k-switch-on.k-state-focused,
.k-switch-on:focus {
	box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
}
.k-switch-on.k-state-focused .k-switch-container,
.k-switch-on:focus .k-switch-container {
	border-color: #58a9cd;
	color: #7bd2f6;
	background-color: #fff;
}
.k-switch-on.k-state-focused .k-switch-handle,
.k-switch-on:focus .k-switch-handle {
	border-color: #77c9eb;
	color: #7bd2f6;
	background-color: #7bd2f6;
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.25) 0, rgba(255, 255, 255, 0) 100%);
}
.k-switch-on.k-state-hover .k-switch-container,
.k-switch-on:hover .k-switch-container {
	border-color: #58a9cd;
	color: #7bd2f6;
	background-color: #fff;
}
.k-switch-on.k-state-hover .k-switch-handle,
.k-switch-on:hover .k-switch-handle {
	border-color: #77c9eb;
	color: #7bd2f6;
	background-color: #7bd2f6;
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
}
.k-switch-on .k-switch-label-off {
	color: transparent;
}
.k-switch-off .k-switch-container {
	border-color: #93c8df;
	color: #003f59;
	background-color: #fff;
}
.k-switch-off .k-switch-handle {
	border-color: #93c8df;
	color: #003f59;
	background-color: #daecf4;
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
}
.k-switch-off.k-state-focused,
.k-switch-off:focus {
	box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
}
.k-switch-off.k-state-focused .k-switch-container,
.k-switch-off:focus .k-switch-container {
	border-color: #58a9cd;
	color: #003f59;
	background-color: #fff;
}
.k-switch-off.k-state-focused .k-switch-handle,
.k-switch-off:focus .k-switch-handle {
	border-color: #58a9cd;
	color: #003f59;
	background-color: #daecf4;
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
}
.k-switch-off.k-state-hover .k-switch-container,
.k-switch-off:hover .k-switch-container {
	border-color: #58a9cd;
	color: #003f59;
	background-color: #fff;
}
.k-switch-off.k-state-hover .k-switch-handle,
.k-switch-off:hover .k-switch-handle {
	border-color: #58a9cd;
	color: #003f59;
	background-color: #55c5f3;
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
}
.k-switch-off .k-switch-label-on {
	color: transparent;
}
.k-switch {
	background-color: transparent;
	box-shadow: none;
}
.k-switch.k-state-focused .k-switch-container,
.k-switch:focus .k-switch-container {
	outline: 0;
}
.k-switch.k-state-disabled {
	cursor: default;
}
.k-switch[aria-readonly="true"] {
	pointer-events: none;
}
.k-pane-wrapper .k-pane > .k-view > .k-content {
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
	background-position: 50% 50%;
	// background-color: #d9ecf5;
}
.k-pane-wrapper .k-pane .k-grid-mobile .k-column-active + th.k-header {
	border-left-color: #003f59;
}
.k-pane-wrapper .k-pane .k-grid-mobile .k-sorted .k-i-sort-asc-sm,
.k-pane-wrapper .k-pane .k-grid-mobile .k-sorted .k-i-sort-desc-sm,
.k-pane-wrapper .k-pane .k-grid-mobile .k-sorted .k-sort-order {
	color: #7bd2f6;
}
.k-pane-wrapper .k-pane .k-grid-mobile .k-grid-filter.k-state-active,
.k-pane-wrapper .k-pane .k-grid-mobile .k-header-column-menu.k-state-active {
	color: #fff;
	background-color: #7bd2f6;
}
.k-pane-wrapper .k-pane .k-grid-mobile .k-resize-handle-inner::before {
	background-color: #fff;
}
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-nav-current {
	color: #7bd2f6;
}
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-mobile-scheduler-agendadate,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-groupcolumn,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-header,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-times {
	color: inherit;
}
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-mobile-scheduler-agendaweekday,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-time-period {
	color: #73d6ff;
}
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-datecolumn,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-group-cell,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-groupcolumn,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-header,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-header-all-day,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-header-wrap,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-times {
	// background-color: #d9ecf5;
}
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-other-month {
	background-color: #e6f2f8;
}
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-toolbar .k-scheduler-navigation,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-toolbar .k-scheduler-tools > li {
	background-color: inherit;
}
.k-pane-wrapper .k-pane .k-grid-column-menu .k-header,
.k-pane-wrapper .k-pane .k-grid-edit-form .k-header,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-header,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-header {
	background-color: #7bd2f6;
}
.k-pane-wrapper .k-pane .k-grid-column-menu .k-header,
.k-pane-wrapper .k-pane .k-grid-column-menu .k-header .k-link,
.k-pane-wrapper .k-pane .k-grid-edit-form .k-header,
.k-pane-wrapper .k-pane .k-grid-edit-form .k-header .k-link,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-header,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-header .k-link,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-header,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-header .k-link {
	color: #fff;
}
.k-pane-wrapper .k-pane .k-grid-column-menu .k-item,
.k-pane-wrapper .k-pane .k-grid-column-menu .k-link,
.k-pane-wrapper .k-pane .k-grid-edit-form .k-item,
.k-pane-wrapper .k-pane .k-grid-edit-form .k-link,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-item,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-link,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-item,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-link {
	color: #003f59;
}
.k-pane-wrapper .k-pane .k-grid-column-menu .k-clear,
.k-pane-wrapper .k-pane .k-grid-column-menu .k-scheduler-delete,
.k-pane-wrapper .k-pane .k-grid-column-menu .k-scheduler-resetSeries,
.k-pane-wrapper .k-pane .k-grid-column-menu .k-select-all,
.k-pane-wrapper .k-pane .k-grid-edit-form .k-clear,
.k-pane-wrapper .k-pane .k-grid-edit-form .k-scheduler-delete,
.k-pane-wrapper .k-pane .k-grid-edit-form .k-scheduler-resetSeries,
.k-pane-wrapper .k-pane .k-grid-edit-form .k-select-all,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-clear,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-scheduler-delete,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-scheduler-resetSeries,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-select-all,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-clear,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-scheduler-delete,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-scheduler-resetSeries,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-select-all {
	color: #7bd2f6;
}
.k-pane-wrapper .k-pane .k-column-menu .k-listgroup-item.k-state-selected {
	color: #7bd2f6;
	background: 0 0;
}
.k-pane-wrapper .k-pane .k-column-menu .k-listgroup-item.k-state-selected .k-link {
	color: inherit;
}
.k-filter .k-filter-preview-field {
	color: #7bd2f6;
}
.k-filter .k-filter-preview-operator {
	color: rgba(0, 63, 89, 0.6);
}
.k-filter .k-filter-item::before,
.k-filter .k-filter-lines .k-filter-item:last-child > .k-filter-toolbar::after,
.k-filter .k-filter-toolbar::before {
	background-color: #bbdceb;
}
.k-drawer {
	border-color: #bbdceb;
	color: #003f59;
	background-color: #fff;
}
.k-drawer-wrapper {
	scrollbar-color: rgba(156, 156, 156, 0.7) #dedede;
}
.k-drawer-wrapper::-webkit-scrollbar-track {
	background: #dedede;
}
.k-drawer-wrapper::-webkit-scrollbar-thumb {
	background: rgba(156, 156, 156, 0.7);
}
.k-drawer-wrapper::-webkit-scrollbar-thumb:hover {
	background: #9c9c9c;
}
.k-drawer-item.k-state-hover,
.k-drawer-item:hover {
	color: #003f59;
	background-color: #7bd2f6;
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
}
.k-drawer-item.k-state-focused,
.k-drawer-item:focus {
	background-color: #fff;
	box-shadow: inset 0 0 3px 1px #59cfff;
}
.k-drawer-item.k-state-focused.k-state-hover,
.k-drawer-item.k-state-focused:hover,
.k-drawer-item:focus.k-state-hover,
.k-drawer-item:focus:hover {
	color: #003f59;
	background-color: #7bd2f6;
}
.k-drawer-item.k-state-selected {
	color: #fff;
	background-color: #13688c;
}
.k-drawer-item.k-state-selected.k-state-hover,
.k-drawer-item.k-state-selected:hover {
	color: #fff;
	background-color: #105776;
}
.k-drawer-separator {
	background-color: #bbdceb;
}
.k-rating.k-widget {
	background-color: transparent;
}
.k-rating.k-state-focused,
.k-rating:focus {
	box-shadow: none;
}
.k-rating.k-state-focused .k-rating-item > .k-icon,
.k-rating:focus .k-rating-item > .k-icon {
	text-shadow: 0 2px 3.6px rgba(0, 0, 0, 0.1);
}
.k-rating.k-state-focused .k-rating-item.k-state-selected > .k-icon,
.k-rating:focus .k-rating-item.k-state-selected > .k-icon {
	text-shadow: 0 2px 3.6px rgba(123, 210, 246, 0.5);
}
.k-rating-item {
	color: #003f59;
}
.k-rating-item.k-state-selected {
	color: #7bd2f6;
	background: 0 0;
	box-shadow: none;
}
.k-rating-item.k-state-selected.k-state-focused,
.k-rating-item.k-state-selected:focus {
	color: #7bd2f6;
}
.k-rating-item.k-state-hover,
.k-rating-item:hover {
	color: #7bd2f6;
	cursor: pointer;
	background: 0 0;
	box-shadow: none;
}
.k-rating-item.k-state-hover.k-state-selected,
.k-rating-item:hover.k-state-selected {
	box-shadow: none;
}
.k-rating-item.k-state-focused,
.k-rating-item:focus {
	background: 0 0;
	box-shadow: none;
}
.k-rating-item.k-state-focused.k-state-selected,
.k-rating-item:focus.k-state-selected {
	box-shadow: none;
}
.k-timeline .k-timeline-flag {
	background-color: #7bd2f6;
	color: #000;
}
.k-timeline .k-timeline-circle {
	background-color: #7bd2f6;
}
.k-timeline .k-timeline-card .k-card-header {
	background-color: #fff;
	color: #003f59;
}
.k-timeline .k-timeline-card .k-card-body {
	scrollbar-color: #bbdceb #fff;
}
.k-timeline .k-timeline-card .k-card-body::-webkit-scrollbar-track {
	background: #fff;
}
.k-timeline .k-timeline-card .k-card-body::-webkit-scrollbar-thumb {
	background: #bbdceb;
}
.k-timeline .k-timeline-card .k-card-body::-webkit-scrollbar-thumb:hover {
	background: #8bc4dd;
}
.k-timeline .k-timeline-date {
	color: #003f59;
}
.k-timeline .k-timeline-arrow.k-button:not(.k-flat):not(.k-bare):not(.k-outline):not(.k-overflow-button).k-state-disabled {
	opacity: 1;
	color: #9fcee2;
	background-color: #daecf4;
	border-color: #a3d0e4;
}
.k-timeline-horizontal .k-timeline-track-wrap::after,
.k-timeline-vertical::after {
	background-color: #daecf4;
	border-color: #93c8df;
}
.k-timeline-horizontal .k-timeline-flag::after {
	background-color: #7bd2f6;
}
.k-breadcrumb.k-state-focused,
.k-breadcrumb:focus {
	box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
}
.k-breadcrumb .k-breadcrumb-link {
	border-color: transparent;
	color: #7bd2f6;
	background-color: transparent;
}
.k-breadcrumb .k-breadcrumb-link.k-state-hover,
.k-breadcrumb .k-breadcrumb-link:hover {
	border-color: transparent;
	color: #68cbf5;
	background-color: rgba(0, 0, 0, 0.08);
}
.k-breadcrumb .k-breadcrumb-link.k-state-focused,
.k-breadcrumb .k-breadcrumb-link:focus {
	border-color: false;
	color: #7bd2f6;
	background-color: false;
	box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}
.k-breadcrumb .k-breadcrumb-root-link {
	border-color: false;
	color: #003f59;
	background-color: false;
}
.k-breadcrumb .k-breadcrumb-root-link.k-state-hover,
.k-breadcrumb .k-breadcrumb-root-link:hover {
	border-color: false;
	color: false;
	background-color: rgba(0, 0, 0, 0.08);
}
.k-breadcrumb .k-breadcrumb-root-link.k-state-focused,
.k-breadcrumb .k-breadcrumb-root-link:focus {
	border-color: false;
	color: false;
	background-color: false;
	box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}
.k-breadcrumb .k-breadcrumb-delimiter-icon,
.k-breadcrumb .k-breadcrumb-root-link {
	color: inherit;
}
.k-listgroup {
	border-radius: 4px;
	border-color: #bbdceb;
	color: #003f59;
	background-color: #fff;
}
.k-listgroup-flush {
	border-radius: 0;
}
.k-badge.k-badge-secondary {
	border-color: #cae4ef;
	color: #003f59;
	background-color: #cae4ef;
}
.k-badge.k-badge-primary {
	border-color: #7bd2f6;
	color: #003f59;
	background-color: #7bd2f6;
}
.k-badge.k-badge-info {
	border-color: #06c;
	color: #fff;
	background-color: #06c;
}
.k-badge.k-badge-success {
	border-color: #37b400;
	color: #fff;
	background-color: #37b400;
}
.k-badge.k-badge-warning {
	border-color: #ffb400;
	color: #000;
	background-color: #ffb400;
}
.k-badge.k-badge-error {
	border-color: #db4240;
	color: #fff;
	background-color: #db4240;
}
.k-badge-outline.k-badge-secondary {
	border-color: currentColor;
	color: #003f59;
	background-color: #fff;
}
.k-badge-outline.k-badge-primary {
	border-color: currentColor;
	color: #7bd2f6;
	background-color: #fff;
}
.k-badge-outline.k-badge-info {
	border-color: currentColor;
	color: #06c;
	background-color: #fff;
}
.k-badge-outline.k-badge-success {
	border-color: currentColor;
	color: #37b400;
	background-color: #fff;
}
.k-badge-outline.k-badge-warning {
	border-color: currentColor;
	color: #ffb400;
	background-color: #fff;
}
.k-badge-outline.k-badge-error {
	border-color: currentColor;
	color: #db4240;
	background-color: #fff;
}
.k-checkbox {
	border-radius: 4px;
	border-color: #a3d0e4;
	color: #7bd2f6;
	background-color: #fff;
}
.k-checkbox:checked::before {
	border-radius: 4px;
}
.k-checkbox:hover {
	border-color: #8bc4dd;
	color: #7bd2f6;
	background-color: #fff;
}
.k-checkbox:focus {
	box-shadow: 0 0 3px 0 #7bd2f6;
	border-color: #7bd2f6;
}
.k-checkbox.k-state-indeterminate,
.k-checkbox:indeterminate {
	border-color: #a3d0e4;
	color: #7bd2f6;
	background-color: #fff;
}
.k-checkbox:checked {
	border-color: #a3d0e4;
	color: #7bd2f6;
	background-color: #fff;
}
.k-checkbox:checked:focus {
	box-shadow: 0 0 3px 0 #7bd2f6;
	border-color: #7bd2f6;
}
.k-checkbox.k-invalid,
.k-checkbox.k-state-invalid {
	border-color: #fff;
}
.k-radio {
	border-color: #a3d0e4;
	color: #7bd2f6;
	background-color: #fff;
	border-radius: 50%;
}
.k-radio::before {
	border-radius: 50%;
}
.k-radio:hover {
	border-color: #8bc4dd;
	color: #7bd2f6;
	background-color: #fff;
}
.k-radio:focus {
	box-shadow: 0 0 3px 0 #7bd2f6;
	border-color: #8bc4dd;
}
.k-radio:checked {
	border-color: #a3d0e4;
	color: #7bd2f6;
	background-color: #fff;
}
.k-radio:checked:focus {
	box-shadow: 0 0 3px 0 #7bd2f6;
	border-color: #a3d0e4;
}
.k-filemanager {
	border-color: false;
	color: false;
	background-color: false;
}
.k-filemanager-toolbar {
	border-color: #bbdceb;
	color: false;
	background-color: false;
}
.k-filemanager-navigation {
	border-color: #bbdceb;
	color: false;
	background-color: false;
}
.k-filemanager-breadcrumb {
	border-color: #bbdceb;
	color: false;
	background-color: #daecf4;
}
.k-filemanager .k-dropzone-hovered {
	border-color: false;
	color: inherit;
	background-color: inherit;
}
.k-filemanager-listview {
	border-color: false;
	color: false;
	background-color: false;
}
.k-filemanager-listview .k-item {
	border-color: false;
	color: false;
	background-color: false;
}
.k-filemanager-listview .k-item.k-state-selected .k-icon {
	border-color: false;
	color: #fff;
	background-color: false;
}
.k-filemanager-listview .k-item .k-icon {
	border-color: false;
	color: #80a4b4;
	background-color: false;
}
.k-filemanager-grid {
	border-color: false;
	color: false;
	background-color: false;
}
.k-filemanager-preview {
	border-color: #bbdceb;
	color: false;
	background-color: false;
}
.k-filemanager-preview .k-file-preview-wrapper .k-icon {
	border-color: false;
	color: #80a4b4;
	background-color: false;
}
.k-filemanager-preview .k-file-size {
	color: inherit;
}
.k-filemanager-drag-hint {
	border-color: false;
	color: #000;
	background-color: #7bd2f6;
}
.k-filemanager-drag-hint .k-icon {
	border-color: false;
	color: #000;
	background-color: false;
}
.k-in,
.k-item,
.k-window-action {
	border-color: transparent;
}
.k-tool.k-state-hover {
	color: #fff;
}
.k-splitbar .k-resize-handle {
	background-color: #003f59;
	color: #003f59;
}
.k-splitbar .k-resize-handle:hover {
	color: #003f59;
}
.k-splitbar .k-resize-handle {
	background-color: #003f59;
}
.k-block,
.k-widget {
	background-color: #fff;
}
.k-autocomplete,
.k-block,
.k-button-group .k-tool,
.k-content,
.k-dropdown-wrap,
.k-dropzone-active,
.k-editable-area,
.k-editor-dialog .k-tabstrip-items,
.k-filter-row > th,
.k-footer-template td,
.k-grid td,
.k-grid td.k-state-selected,
.k-grid-content-locked,
.k-grid-footer,
.k-grid-footer-locked,
.k-grid-footer-wrap,
.k-grid-header,
.k-grid-header-locked,
.k-grid-header-wrap,
.k-group,
.k-group-footer td,
.k-grouping-header,
.k-grouping-header .k-group-indicator,
.k-header,
.k-input,
.k-pager-refresh,
.k-pager-wrap,
.k-pager-wrap .k-link,
.k-panel > .k-item > .k-link,
.k-panelbar .k-content,
.k-panelbar .k-panel,
.k-panelbar > .k-item > .k-link,
.k-popup.k-align .k-list .k-item:last-child,
.k-separator,
.k-slider-track,
.k-splitbar,
.k-state-default,
.k-state-default .k-select,
.k-state-disabled,
.k-textbox,
.k-textbox > input,
.k-tiles,
.k-toolbar,
.k-tooltip,
.k-treemap-tile,
.k-upload-files,
.k-widget {
	border-color: #bbdceb;
}
.k-dropzone-hovered,
.k-footer-template td,
.k-grid-footer,
.k-group,
.k-group-footer td,
.k-grouping-header,
.k-pager-wrap,
.k-popup,
.k-widget .k-status {
	background-color: #f5f5f5;
}
.k-grouping-row td,
.k-resize-handle-inner,
td.k-group-cell {
	// background-color: #d9ecf5;
}
.k-list-container {
	border-color: #a3d0e4;
	background-color: #daecf4;
}
.k-list-filter > .k-textbox {
	border-color: #a3a3a3;
}
.k-content,
.k-panel > li.k-item,
.k-panelbar > li.k-item,
.k-tiles {
	background-color: #fff;
}
.k-alt,
.k-pivot-layout > tbody > tr:first-child > td:first-child,
.k-resource.k-alt,
.k-separator {
	background-color: #e6f2f8;
}
.k-header.k-alt,
.k-pivot-rowheaders .k-alt .k-alt {
	background-color: #c6e2ee;
}
.k-autocomplete.k-header,
.k-dropdown-wrap.k-state-active,
.k-numeric-wrap.k-state-active,
.k-picker-wrap.k-state-active,
.k-textbox {
	border-color: #bbdceb;
	background-color: #daecf4;
}
.k-autocomplete .k-input,
.k-autocomplete.k-state-focused .k-input,
.k-dropdown-wrap .k-input,
.k-dropdown-wrap.k-state-focused .k-input,
.k-multiselect-wrap,
.k-numeric-wrap.k-state-focused .k-input,
.k-picker-wrap.k-state-focused .k-input,
.k-textbox > input {
	border-color: #bbdceb;
}
.k-textbox > input,
input.k-textbox,
input.k-textbox:hover,
textarea.k-textbox,
textarea.k-textbox:hover {
	background: 0 0;
}
.k-input,
.k-multiselect-wrap,
.k-textbox > input,
input.k-textbox,
input.k-textbox:hover,
textarea.k-textbox,
textarea.k-textbox:hover {
	background-color: #fff;
	color: #003f59;
}
.k-input[readonly] {
	background-color: #fff;
	color: #003f59;
}
.k-block,
.k-content,
.k-dropdown .k-input,
.k-popup,
.k-toolbar,
.k-widget {
	color: #003f59;
}
.k-inverse {
	color: #fff;
}
.k-block {
	color: #00435e;
}
.k-link,
.k-nav-current.k-state-hover .k-link {
	color: #003f59;
}
.k-panelbar > li > .k-link,
.k-tabstrip-items .k-link {
	color: #003f59;
}
.k-grid-header .k-header > .k-link,
.k-header,
.k-treemap-title {
	// color: #00435e;
}
.k-autocomplete,
.k-draghandle,
.k-dropdown-wrap,
.k-grid-header,
.k-grouping-header,
.k-header,
.k-numeric-wrap,
.k-pager-wrap,
.k-panelbar .k-tabstrip-items .k-item,
.k-picker-wrap,
.k-progressbar,
.k-state-highlight,
.k-tabstrip-items .k-item,
.k-textbox,
.k-toolbar {
	// background-image: url(textures/highlight.png);
	// background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
	background-position: 50% 50%;
	// background-color: #d9ecf5;
}
.k-block,
.k-treemap-tile {
	// background-color: #d9ecf5;
}
.k-mediaplayer-toolbar {
	background-color: rgba(217, 236, 245, 0.85);
}
.k-hr {
	border-color: #bbdceb;
}
.k-column-menu .k-state-active .k-sprite,
.k-column-menu .k-state-hover .k-sprite,
.k-icon:hover,
.k-pager-numbers .k-current-page .k-link:hover:after,
.k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view.k-state-hover > .k-link:after,
.k-state-focused .k-icon,
.k-state-hover .k-icon,
.k-state-selected .k-icon {
	opacity: 1;
}
.k-column-menu .k-sprite,
.k-pager-numbers .k-current-page .k-link:after,
.k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link:after,
.k-state-disabled .k-icon {
	opacity: 0.9;
}
.k-tool {
	border-color: transparent;
}
.k-i-loading {
	// background-image: url(BlueOpal/loading.gif);
}
.k-loading-image {
	// background-image: url(BlueOpal/loading-image.gif);
}
.k-loading-color {
	background-color: #fff;
}
.k-draghandle {
	border-color: #93c8df;
	background-color: #fff;
	box-shadow: none;
}
.k-draghandle:hover {
	border-color: #8bc4dd;
	background-color: #7cd1f5;
	box-shadow: none;
}
.k-scheduler {
	color: #003f59;
	background-color: #fff;
}
.k-scheduler-layout {
	color: #003f59;
}
.k-scheduler-datecolumn,
.k-scheduler-groupcolumn {
	background-color: #fff;
	color: #003f59;
}
.k-scheduler-header th,
.k-scheduler-header-wrap,
.k-scheduler-table td,
.k-scheduler-times,
.k-scheduler-times th,
.k-scheduler-times tr {
	border-color: #a3d0e4;
}
.k-nonwork-hour,
.k-scheduler-dayview .k-today.k-nonwork-hour,
.k-scheduler-timelineview .k-today.k-nonwork-hour {
	background-color: #e6f2f8;
}
.k-gantt .k-nonwork-hour {
	background-color: rgba(218, 236, 244, 0.2);
}
.k-gantt .k-header.k-nonwork-hour {
	background-color: rgba(218, 236, 244, 0.15);
}
.k-scheduler-table .k-today,
.k-today > .k-scheduler-datecolumn,
.k-today > .k-scheduler-groupcolumn {
	background-color: #f2f8fb;
}
.k-scheduler-now-arrow {
	border-left-color: #721715;
}
.k-scheduler-now-line {
	background-color: #721715;
}
.k-event,
.k-task-complete {
	border-color: #5ec0e9;
	background: #1ea4dd 0 -257px none repeat-x;
	color: #003f59;
}
.k-event-inverse {
	color: #fff;
}
.k-event.k-state-selected {
	background-position: 0 0;
	box-shadow: 0 0 0 2px #003f59;
}
.k-event .k-resize-handle:after,
.k-task-single .k-resize-handle:after {
	background-color: #003b54;
}
.k-scheduler-marquee:after,
.k-scheduler-marquee:before {
	border-color: #13688c;
}
.k-panelbar .k-content,
.k-panelbar .k-item,
.k-panelbar .k-panel {
	background-color: #fff;
	color: #003f59;
	border-color: #a3d0e4;
}
.k-panelbar > li > .k-link {
	color: #003f59;
}
.k-panelbar > .k-item > .k-link {
	border-color: #a3d0e4;
}
.k-panel > li.k-item {
	background-color: #fff;
}
.k-active-filter,
.k-state-active,
.k-state-active:hover {
	background-color: #fff;
	border-color: #68b1d2;
	color: #00435e;
}
.k-fieldselector .k-list-container {
	background-color: #fff;
}
.k-menu .k-state-hover > .k-state-active {
	background-color: transparent;
}
.k-mediaplayer-toolbar .k-button.k-bare.k-state-active,
.k-mediaplayer-toolbar .k-button.k-bare.k-state-active:hover,
.k-mediaplayer-toolbar .k-button.k-bare:active {
	color: #7bd2f6;
}
.k-menu .k-state-selected > .k-link {
	color: #fff;
	background-color: #13688c;
	border-color: #146b90;
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0, rgba(255, 255, 255, 0) 100%);
}
.k-menu .k-link.k-state-active {
	background-color: #fff;
	border-color: #68b1d2;
	color: #00435e;
}
.k-menu .k-menu-scroll-button:hover,
.k-menu .k-state-hover > .k-link {
	color: #003f59;
	background-color: #7bd2f6;
	border-color: #8bc4dd;
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
}
.k-state-highlight {
	background: #fff;
	color: #00435e;
}
.k-grouping-row .k-state-focused,
.k-state-focused {
	border-color: #bbdceb;
}
.k-window-titlebar .k-link {
	border-radius: 4px;
}
.k-calendar-container.k-group {
	border-color: #a3d0e4;
}
.k-draghandle.k-state-selected:hover,
.k-ghost-splitbar-horizontal,
.k-ghost-splitbar-vertical,
.k-list > .k-state-highlight,
.k-list > .k-state-selected,
.k-marquee-color,
.k-panel > .k-state-selected,
.k-scheduler .k-scheduler-toolbar .k-state-selected,
.k-scheduler .k-today.k-state-selected,
.k-state-selected,
.k-state-selected:link,
.k-state-selected:visited,
.k-tool.k-state-selected {
	//dont remove. removes background highlighting for selected items in reports

	color: #fff;
	background-color: #13688c;
	border-color: #146b90;
}
.k-group-header + .k-list > .k-item.k-first,
.k-static-header + .k-list > .k-item.k-first,
.k-virtual-item.k-first {
	border-top-color: #8bc4dd;
}
.k-group-header + div > .k-list > .k-item.k-first:before {
	border-top-color: #88c5e0;
}
.k-popup > .k-group-header,
.k-popup > .k-virtual-wrap > .k-group-header {
	background: #8bc4dd;
	color: #fff;
}
.k-popup .k-list .k-item > .k-group {
	background: #88c5e0;
	color: #fff;
	border-bottom-left-radius: 3px;
}
.k-popup .k-treeview .k-item > .k-group {
	background: 0 0;
	color: #003f59;
}
.k-marquee-text {
	color: #fff;
}
.k-grid-header th.k-state-focused,
.k-list > .k-state-focused,
.k-listview > .k-state-focused,
.k-state-focused,
td.k-state-focused {
	box-shadow: inset 0 0 3px 1px #59cfff;
}
.k-list > .k-state-focused.k-state-selected,
.k-listview > .k-state-focused.k-state-selected,
.k-state-focused.k-state-selected,
td.k-state-focused.k-state-selected {
	box-shadow: inset 0 0 3px 1px #0e4a64;
}
.k-list-optionlabel.k-state-selected.k-state-focused {
	box-shadow: none;
}
.k-panelbar .k-item .k-link.k-state-selected,
.k-panelbar > li.k-state-default > .k-link.k-state-selected,
.k-panelbar > li > .k-state-selected,
.k-state-selected > .k-link {
	color: #fff;
}
.k-panelbar .k-group .k-link.k-state-hover {
	color: #003f59;
}
.k-dropdown .k-state-focused,
.k-filebrowser-dropzone,
.k-list > .k-state-hover,
.k-pager-wrap .k-link:hover,
.k-scheduler .k-scheduler-toolbar ul li.k-state-hover,
.k-splitbar-horizontal-hover,
.k-splitbar-vertical-hover,
.k-state-hover {
	color: #003f59;
	background-color: #7bd2f6;
	border-color: #8bc4dd;
}
.k-pager-wrap .k-link.k-state-disabled {
	color: #003f59;
	border-color: #bbdceb;
}
.k-state-focused > .k-select,
.k-state-hover > .k-select {
	border-color: #8bc4dd;
}
.k-draghandle:hover,
.k-listbox .k-item:hover:not(.k-state-disabled),
.k-pager-wrap .k-link:hover,
.k-state-hover,
div.k-filebrowser-dropzone em {
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
}
.k-pager-wrap {
	// background-color: #d9ecf5;
	color: #00435e;
}
.k-pager-wrap.k-pager-lg .k-pager-numbers.k-state-expanded,
.k-pager-wrap.k-pager-md .k-pager-numbers.k-state-expanded,
.k-pager-wrap.k-pager-sm .k-pager-numbers.k-state-expanded {
	// background-color: #d9ecf5;
}
.k-dropdown-wrap.k-state-active,
.k-numeric-wrap.k-state-active,
.k-panelbar > .k-item > .k-state-focused,
.k-picker-wrap.k-state-active,
.k-state-active,
.k-state-active:hover,
.k-state-active > .k-link {
	background-image: none;
}
.k-draghandle.k-state-selected:hover,
.k-state-selected {
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0, rgba(255, 255, 255, 0) 100%);
}
.k-draghandle.k-state-selected:hover {
	background-position: 50% 50%;
}
.k-state-hover > .k-link,
div.k-filebrowser-dropzone em {
	color: #003f59;
}
.k-dropdown .k-state-focused .k-input {
	color: #003f59;
}
.k-dropdown .k-state-hover .k-input {
	color: #003f59;
}
.k-state-error {
	border-color: #721715;
	background-color: #eef9fe;
	color: #68cbf5;
}
.k-state-disabled {
	opacity: 0.6;
}
.k-loading-mask.k-state-selected,
.k-tile-empty.k-state-selected {
	border-width: 0;
	background-image: none;
	background-color: transparent;
}
.k-dropzone em,
.k-slider .k-draghandle,
.k-state-disabled,
.k-state-disabled .k-link,
.k-tile-empty strong {
	color: #9fcee2;
}
.k-dropzone .k-upload-status {
	color: #9fcee2;
}
.k-file .k-upload-status {
	color: #003f59;
}
.k-progressbar-indeterminate {
	// background: url(BlueOpal/indeterminate.gif);
}
.k-progressbar-indeterminate .k-progress-status-wrap,
.k-progressbar-indeterminate .k-state-selected {
	display: none;
}
.k-slider-track {
	background-color: #bbdceb;
}
.k-slider-selection {
	background-color: #13688c;
}
.k-slider-horizontal .k-tick {
	// background-image: url(BlueOpal/slider-h.gif);
}
.k-slider-vertical .k-tick {
	// background-image: url(BlueOpal/slider-v.gif);
}
.k-chart-crosshair-tooltip,
.k-chart-shared-tooltip,
.k-widget.k-tooltip {
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
	background-position: 50% 50%;
	// background-color: #d9ecf5;
	color: #00435e;
	border-color: transparent;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}
.k-widget.k-tooltip-validation {
	border-color: #ffb400;
	background-color: #ffb400;
	color: #000;
}
.input-append .k-tooltip-validation,
.input-prepend .k-tooltip-validation {
	font-size: 12px;
	position: relative;
	top: 3px;
}
.k-callout-n {
	// border-bottom-color: #d9ecf5;
}
.k-callout-w {
	// border-right-color: #d9ecf5;
}
.k-callout-s {
	// border-top-color: #d9ecf5;
}
.k-callout-e {
	// border-left-color: #d9ecf5;
}
.k-tooltip-validation .k-callout-n {
	border-bottom-color: #ffb400;
}
.k-tooltip-validation .k-callout-w {
	border-right-color: #ffb400;
}
.k-tooltip-validation .k-callout-s {
	border-top-color: #ffb400;
}
.k-tooltip-validation .k-callout-e {
	border-left-color: #ffb400;
}
.k-splitbar {
	background-color: #daecf4;
}
.k-restricted-size-horizontal,
.k-restricted-size-vertical {
	background-color: #68cbf5;
}
.k-dropzone {
	color: #00435e;
	// background-color: #d9ecf5;
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
	background-position: 50% 50%;
}
.k-file {
	background-color: #fff;
	border-color: #a3d0e4;
}
.k-file:last-child {
	border-radius: 0 0 4px 4px;
}
.k-file-progress {
	color: #00435e;
}
.k-file-progress .k-progress {
	background-color: #06c;
}
.k-file-success .k-file-name,
.k-file-success .k-upload-pct {
	color: #37b400;
}
.k-file-success .k-progress {
	background-color: #37b400;
}
.k-file-error {
	color: #db4240;
}
.k-file-error .k-file-extension-wrapper,
.k-file-error .k-file-group-wrapper,
.k-file-error .k-multiple-files-extension-wrapper,
.k-file-error .k-multiple-files-group-wrapper {
	color: #db4240;
	border-color: #db4240;
}
.k-file-error .k-file-extension-wrapper:before,
.k-file-error .k-file-group-wrapper:before,
.k-file-error .k-multiple-files-extension-wrapper:before,
.k-file-error .k-multiple-files-group-wrapper:before {
	border-color: transparent transparent #db4240 #db4240;
}
.k-file-error .k-progress,
.k-file-invalid .k-progress {
	background-color: #db4240;
}
.k-file-extension-wrapper,
.k-file-group-wrapper,
.k-multiple-files-extension-wrapper,
.k-multiple-files-group-wrapper {
	color: #9fcee2;
	border-color: #9fcee2;
}
.k-file-invalid .k-file-name-invalid {
	color: #db4240;
}
.k-file-invalid-extension-wrapper,
.k-file-invalid-group-wrapper,
.k-multiple-files-invalid-extension-wrapper,
.k-multiple-files-invalid-group-wrapper {
	color: #db4240;
	border-color: #f2bdbc;
}
.k-file-extension-wrapper:before,
.k-multiple-files-extension-wrapper:before {
	background-color: #fff;
	border-color: transparent transparent #9fcee2 #9fcee2;
}
.k-file-invalid-extension-wrapper:before,
.k-multiple-files-invalid-extension-wrapper:before {
	background-color: #fff;
	border-color: transparent transparent #f2bdbc #f2bdbc;
}
.k-multiple-files-extension-wrapper:after {
	border-top-color: #9fcee2;
	border-left-color: #9fcee2;
}
.k-multiple-files-invalid-extension-wrapper:after {
	border-top-color: #f2bdbc;
	border-left-color: #f2bdbc;
}
.k-file-information,
.k-file-size,
.k-file-validation-message {
	color: #9fcee2;
}
.k-upload .k-upload-selected {
	color: #7bd2f6;
	border-color: #bbdceb;
}
.k-upload-action:focus:active:not(.k-state-disabled):not([disabled]) {
	box-shadow: 0 0 2px 1px #7bd2f6;
}
.k-tile {
	border-color: #fff;
}
.k-textbox:hover,
.k-tiles li.k-state-hover {
	border-color: #8bc4dd;
}
.k-tiles li.k-state-selected {
	border-color: #146b90;
}
.k-leaf,
.k-leaf.k-state-hover {
	color: #fff;
}
.k-leaf.k-inverse,
.k-leaf.k-inverse.k-state-hover {
	color: #000;
}
.k-slider,
.k-treeview,
.k-upload {
	box-shadow: none;
}
.k-state-hover {
	box-shadow: none;
}
.k-autocomplete.k-state-focused,
.k-dropdown-wrap.k-state-focused,
.k-numeric-wrap.k-state-focused,
.k-picker-wrap.k-state-focused,
.k-textbox:focus {
	box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
}
.k-state-selected {
	box-shadow: none;
}
.k-state-active {
	box-shadow: none;
}
.k-grid tr:hover {
	//removed to prevent override of highlighting for table row.
	// background-color: #7ad2f7;
	// background-image: url(textures/highlight.png);
	// background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
	background-position: 50% 50%;
}
.k-grid .k-filter-row:hover,
.k-pivot-rowheaders .k-grid tr:hover {
	background: 0 0;
}
.k-grid td.k-state-selected:hover,
.k-grid tr.k-state-selected:hover {
	background-color: #0d6a92;
	background-image: none;
}
.k-autocomplete.k-state-border-down,
.k-autocomplete.k-state-border-up,
.k-datepicker-calendar,
.k-dropdown-wrap.k-state-active,
.k-filebrowser .k-image,
.k-grid .k-filter-options,
.k-menu .k-menu-group,
.k-multiselect.k-state-focused,
.k-picker-wrap.k-state-active,
.k-popup,
.k-time-popup,
.k-tooltip {
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
}
.k-treemap-tile.k-state-hover {
	box-shadow: inset 0 0 0 3px #bbdceb;
}
.k-window {
	border-color: rgba(0, 0, 0, 0.3);
	box-shadow: 1px 1px 7px 1px rgba(128, 128, 128, 0.3);
	background-color: #fff;
}
.k-window.k-state-focused {
	border-color: rgba(0, 0, 0, 0.3);
	box-shadow: 1px 1px 7px 1px rgba(0, 0, 0, 0.3);
}
.k-window-maximized .k-window-content,
.k-window-maximized .k-window-titlebar,
.k-window.k-window-maximized {
	border-radius: 0;
}
.k-shadow {
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
}
.k-inset {
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.3);
}
.k-editor-inline ::selection {
	background-color: #146b90;
	text-shadow: none;
	color: #fff;
}
.k-editor-inline ::-moz-selection {
	background-color: #146b90;
	text-shadow: none;
	color: #fff;
}
.k-editor-inline.k-state-active,
.k-editor-inline:hover {
	border-color: #bbdceb;
}
.k-notification {
	border-radius: 4px;
}
.k-notification {
	background-image: linear-gradient(rgba(255, 255, 255, 0.25) 0, rgba(255, 255, 255, 0) 100%);
}
.k-notification-info {
	background-color: #06c;
	color: #fff;
	border-color: #06c;
}
.k-notification-success {
	background-color: #37b400;
	color: #fff;
	border-color: #37b400;
}
.k-notification-warning {
	background-color: #ffb400;
	color: #000;
	border-color: #ffb400;
}
.k-notification-error {
	background-color: #db4240;
	color: #fff;
	border-color: #db4240;
}
.k-gantt .k-treelist {
	background: #e6f2f8;
}
.k-gantt .k-treelist .k-alt {
	background-color: #d2e8f2;
}
.k-gantt .k-treelist tr:hover {
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
	background-color: #88c5e0;
}
.k-gantt .k-treelist .k-alt.k-state-selected,
.k-gantt .k-treelist .k-alt.k-state-selected > td,
.k-gantt .k-treelist .k-state-selected,
.k-gantt .k-treelist .k-state-selected td {
	background-color: #13688c;
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0, rgba(255, 255, 255, 0) 100%);
}
.k-gantt .k-treelist .k-state-selected:hover,
.k-gantt .k-treelist .k-state-selected:hover td {
	background-color: #0d6a92;
	background-image: none;
}
.k-task-dot:after {
	background-color: #003f59;
	border-color: #003f59;
}
.k-task-dot:hover:after {
	background-color: #fff;
}
.k-task-summary {
	border-color: #0099d9;
	background: #0099d9;
}
.k-task-milestone,
.k-task-summary-complete {
	border-color: #003f59;
	background: #003f59;
}
.k-state-selected.k-task-summary {
	border-color: #3ab2e4;
	background: #3ab2e4;
}
.k-state-selected .k-task-summary-complete,
.k-state-selected.k-task-milestone {
	border-color: #13688c;
	background: #13688c;
}
.k-task-single {
	background-color: #7ecced;
	border-color: #5ec0e9;
	color: #003f59;
}
.k-state-selected.k-task-single {
	border-color: #146b90;
}
.k-line {
	background-color: #003f59;
	color: #003f59;
}
.k-state-selected.k-line {
	background-color: #13688c;
	color: #13688c;
}
.k-resource {
	background-color: #fff;
}
.k-autocomplete,
.k-block,
.k-calendar-container,
.k-colorpicker,
.k-combobox,
.k-datepicker,
.k-datetimepicker,
.k-drag-clue,
.k-dropdown,
.k-dropdown-wrap,
.k-editor-inline,
.k-gantt-views > .k-current-view,
.k-grid .k-filter-options,
.k-grouping-header .k-group-indicator,
.k-inline-block,
.k-list-container,
.k-multiselect,
.k-numeric-wrap,
.k-numerictextbox,
.k-picker-wrap,
.k-slider-selection,
.k-slider-track,
.k-split-button .k-gantt-views,
.k-textbox,
.k-tile,
.k-timepicker,
.k-tooltip,
.k-touch-scrollbar,
.k-treeview .k-in,
.k-upload,
.k-window,
.k-window-action,
.k-window-titleless .k-window-content {
	border-radius: 4px;
}
.k-tool {
	text-align: center;
	vertical-align: middle;
}
.k-tool.k-group-start,
.k-toolbar .k-button-group .k-group-start,
.k-toolbar .k-split-button .k-button {
	border-radius: 4px 0 0 4px;
}
.k-rtl .k-tool.k-group-start,
.k-rtl .k-toolbar .k-button-group .k-group-start,
.k-rtl .k-toolbar .k-split-button .k-button {
	border-radius: 0 4px 4px 0;
}
.k-toolbar .k-button-group > .k-group-end {
	border-radius: 4px;
}
.k-tool.k-group-end,
.k-toolbar .k-button-group .k-button + .k-group-end,
.k-toolbar .k-split-button .k-split-button-arrow {
	border-radius: 0 4px 4px 0;
}
.k-rtl .k-tool.k-group-end,
.k-rtl .k-toolbar .k-button-group .k-group-end,
.k-rtl .k-toolbar .k-split-button .k-split-button-arrow {
	border-radius: 4px 0 0 4px;
}
.k-group-start.k-group-end.k-tool {
	border-radius: 4px;
}
.k-autocomplete.k-state-border-up,
.k-calendar-container.k-state-border-up,
.k-dropdown-wrap.k-state-border-up,
.k-filter-menu,
.k-list-container.k-state-border-up,
.k-multiselect.k-state-border-up,
.k-numeric-wrap.k-state-border-up,
.k-picker-wrap.k-state-border-up,
.k-window-content {
	border-radius: 0 0 4px 4px;
}
.k-autocomplete.k-state-border-up .k-input,
.k-dropdown-wrap.k-state-border-up .k-input,
.k-numeric-wrap.k-state-border-up .k-input,
.k-picker-wrap.k-state-border-up .k-input,
.k-picker-wrap.k-state-border-up .k-selected-color {
	border-radius: 0 0 0 4px;
}
.k-multiselect.k-state-border-up .k-multiselect-wrap {
	border-radius: 0 0 4px 4px;
}
.k-autocomplete.k-state-border-down,
.k-block > .k-header,
.k-calendar-container.k-state-border-down,
.k-dropdown-wrap.k-state-border-down,
.k-gantt-views.k-state-expanded,
.k-gantt-views.k-state-expanded > .k-current-view,
.k-list-container.k-state-border-down,
.k-multiselect.k-state-border-down,
.k-numeric-wrap.k-state-border-down,
.k-panelbar .k-tabstrip-items .k-item,
.k-picker-wrap.k-state-border-down,
.k-tabstrip-items .k-item,
.k-tabstrip-items .k-link,
.k-window-titlebar {
	border-radius: 4px 4px 0 0;
}
.k-split-button.k-state-border-down > .k-button {
	border-radius: 4px 0 0 0;
}
.k-split-button.k-state-border-up > .k-button {
	border-radius: 0 0 0 4px;
}
.k-split-button.k-state-border-down > .k-split-button-arrow {
	border-radius: 0 4px 0 0;
}
.k-split-button.k-state-border-up > .k-split-button-arrow {
	border-radius: 0 0 4px 0;
}
.k-dropdown-wrap .k-input,
.k-numeric-wrap .k-input,
.k-picker-wrap .k-input {
	border-radius: 3px 0 0 3px;
}
.k-rtl .k-dropdown-wrap .k-input,
.k-rtl .k-numeric-wrap .k-input,
.k-rtl .k-picker-wrap .k-input {
	border-radius: 0 3px 3px 0;
}
.k-numeric-wrap .k-link {
	border-radius: 0 3px 0 0;
}
.k-numeric-wrap .k-link + .k-link {
	border-radius: 0 0 3px 0;
}
.k-colorpicker .k-selected-color {
	border-radius: 3px 0 0 3px;
}
.k-rtl .k-colorpicker .k-selected-color {
	border-radius: 0 3px 3px 0;
}
.k-autocomplete.k-state-border-down .k-input {
	border-radius: 4px 4px 0 0;
}
.k-dropdown-wrap.k-state-border-down .k-input,
.k-numeric-wrap.k-state-border-down .k-input,
.k-picker-wrap.k-state-border-down .k-input,
.k-picker-wrap.k-state-border-down .k-selected-color {
	border-radius: 4px 0 0 0;
}
.k-numeric-wrap .k-link.k-state-selected {
	background-color: #7bd2f6;
}
.k-multiselect.k-state-border-down .k-multiselect-wrap {
	border-radius: 3px 3px 0 0;
}
.k-datetimepicker .k-select + .k-select,
.k-dropdown-wrap .k-select,
.k-list-container.k-state-border-right,
.k-numeric-wrap .k-select,
.k-picker-wrap .k-select {
	border-radius: 0 4px 4px 0;
}
.k-rtl .k-datetimepicker .k-select + .k-select,
.k-rtl .k-dropdown-wrap .k-select,
.k-rtl .k-list-container.k-state-border-right,
.k-rtl .k-numeric-wrap .k-select,
.k-rtl .k-picker-wrap .k-select {
	border-radius: 4px 0 0 4px;
}
.k-numeric-wrap.k-expand-padding .k-input {
	border-radius: 4px;
}
.k-autocomplete .k-input,
.k-multiselect-wrap,
.k-textbox > input {
	border-radius: 3px;
}
.k-dropzone,
.k-fieldselector .k-list .k-item,
.k-list .k-state-focused,
.k-list .k-state-highlight,
.k-list .k-state-hover,
.k-list .k-state-selected,
.k-list-optionlabel,
.k-listbox .k-item {
	border-radius: 3px;
}
.k-grid .k-slider .k-button,
.k-slider .k-button {
	border-radius: 13px;
}
.k-draghandle {
	border-radius: 7px;
}
.k-scheduler-toolbar > ul li:first-child,
.k-scheduler-toolbar > ul li:first-child .k-link,
.k-scheduler-toolbar > ul.k-scheduler-views li:first-child + li,
.k-scheduler-toolbar > ul.k-scheduler-views li:first-child + li .k-link {
	border-radius: 4px 0 0 4px;
}
.k-rtl .k-scheduler-toolbar > ul li:first-child,
.k-rtl .k-scheduler-toolbar > ul li:first-child .k-link,
.k-rtl .k-scheduler-toolbar > ul.k-scheduler-views li:first-child + li,
.k-rtl .k-scheduler-toolbar > ul.k-scheduler-views li:first-child + li .k-link {
	border-radius: 0 4px 4px 0;
}
.k-edit-field > .k-scheduler-navigation {
	border-radius: 4px;
}
.k-scheduler-toolbar .k-nav-next,
.k-scheduler-toolbar .k-nav-next .k-link,
.k-scheduler-toolbar ul + ul li:last-child,
.k-scheduler-toolbar ul + ul li:last-child .k-link {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}
.k-rtl .k-scheduler-toolbar .k-nav-next,
.k-rtl .k-scheduler-toolbar .k-nav-next .k-link,
.k-rtl .k-scheduler-toolbar ul + ul li:last-child,
.k-rtl .k-scheduler-toolbar ul + ul li:last-child .k-link {
	border-radius: 4px 0 0 4px;
}
.k-scheduler div.k-scheduler-footer .k-link,
.k-scheduler div.k-scheduler-footer ul li {
	border-radius: 4px;
}
.k-event,
.k-event .k-link,
.k-more-events,
.k-task-complete,
.k-task-single {
	border-radius: 3px;
}
.k-scheduler-mobile .k-event {
	border-radius: 2px;
}
.k-autocomplete,
.k-dropdown-wrap.k-state-default,
.k-numeric-wrap.k-state-default,
.k-picker-wrap.k-state-default {
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
	background-position: 50% 50%;
	background-color: #deeef5;
	border-color: #a3d0e4;
}
.k-dropdown-wrap.k-state-hover,
.k-numeric-wrap.k-state-hover,
.k-picker-wrap.k-state-hover {
	background-color: #7ad2f7;
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
	background-position: 50% 50%;
	border-color: #8bc4dd;
}
.k-autocomplete.k-state-hover,
.k-multiselect-wrap.k-state-hover,
.k-state-hover > .k-multiselect-wrap {
	border-color: #8bc4dd;
	background: #fff;
	color: #003f59;
}
.k-dateinput.k-state-disabled > .k-textbox:hover,
.k-maskedtextbox.k-state-disabled > .k-textbox:hover,
.k-multiselect-wrap,
input.k-textbox,
textarea.k-textbox {
	border-color: #a3d0e4;
}
.k-dropdown-wrap.k-state-focused,
.k-numeric-wrap.k-state-focused,
.k-picker-wrap.k-state-focused {
	background-color: #7ad2f7;
	// background-image: url(textures/highlight.png);
	background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
	background-position: 50% 50%;
	border-color: #bdbdbd;
	box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
}
.k-autocomplete.k-state-focused,
.k-multiselect-wrap.k-state-focused,
.k-state-focused > .k-multiselect-wrap {
	border-color: #bdbdbd;
	box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
}
.k-list-container {
	color: #003f59;
}
.k-nodata {
	color: #9fcee2;
}
.k-dropdown .k-input,
.k-dropdown .k-state-focused .k-input,
.k-menu .k-popup,
.k-multiselect .k-button,
.k-multiselect .k-button:hover {
	// color: #003f59;
}
.k-state-default > .k-select {
	border-color: #a3d0e4;
}
.k-state-hover > .k-select {
	border-color: #8bc4dd;
}
.k-state-focused > .k-select {
	border-color: #bdbdbd;
}
.k-tabstrip:focus {
	box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
}
.k-panelbar > li.k-state-default > .k-link,
.k-tabstrip-items .k-state-default .k-link {
	color: #003f59;
}
.k-panelbar > li.k-state-default > .k-link.k-state-hover,
.k-panelbar > li.k-state-hover > .k-link,
.k-tabstrip-items .k-state-hover .k-link {
	color: #003f59;
}
.k-panelbar > .k-state-focused.k-state-hover {
	background: #7bd2f6;
	box-shadow: none;
}
.k-tabstrip-items .k-state-default {
	border-color: #a3d0e4;
}
.k-tabstrip-items .k-state-hover {
	border-color: #8bc4dd;
}
.k-panelbar .k-tabstrip-items .k-state-active,
.k-tabstrip-items .k-state-active {
	background-color: #fff;
	background-image: none;
	border-color: #68b1d2;
}
.k-panelbar .k-tabstrip-top > .k-tabstrip-items .k-state-active,
.k-tabstrip-top > .k-tabstrip-items .k-state-active {
	border-bottom-color: #fff;
}
.k-tabstrip .k-content.k-state-active {
	background-color: #fff;
	color: #003f59;
}
.k-menu .k-item,
.k-menu.k-header {
	border-color: #a3d0e4;
}
.k-column-menu,
.k-column-menu .k-item,
.k-overflow-container .k-overflow-group {
	border-color: #a3d0e4;
}
.k-toolbar .k-button.k-state-disabled:focus {
	border-color: #68cbf5;
	box-shadow: 0 0 2px 1px #7bd2f6;
}
.k-overflow-container .k-button-group .k-button {
	border-radius: 4px;
}
.k-overflow-container .k-overflow-group {
	box-shadow: inset 0 1px 0 #fff, 0 1px 0 #fff;
}
.k-overflow-container .k-separator {
	border-color: #a3d0e4;
}
.k-overflow-container .k-overflow-group + .k-overflow-group,
.k-toolbar-first-visible.k-overflow-group {
	box-shadow: 0 1px 0 #fff;
}
.k-toolbar-last-visible.k-overflow-group {
	box-shadow: inset 0 1px 0 #fff;
}
.k-column-menu .k-separator {
	border-color: #a3d0e4;
	background-color: transparent;
}
.k-menu .k-group {
	border-color: #a3d0e4;
}
.k-grid-filter.k-state-active {
	background-color: #fff;
}
.k-grid-footer td,
.k-group-footer td,
.k-grouping-row td {
	color: #003f59;
	border-color: #a3d0e4;
	font-weight: 700;
}
.k-grouping-header {
	color: #003f59;
}
.k-grid td.k-state-focused {
	box-shadow: inset 0 0 3px 1px #59cfff;
}
.k-grid .k-header .k-grid-search {
	background: 0 0;
	background-color: #fff;
	color: #003f59;
}
.k-gantt-toolbar .k-state-default,
.k-grid .k-grouping-header,
.k-grid-header,
.k-grid-header-wrap,
.k-grouping-header .k-group-indicator,
.k-header,
.k-pager-wrap,
.k-pager-wrap .k-link,
.k-pager-wrap .k-textbox {
	border-color: #a3d0e4;
}
.k-pager-numbers .k-link,
.k-treeview .k-in {
	border-color: transparent;
}
.k-grid .k-hierarchy-cell .k-icon,
.k-scheduler-table .k-icon,
.k-treeview .k-icon {
	background-color: transparent;
	border-radius: 4px;
}
.k-scheduler-table .k-state-hover .k-icon {
	background-color: transparent;
}
.k-editor .k-tool:focus {
	outline: 0;
	border-color: #68cbf5;
	box-shadow: 0 0 2px 1px #7bd2f6;
}
@media screen and (-ms-high-contrast: active) {
	.k-editor-toolbar-wrap .k-button-group .k-tool:focus,
	.k-editor-toolbar-wrap .k-dropdown-wrap.k-state-focused {
		border-color: #fff;
	}
}
@media only screen and (max-width: 1024px) {
	.k-edge .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
	.k-ff .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
	.k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
	.k-safari .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
	.k-webkit .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view {
		// background-image: url(textures/highlight.png);
		background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
		background-position: 50% 50%;
		background-color: #deeef5;
		border-color: #bbdceb;
	}
	.k-edge .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
	.k-ff .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
	.k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
	.k-safari .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
	.k-webkit .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view {
		border-radius: 4px;
	}
	.k-edge .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li,
	.k-ff .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li,
	.k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li,
	.k-safari .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li,
	.k-webkit .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li {
		border-radius: 0;
	}
	.k-edge .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li.k-current-view,
	.k-ff .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li.k-current-view,
	.k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li.k-current-view,
	.k-safari .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li.k-current-view,
	.k-webkit .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li.k-current-view {
		border-radius: 3px 3px 0 0;
	}
	.k-edge .k-scheduler-toolbar > ul li:first-child,
	.k-edge .k-scheduler-toolbar > ul li:first-child .k-link,
	.k-edge .k-scheduler-toolbar > ul.k-scheduler-views li,
	.k-edge .k-scheduler-toolbar > ul.k-scheduler-views li .k-link,
	.k-ff .k-scheduler-toolbar > ul li:first-child,
	.k-ff .k-scheduler-toolbar > ul li:first-child .k-link,
	.k-ff .k-scheduler-toolbar > ul.k-scheduler-views li,
	.k-ff .k-scheduler-toolbar > ul.k-scheduler-views li .k-link,
	.k-ie11 .k-scheduler-toolbar > ul li:first-child,
	.k-ie11 .k-scheduler-toolbar > ul li:first-child .k-link,
	.k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views li,
	.k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views li .k-link,
	.k-safari .k-scheduler-toolbar > ul li:first-child,
	.k-safari .k-scheduler-toolbar > ul li:first-child .k-link,
	.k-safari .k-scheduler-toolbar > ul.k-scheduler-views li,
	.k-safari .k-scheduler-toolbar > ul.k-scheduler-views li .k-link,
	.k-webkit .k-scheduler-toolbar > ul li:first-child,
	.k-webkit .k-scheduler-toolbar > ul li:first-child .k-link,
	.k-webkit .k-scheduler-toolbar > ul.k-scheduler-views li,
	.k-webkit .k-scheduler-toolbar > ul.k-scheduler-views li .k-link {
		border-radius: 0;
	}
	.k-edge .k-scheduler-toolbar > ul.k-scheduler-views li:last-child,
	.k-edge .k-scheduler-toolbar > ul.k-scheduler-views li:last-child .k-link,
	.k-ff .k-scheduler-toolbar > ul.k-scheduler-views li:last-child,
	.k-ff .k-scheduler-toolbar > ul.k-scheduler-views li:last-child .k-link,
	.k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views li:last-child,
	.k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views li:last-child .k-link,
	.k-safari .k-scheduler-toolbar > ul.k-scheduler-views li:last-child,
	.k-safari .k-scheduler-toolbar > ul.k-scheduler-views li:last-child .k-link,
	.k-webkit .k-scheduler-toolbar > ul.k-scheduler-views li:last-child,
	.k-webkit .k-scheduler-toolbar > ul.k-scheduler-views li:last-child .k-link {
		border-radius: 0 0 3px 3px;
	}
	.k-edge .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover,
	.k-ff .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover,
	.k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover,
	.k-safari .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover,
	.k-webkit .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover {
		border-color: #8bc4dd;
		// background-image: url(textures/highlight.png);
		background-image: none, linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
		background-color: #7bd2f6;
	}
	.k-edge .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link,
	.k-ff .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link,
	.k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link,
	.k-safari .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link,
	.k-webkit .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link {
		color: #003f59;
		min-width: 75px;
	}
	.k-edge .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover > .k-link,
	.k-ff .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover > .k-link,
	.k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover > .k-link,
	.k-safari .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover > .k-link,
	.k-webkit .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover > .k-link {
		color: #003f59;
	}
	.k-edge .k-scheduler-views > li.k-state-selected > .k-link:after,
	.k-ff .k-scheduler-views > li.k-state-selected > .k-link:after,
	.k-ie11 .k-scheduler-views > li.k-state-selected > .k-link:after,
	.k-safari .k-scheduler-views > li.k-state-selected > .k-link:after,
	.k-webkit .k-scheduler-views > li.k-state-selected > .k-link:after {
		display: block;
		content: "";
		position: absolute;
		top: 50%;
		margin-top: -0.5em;
		right: 0.333em;
		width: 1.333em;
		height: 1.333em;
	}
	.k-edge .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
	.k-ff .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
	.k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
	.k-safari .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
	.k-webkit .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded {
		border-width: 1px 1px 0 1px;
		border-style: solid;
		border-color: #a3d0e4;
		// background-color: #d9ecf5;
		border-radius: 4px 4px 0 0;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
	}
	.k-edge .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
	.k-ff .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
	.k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
	.k-safari .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
	.k-webkit .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded {
		border-width: 1px;
		background-image: none;
		border-radius: 4px;
	}
}
.k-chart .k-mask {
	background-color: #fff;
	filter: alpha(opacity=68);
	opacity: 0.68;
}
.k-chart .k-selection {
	border-color: rgba(0, 0, 0, 0.2);
	box-shadow: inset 0 1px 8px rgba(0, 0, 0, 0.1);
	transition: box-shadow 0.2s linear, border-color 0.2s linear;
}
.k-chart .k-selection:hover {
	border-color: rgba(0, 0, 0, 0.3);
	box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.2);
}
.k-chart .k-handle {
	background-color: #fcfcfc;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.k-chart .k-handle:hover {
	background-color: #fff;
	border-color: #b8b8b8;
	box-shadow: 0 0 0 2px rgba(111, 101, 96, 0.5);
}
.k-chart .k-navigator-hint .k-tooltip {
	border: 3px solid #fff;
	box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
	background: #fff;
	color: #242424;
}
.k-chart .k-navigator-hint .k-scroll {
	background: rgba(123, 210, 246, 0.7);
	height: 4px;
}
.k-map .k-marker {
	font-size: 28px;
	color: #7bd2f6;
	text-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
}
.k-map .k-attribution {
	color: #666;
}
.k-spreadsheet-column-header,
.k-spreadsheet-row-header {
	background-color: #fff;
}
.k-spreadsheet-column-header,
.k-spreadsheet-row-header,
.k-spreadsheet-top-corner {
	background-color: #daecf4;
	background-image: none;
	color: #000;
	border-color: #8bc4dd;
}
.k-spreadsheet-top-corner {
	border-color: #8bc4dd;
}
.k-spreadsheet-top-corner:after {
	border-color: transparent #8bc4dd #8bc4dd transparent;
}
.k-spreadsheet-pane {
	border-color: #8bc4dd;
}
.k-spreadsheet-pane .k-spreadsheet-haxis,
.k-spreadsheet-pane .k-spreadsheet-vaxis {
	border-color: #b3d8e8;
}
.k-spreadsheet-pane .k-spreadsheet-column-header,
.k-spreadsheet-pane .k-spreadsheet-row-header {
	border-color: #8bc4dd;
}
.k-spreadsheet-pane .k-spreadsheet-merged-cell {
	background-color: #fff;
}
.k-spreadsheet-pane .k-selection-full,
.k-spreadsheet-pane .k-selection-partial {
	border-color: rgba(19, 104, 140, 0.2);
	background-color: rgba(19, 104, 140, 0.2);
}
.k-spreadsheet-pane .k-filter-range {
	border-color: #13688c;
}
.k-spreadsheet-pane .k-spreadsheet-column-header .k-selection-full,
.k-spreadsheet-pane .k-spreadsheet-column-header .k-selection-partial {
	border-bottom-color: #13688c;
}
.k-spreadsheet-pane .k-spreadsheet-row-header .k-selection-full,
.k-spreadsheet-pane .k-spreadsheet-row-header .k-selection-partial {
	border-right-color: #13688c;
}
.k-auto-fill,
.k-spreadsheet-selection {
	border-color: #13688c;
	box-shadow: inset 0 0 0 1px #13688c;
}
.k-auto-fill-wrapper .k-tooltip {
	background: #fff;
}
.k-spreadsheet-selection {
	background-color: rgba(19, 104, 140, 0.2);
}
.k-spreadsheet-active-cell {
	box-shadow: inset 0 0 0 1px #13688c;
	background-color: #fff;
}
.k-spreadsheet-active-cell.k-right {
	box-shadow: inset 0 0 0 1px #13688c, inset -1px 0 0 1px #13688c;
}
.k-spreadsheet-active-cell.k-bottom {
	box-shadow: inset 0 0 0 1px #13688c, inset 0 -1px 0 1px #13688c;
}
.k-spreadsheet-active-cell.k-bottom.k-right {
	box-shadow: inset 0 0 0 1px #13688c, inset -1px -1px 0 1px #13688c;
}
.k-spreadsheet-active-cell.k-single {
	color: #003f59;
	background-color: #fff;
}
.k-spreadsheet .k-spreadsheet-action-bar {
	background-color: #fff;
	border-color: #bbdceb;
}
.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-name-editor {
	border-color: #8bc4dd;
}
.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-formula-bar::before {
	border-color: #8bc4dd;
}
.k-spreadsheet .k-spreadsheet-formula-input {
	background-color: #fff;
	color: #003f59;
}
.k-spreadsheet .k-resize-handle,
.k-spreadsheet .k-resize-hint-handle,
.k-spreadsheet .k-resize-hint-marker {
	background-color: #13688c;
}
.k-spreadsheet .k-resize-hint-vertical .k-resize-hint-handle,
.k-spreadsheet .k-resize-hint-vertical .k-resize-hint-marker {
	background-color: #13688c;
}
.k-spreadsheet .k-single-selection::after {
	background-color: #13688c;
	border-color: #fff;
}
.k-spreadsheet .k-auto-fill-punch {
	background-color: rgba(255, 255, 255, 0.5);
}
.k-spreadsheet .k-single-selection.k-dim-auto-fill-handle::after {
	background-color: rgba(19, 104, 140, 0.5);
}
.k-spreadsheet .k-spreadsheet-cell-comment {
	color: #00435e;
	// background-color: #d9ecf5;
	border-color: #a3d0e4;
}
.k-spreadsheet .k-spreadsheet-has-comment:after {
	border-color: #13688c #13688c transparent transparent;
}
.k-spreadsheet .k-dirty {
	border-color: #db4240 transparent transparent #db4240;
}
.k-spreadsheet-format-cells .k-spreadsheet-preview {
	border-color: #bbdceb;
}
.k-spreadsheet-filter {
	border-radius: 4px;
	background-color: #fff;
	box-shadow: inset 0 0 0 1px #b3d8e8;
}
.k-spreadsheet-filter.k-state-active {
	color: #fff;
	background-color: #13688c;
}
.k-spreadsheet-filter:hover {
	color: #003f59;
	background: #7bd2f6;
	border-color: #55c5f3;
}
.k-action-window .k-action-buttons {
	border-color: #bbdceb;
	// background: #d9ecf5;
}
.k-spreadsheet-sample {
	color: #00abf2;
}
.k-state-selected .k-spreadsheet-sample {
	color: inherit;
}
.k-spreadsheet-window .k-list {
	border-color: #bbdceb;
	border-radius: 4px;
}
.k-spreadsheet-toolbar.k-toolbar .k-button-group .k-button:not(.k-toggle-button) {
	border-radius: 4px;
}
.k-spreadsheet-toolbar > .k-button,
.k-spreadsheet-toolbar > .k-button-group,
.k-spreadsheet-toolbar > .k-widget {
	border-radius: 4px;
}
.k-spreadsheet-toolbar > .k-separator {
	border-color: #bbdceb;
}
.k-spreadsheet-toolbar .k-overflow-anchor {
	border-radius: 0;
}
.k-spreadsheet-popup {
	border-radius: 4px;
}
.k-spreadsheet-popup .k-separator {
	background-color: #bbdceb;
}
.k-spreadsheet-popup .k-button {
	background-color: transparent;
}
.k-spreadsheet-popup .k-button:hover {
	background-color: #7bd2f6;
}
.k-spreadsheet-popup .k-state-active {
	background-color: #13688c;
	color: #000;
}
.k-spreadsheet-popup .k-state-active:hover {
	background-color: #0d475f;
}
.k-spreadsheet-filter-menu .k-details {
	border-color: #bbdceb;
}
.k-spreadsheet-filter-menu .k-details-content .k-space-right {
	background-color: #fff;
}
.k-spreadsheet-filter-menu .k-spreadsheet-value-treeview-wrapper {
	background-color: #fff;
	border-color: #bbdceb;
	border-radius: 4px 0 0 4px;
}
.k-syntax-ref {
	color: #f82;
}
.k-syntax-num {
	color: #09f;
}
.k-syntax-func {
	font-weight: 700;
}
.k-syntax-str {
	color: #38b714;
}
.k-syntax-error {
	color: red;
}
.k-syntax-bool {
	color: #a9169c;
}
.k-syntax-startexp {
	font-weight: 700;
}
.k-syntax-paren-match {
	background-color: #caf200;
}
.k-series-a {
	border-color: #0069a5;
	background-color: rgba(0, 105, 165, 0.15);
}
.k-series-b {
	border-color: #0098ee;
	background-color: rgba(0, 152, 238, 0.15);
}
.k-series-c {
	border-color: #7bd2f6;
	background-color: rgba(123, 210, 246, 0.15);
}
.k-series-d {
	border-color: #ffb800;
	background-color: rgba(255, 184, 0, 0.15);
}
.k-series-e {
	border-color: #ff8517;
	background-color: rgba(255, 133, 23, 0.15);
}
.k-series-f {
	border-color: #e34a00;
	background-color: rgba(227, 74, 0, 0.15);
}
.k-spreadsheet-sheets-remove:hover .k-icon {
	color: #c22;
}
.k-spreadsheet-formula-list .k-state-focused {
	background-color: #13688c;
	color: #fff;
}
.k-spreadsheet-insert-image-dialog {
	border-color: #bbdceb;
	border-radius: 4px;
}
.k-spreadsheet-insert-image-dialog .k-spreadsheet-has-image.k-state-hovered,
.k-spreadsheet-insert-image-dialog .k-spreadsheet-has-image:hover {
	box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
	border-radius: 4px;
}
.k-spreadsheet-insert-image-dialog .k-spreadsheet-has-image.k-state-hovered div,
.k-spreadsheet-insert-image-dialog .k-spreadsheet-has-image:hover div {
	color: #fff;
}
.k-spreadsheet-drawing.k-spreadsheet-active-drawing {
	outline-color: #13688c;
}
.k-spreadsheet-drawing .k-spreadsheet-drawing-handle {
	border-color: #fff;
	background-color: #13688c;
	border-radius: 50%;
}
.k-spreadsheet-drawing-anchor-cell {
	background: rgba(19, 104, 140, 0.2);
}
.k-rtl .k-spreadsheet .k-spreadsheet-has-comment::after {
	border-color: #13688c transparent transparent #13688c;
}
.k-rtl .k-spreadsheet .k-dirty {
	border-color: #db4240 #db4240 transparent transparent;
}
.k-spreadsheet .k-widget[data-property="fontSize"] {
	width: 60px;
}
.k-spreadsheet .k-widget[data-property="format"] {
	width: 100px;
}
.k-spreadsheet .k-widget[data-property="fontFamily"] {
	width: 130px;
}
.k-spreadsheet-toolbar .k-combobox .k-input {
	color: #003f59;
}
.k-spreadsheet-toolbar .k-combobox .k-state-active .k-input,
.k-spreadsheet-toolbar .k-combobox .k-state-focused .k-input,
.k-spreadsheet-toolbar .k-combobox .k-state-hover .k-input {
	color: #003f59;
}
.k-dialog .k-content {
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
}
.k-dialog.k-dialog-titleless .k-content {
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
}
.k-dialog .k-dialog-buttongroup.k-dialog-button-layout-stretched .k-button {
	padding: 12px 12px;
}
.k-dialog .k-dialog-buttongroup.k-dialog-button-layout-normal {
	border-top: solid 1px #bbdceb;
	// background: #d9ecf5;
}
.k-dialog .k-dialog-buttongroup.k-dialog-button-layout-normal .k-button {
	border-radius: 4px;
}
.k-dialog.k-window-sm .k-dialog-buttongroup.k-dialog-button-layout-stretched {
	-ms-flex-direction: column;
	flex-direction: column;
}
.k-dialog.k-window-sm .k-dialog-buttongroup.k-dialog-button-layout-stretched .k-button {
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	padding: 6px 6px;
}
.k-dialog.k-window-md .k-dialog-buttongroup.k-dialog-button-layout-stretched .k-button {
	padding: 8.4px 8.4px;
}
.k-rtl .k-dialog a.k-dialog-action.k-dialog-close {
	left: 0.5em;
}
.k-rtl .k-dialog .k-dialog-buttongroup.k-dialog-button-layout-stretched .k-button:first-child {
	border-bottom-right-radius: 4px;
}
.k-rtl .k-dialog .k-dialog-buttongroup.k-dialog-button-layout-stretched .k-button:last-child {
	border-bottom-left-radius: 4px;
}
.k-rtl .k-dialog .k-dialog-buttongroup.k-dialog-button-layout-normal {
	text-align: left;
}
.k-rtl .k-dialog .k-dialog-buttongroup.k-dialog-button-layout-normal .k-button {
	margin-left: 0;
	margin-right: 0.5em;
}
.k-rtl .k-dialog .k-dialog-buttongroup.k-dialog-button-layout-normal .k-button:first-child {
	margin-right: 0;
}
.k-numeric-wrap .k-i-warning {
	color: #db4240;
	position: absolute;
	top: 0;
	right: 1.9em;
	width: 1.9em;
}
.k-numeric-wrap.k-state-invalid {
	border-color: #db4240;
}
.k-numeric-wrap.k-state-invalid input {
	color: #db4240;
}
.k-rtl .k-numeric-wrap.k-state-invalid .k-i-warning {
	right: auto;
	left: 1.9em;
}
.k-maskedtextbox.k-state-invalid .k-textbox {
	border-color: #db4240;
	color: #db4240;
}
.k-maskedtextbox.k-state-invalid .k-i-warning {
	color: #db4240;
}
.k-dateinput.k-state-invalid .k-textbox {
	color: #db4240;
	border-color: #db4240;
}
.k-dateinput.k-state-invalid .k-i-warning {
	margin-left: 0;
	margin-right: 0.6em;
	color: #db4240;
}
.k-rtl .k-dateinput .k-i-warning {
	margin-right: 0;
	margin-left: 0.6em;
}
.k-datepicker .k-picker-wrap.k-state-invalid,
.k-timepicker .k-picker-wrap.k-state-invalid {
	border-color: #db4240;
}
.k-datepicker .k-picker-wrap.k-state-invalid .k-input,
.k-timepicker .k-picker-wrap.k-state-invalid .k-input {
	color: #db4240;
}
.k-datepicker .k-picker-wrap .k-i-warning,
.k-timepicker .k-picker-wrap .k-i-warning {
	color: #db4240;
	margin-left: 0;
	margin-right: 2.1em;
}
.k-rtl .k-datepicker .k-picker-wrap .k-i-warning,
.k-rtl .k-timepicker .k-picker-wrap .k-i-warning {
	margin-right: 0;
	margin-left: 2.1em;
}
.k-datetimepicker .k-picker-wrap.k-state-invalid {
	border-color: #db4240;
}
.k-datetimepicker .k-picker-wrap.k-state-invalid .k-input {
	color: #db4240;
}
.k-datetimepicker .k-picker-wrap .k-i-warning {
	color: #db4240;
	margin-left: 0;
	margin-right: 4.3em;
}
.k-rtl .k-datetimepicker .k-picker-wrap .k-icon.k-i-warning {
	margin-right: 0;
	margin-left: 4.3em;
}
.k-listbox .k-list-scroller {
	padding: 2px;
	border-color: #bbdceb;
	background-color: #fff;
}
.k-listbox .k-item:hover:not(.k-state-disabled) {
	color: #003f59;
	background-color: #7bd2f6;
	border-color: transparent;
}
.k-listbox .k-drop-hint {
	height: 0;
	border-top: 1px solid #7bd2f6;
}
.k-grid-header .k-i-sort-asc-sm,
.k-grid-header .k-i-sort-desc-sm,
.k-grid-header .k-sort-order {
	color: inherit;
}
.k-scheduler .k-other-month .k-nav-day {
	opacity: 0.6;
}
.k-dropdowngrid-popup {
	border-color: #bbdceb;
	color: #003f59;
	background-color: #fff;
}
.k-dropdowngrid-popup .k-header {
	background-image: linear-gradient(rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
	border-color: #bbdceb;
	color: #00435e;
	// background-color: #d9ecf5;
}
.k-dropdowngrid-popup .k-group-header {
	border-color: #bbdceb;
	color: #00435e;
	// background-color: #d9ecf5;
}
.k-dropdowngrid-popup .k-cell {
	border-color: #bbdceb;
}
.k-dropdowngrid-popup .k-item:nth-child(2n) {
	background-color: #e6f2f8;
}
.k-dropdowngrid-popup .k-footer {
	border-color: #bbdceb;
	color: #00435e;
	// background-color: #d9ecf5;
}
.k-dropdowngrid-popup .k-item.k-state-hover {
	background-image: linear-gradient(rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
	border-color: #bbdceb;
	color: #003f59;
	background-color: #7bd2f6;
}
.k-dropdowngrid-popup .k-item.k-state-selected {
	background-image: linear-gradient(rgba(255, 255, 255, 0.1) 0, rgba(255, 255, 255, 0) 100%);
	border-color: #bbdceb;
	color: #fff;
	background-color: #13688c;
}
.k-dropdowngrid-popup .k-group-cell span {
	background-color: #003f59;
	color: #fff;
}
.k-grid-list > .k-item.k-last > .k-cell,
.k-grid-list > .k-item.k-last > .k-group-cell,
.k-grid-list > .k-item.k-last > .k-spacer-cell {
	border-bottom-color: #003f59;
}
