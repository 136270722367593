.cdk-overlay-container {
    position: fixed;
    //z-index: 1000;
 }
 
  .cdk-global-overlay-wrapper,
  .cdk-overlay-container {
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
 }
 
.cdk-overlay-connected-position-bounding-box, .cdk-overlay-pane, .cdk-overlay-container, .cdk-global-overlay-wrapper {
   z-index: 99998 !important;
}
