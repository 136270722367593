.underlineWarning {
	border-bottom: 2px solid rgb(214, 226, 0);
}

.underlineAlarm {
	border-bottom: 2px solid rgb(255, 0, 0);
}

.underlineRunning {
	border-bottom: 2px solid rgb(21, 255, 0);
}

@-webkit-keyframes underlineBlinkCritical {
	from,
	to {
		border-bottom: 4px solid transparent;
	}
	50% {
		border-bottom: 4px solid rgba(255, 0, 0, 0.5);
	}
	100% {
		border-bottom: 4px solid rgba(255, 0, 0, 1);
	}
}
@keyframes underlineBlinkCritical {
	from,
	to {
		border-bottom: 4px solid transparent;
	}
	50% {
		border-bottom: 4px solid rgba(255, 0, 0, 0.5);
	}
	100% {
		border-bottom: 4px solid rgba(255, 0, 0, 1);
	}
}
.underlineBlinkCritical {
	border-bottom: 4px solid rgba(255, 0, 0, 1);
	-webkit-animation: underlineBlinkCritical 0.5s step-end infinite;
	animation: underlineBlinkCritical 0.5s step-end infinite;
	/* add 'border-color: transparent' if you wish no border to show initially */
}

.white-content{
	.gseLegend {
		background-color: #fff;
	}

	.gseLegendLabel{
		color: black;
	}
}

.gseLegendLabel{
	color: white;
}
.gseLegend {
	font-family: Arial, sans-serif;
	background-color: #27304c;
	padding: 10px;
	margin: 10px;
	border: 3px solid #000;
}
.gseLegend h3 {
	margin-top: 0;
	font-size: 14px;
}
.gseLegend img {
	vertical-align: middle;
}
