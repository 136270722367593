.parent-table-container {
	overflow: auto;
	height: calc(100%);
}

.no-gutters {
	padding: 0;
	margin: 0;
}

.hookup-details-container {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	height: 100%;
	width: 100%;
	padding: 0px 6px;
}

.hookup-details-container-left {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: left;
	height: 100%;
	width: 100%;
	padding: 0px 6px;
}

.red-status-block {
	background-color: FireBrick;
	border-radius: 10px;
	text-align: center;
	margin: 8px;
	padding: 8px;
}

.yellow-status-block {
	background-color: DarkOrange;
	border-radius: 10px;
	text-align: center;
	margin: 8px;
	padding: 8px;
}

.green-status-block {
	background-color: LimeGreen;
	border-radius: 10px;
	text-align: center;
	margin: 8px;
	padding: 8px;
}

.hookup-statistics-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.hookup-time-number {
	font-weight: bold;
	margin: 8px 0px;
}

.past-hookup-chart-container {
	height: 100%;
	width: 100%;
	border-top: 1px solid;
}


#progress {
	height: 100px;
	width: 100px;
	border-radius: 50%;
	display: grid;
	place-items: center;
	float: right;
	margin-top: 3px;
	margin-right: 10px;
}

#progress-value {
	display: block;
	height: calc(100% - 25px);
	width: calc(100% - 25px);
	background-color:#ffffff;
	color:#008fff;
	border-radius: 50%;
	display: grid;
	place-items: center;
	font-weight: 600;
	font-size: 20px;
}

// Card = dark theme
.hookup-details {
	background-color:#4C4E52;
	margin: 10px;
}
.hookup-details-good {
	background-color:#629201;;
	margin: 10px;
}
.hookup-details-caution {
	background-color:mediumpurple;
	margin: 10px;
}

// Card = light theme
.hookup-details-light {
	background-color:lightgrey;
	margin: 10px;
}
.hookup-details-good-light {
	background-color:#C0E17E;
	margin: 10px;
}
.hookup-details-caution-light {
	background-color:#C4B6EC;
	margin: 10px;
}

h4.Title {
	color:#4C4E52;
  }

  span.TitleMedium {
	font-size: medium;
	color:#4C4E52;
  }

  span.TitleMediumLight {
	font-size: medium;
	color:lightgrey;
  }

  span.Title {
	font-size: large;
	color:#4C4E52;
  }

  span.TitleLight {
	font-size: large;
	color:lightgrey;
  }

  span.Subtitle {
	font-size: small;
	color:grey;
  }


  span.OpportunityMedium {
	font-size: medium;
	color:limegreen;
  }

  span.Opportunity {
	font-size: large;
	color:green;
  }

  span.Duration {
	font-size: large;
	color:#4C4E52;

  }
  span.DurationLight {
	font-size: large;
	color:lightgrey;
  }
  span.Docked {
	font-size: large;
	color:#4C4E52;	// #008fff;
  }
  span.UnitOn {
	font-size: large;
	color:#7100ff;
  }
  span.UnitOnAlt {
	font-size: large;
	color:#000fff;
  }
  span.UnitOff {
	font-size: large;
	color:#4C4E52;
  }

  span.DockedLight {
	font-size: large;
	color:#c4e5ff;
  }
  span.UnitOnLight {
	font-size: large;
	color:#7100ff;
  }
  span.UnitOnAltLight {
	font-size: large;
	color:#000fff;
  }
  span.UnitOffLight {
	font-size: large;
	color:#4C4E52;
  }

  span.TurnDocked {
	font-size: xx-large;
	color:white;
  }

.summary {grid-area: summary; text-align: center;}
.summary1 {grid-area: summary1; text-align: center; display: flex; align-items: flex-end; justify-content: center;}
.summary2 {grid-area: summary2; text-align: center;}
.summary3 {grid-area: summary3; text-align: center;}
.summary4 {grid-area: summary4; text-align: center;}
.text1 { width: 100px; grid-area: text1; text-align: right;}
.text2 { width: 100px; grid-area: text2; text-align: right;}
.text3 { width: 100px; grid-area: text3; text-align: right;}
.pt-button1 { width: 50px; grid-area: button1; text-align: right;}
.pt-button2 { width: 50px; grid-area: button2; text-align: right;}
.pt-button3 { width: 50px; grid-area: button3; text-align: right;}

.grid-container-large {
	display: grid;
	grid-template-areas:
		'button1 text1 summary1'
		'button2 text2 summary2'
		'button3 text3 summary2';
	gap: 10px;
	margin: 10px;
}

.pt-gate { grid-area: gate; }
.pt-plane { grid-area: plane; }
.pt-label { grid-area: label; text-align: center; display: flex; align-items: flex-end; justify-content: center;}

.plane-grid-container {
	display: grid;
	grid-template-areas:
		'gate plane'
		'label label';
	gap: 2px;
	margin: 5px;
}


h2.Heading {
	text-align: center;
	margin-bottom: 10px;
}
h5.SubHeading {
	text-align: center;
	margin: 5px;
}

#connect{
	position: absolute;
	top: 50px;
	left: 50px;

}

.time { grid-area: time;  }

.pt-grid-container {
	display: grid;
	grid-template-areas:
	  'left main time time right right'
	  'left main time time right right';
	gap: 10px;
  }

p.pt-label {
	padding-bottom: 7px;
}

.left { grid-area: left; height: 80px; width: 80px; }
.main { grid-area: main;  }
.right { grid-area: right; 	text-align: right;}

.left-label { grid-area: left; }
.main-center { grid-area: main; }

  .grid-container {
	display: grid;
	grid-template-areas:
	  'left main main main right right'
	  'left main main main right right';
	gap: 10px;
  }

  #progress-small {
	height: 70px;
	width: 70px;
	border-radius: 50%;
	display: grid;
	place-items: center;
	float: right;
	margin-top: 3px;
	margin-right: 10px;
}

  #progress-medium {
	height: 80px;
	width: 80px;
	border-radius: 50%;
	display: grid;
	place-items: center;
	float: right;
	margin-top: 3px;
	margin-right: 10px;
}

#progress-value-small {
	display: block;
	height: calc(100% - 20px);
	width: calc(100% - 20px);
	background-color:#ffffff;
	color:#008fff;
	border-radius: 50%;
	display: grid;
	place-items: center;
	font-weight: 600;
	font-size: 18px;
}

#progress-value-medium {
	display: block;
	height: calc(100% - 20px);
	width: calc(100% - 20px);
	background-color:#ffffff;
	color:#008fff;
	border-radius: 50%;
	display: grid;
	place-items: center;
	font-weight: 600;
	font-size: 20px;
}



.left-summary {
	grid-area: left-summary;
	text-align: center;
}
.left-text1 { grid-area: left-text1; text-align: center;}
.left-text2 { grid-area: left-text2; text-align: center;}
.left-text3 { grid-area: left-text3; text-align: center;}
.left-text4 { grid-area: left-text4; text-align: center;}
.right-summary { grid-area: right-summary; text-align: center;}
.right-text1 { grid-area: right-text1; text-align: center;}
.right-text2 { grid-area: right-text2; text-align: center;}
.right-text3 { grid-area: right-text3; text-align: center;}
.right-text4 { grid-area: right-text4; text-align: center;}



.grid-container-summary-small {
	display: grid;
	grid-template-areas:
	  'left-summary left-text1'
	  'left-summary left-text2';
	gap: 0px;
	padding: 0px;
}

.grid-container-summary-medium {
	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-template-areas:
	  'left-summary left-text1'
	  'left-summary left-text2'
	  'left-summary left-text3'
	  'left-summary left-text4';
	gap: 2px;
	padding: 5px;
}

.grid-container-summary {
	display: grid;
	grid-template-areas:
	  'left-summary left-text1 right-summary right-text1'
	  'left-summary left-text2 right-summary right-text2'
	  'left-summary left-text3 right-summary right-text3'
	  'left-summary left-text4 right-summary right-text4';
	gap: 2px;
	padding: 10px;
}

span.Alert {
	display:inline-flex;
	align-items:center;
	justify-content:center;
	width:50px;
	height:50px;
	border-radius:50%;
	background-color:#000;
	color:#fff;
	margin-right: 10px;
}

span.Critical {
	display:inline-flex;
	align-items:center;
	justify-content:center;
	width:50px;
	height:50px;
	border-radius:50%;
	background-color:green;
	color:#fff;
	margin-right: 10px;
}



span.AssetOff {
	display:inline-flex;
	align-items:center;
	justify-content:center;
	width:50px;
	height:30px;
	border-radius:10%;
	background-color:#000;
	color:#fff;
	margin-right: 10px;
}

span.AssetOn {
	display:inline-flex;
	align-items:center;
	justify-content:center;
	width:50px;
	height:30px;
	border-radius:10%;
	background-color:limegreen;
	color:#fff;
	margin-right: 10px;
}

span.AssetOnAlt {
	display:inline-flex;
	align-items:center;
	justify-content:center;
	width:50px;
	height:30px;
	border-radius:10%;
	background-color:mediumpurple;
	color:#fff;
	margin-right: 10px;
}



h2.Heading {
	text-align: center;
	margin-bottom: 30px;
}


h4.Heading {
	text-align: center;
}

h1.Opportunity {
	text-align: center;
	color:limegreen;
}


h4.Opportunity {
	text-align: center;
	color:limegreen;
}


.headers-align .mat-expansion-panel-header-title,
.headers-align .mat-expansion-panel-header-description {
  flex-basis: 0;
}

.headers-align .mat-expansion-panel-header-description {
  justify-content: space-between;
  align-items: center;
}

.indent {
	margin-left: 10px;
	text-align: left;
}

.right-justify {
	margin-right: 10px;
	text-align: right;
}


::ng-deep .mat-progress-bar-fill::after {
    background-color: #c5f768;
}

::ng-deep .mat-progress-bar-buffer {
    background: #E4E8EB;
}

::ng-deep .mat-progress-bar {
    border-radius: 2px;
}

progress {
	border: none;
	width: 100%;
	height: 5px;
  }

  progress {
	color: lightblue;
  }

  progress::-moz-progress-bar {
	background: lightblue;
  }

  progress::-webkit-progress-value {
	background: lightskyblue;
  }

  progress::-webkit-progress-bar {
	background: white;
  }


progress.Alert {
	color: lightblue;
}
progress.Critical {
	color: green;
}

progress.Critical::-moz-progress-bar {
	background: green;
  }

  progress.Critical::-webkit-progress-value {
	background: green;
  }

  progress.Critical::-webkit-progress-bar {
	background: white;
  }

.mat-button-toggle {
	background-color: #4C4E52;
	color: white;
  }

  .mat-button-toggle.light {
	background-color: lightgray;
	color: #4C4E52;
  }

.mat-button-toggle-checked {
	background-color: #2979FF;
	color: white;
  }

  .mat-button-toggle-checked.light {
	background-color: #2979FF;
	color: white;
  }

  .pt-card-title {
	margin-bottom:8px;
	padding-bottom:8px;
	display:flex;
	border-bottom: thin solid grey;
  }


  .pt-container {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
  }

  .pt-sidenav {
	width: 30%;
	height: 100%;
	background-color: transparent;
  }

  .pt-sidenav-content {
	margin: auto;
	width: 70%;
	height: 100%;
  }

  .pt-side-scroll {
	height: 100%;
  }

  .pt-scroll-container {
	overflow-y: scroll;
	height: 100%;
  }


span.Sidebar-Title {
	font-size: medium; /* smaller font on mobile */
	color:lightgrey;
  }


span.Sidebar-Opportunity {
	font-size: medium; /* smaller font on mobile */
	color:limegreen;
}
