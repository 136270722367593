
.mat-tab-label,
.mat-tab-link {
  color: $input-color;
  font-size: 0.8rem;
}

.mat-tab-body,
.mat-tab-body-wrapper,
.mat-tab-group {
  height: calc(100%);
}

.mat-tab-label,
.mat-tab-label-active {
	min-width: 0 !important;
	padding-left: 3px !important;

		padding-right: 30px !important;

		//   margin: 3px !important;
}

.mat-tab-label {
  height: 30px !important;
}

 .mat-tab-body-content {
//   overflow: hidden !important;
   padding: 2px;
   height: calc(100%);
 }

 .mat-tab-header-pagination-chevron {
	border-color: $input-color !important;
 }

.mat-elevation-z4 {
	box-shadow: 0 2px 4px -1px rgb(255 255 255 / 20%), 0 4px 5px 0 rgb(255 255 255 / 14%), 0 1px 10px 0 rgb(255 255 255 / 12%) !important;
}


.white-content {
  .mat-tab-label {
    color: black !important;
    background-color: white !important;
    box-shadow: lightgray !important;
  }

  	.mat-tab-header-pagination-chevron {
		border-color: rgba(0, 0, 0, .38) !important;
	}

	.mat-elevation-z4 {
		box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%) !important;
	}
}

.mat-badge-content {
	right: -22px !important;
	top: -5px !important;
	background-color: red;
}
