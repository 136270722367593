.navigation-tab {
	flex-direction: column;
	flex-basis: content;
	background-color: transparent;
	color: white;
}
.tactical-navigation-tab-label {
	float        : left;
	width        : 22px;
	padding-right: 0;
	height       : 100%;
}

.tactical-navigation-tab-margin {
	margin-top: 40px;
}
/* Hiding scrollbar for Chrome, Safari and Opera */

#navigation-content {
	overflow-y: scroll;
	overflow-x: hidden;
	//height: 100%;
	height: 500px;
}

#navigation-content::-webkit-scrollbar{
	display: none !important;
}

/* Hiding scrollbar for IE, Edge and Firefox */
#navigation-content {
	scrollbar-width: none;  /* Firefox */
	-ms-overflow-style: none;  /* IE and Edge */
}

.navigation-content {
	background-color: transparent;
	color: white;
	float: left;
	padding-bottom: 0.5rem;
}

.video-container {
	display: flex;
	align-items: flex-start;
}

.main-content-with-nav-invisible {
	flex-grow: 4;
	flex-direction: row-reverse;
	width: 100%;
}
.main-content-with-nav-visible {
	flex-shrink: 4;
	flex-direction: row;
	width: 93.5%;
}

.flex-container {
    display: flex;
	flex-wrap: nowrap;
	align-items: stretch;
}

//-- Accordion --//
	.accordion {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
	}
//-- End Accordion --//

//-- Initial Tab --//
	.vertical-text {
		position: relative;
		cursor: pointer;
		font-size: xx-small;
		background-color: $blue-500;
		padding: 5px;
		border-radius: 0 0 5px 5px;
		border: solid 0.2px #aaa;
		float: right;
		-webkit-transform: rotate(90deg) translate(100%,100%);
				transform: rotate(90deg) translate(100%,100%);
		-webkit-transform-origin: 100% 100%;
				transform-origin: 100% 100%;
	}

	.nav-visible {
		visibility: visible;
		opacity: 2;
		width: 8.5%;
		z-index: 2;
		transition: all 0.2s ease;
	}

	.nav-hidden {
		visibility: hidden;
		opacity: 0;
		width: 0;
		z-index: -1;
		transition: all 0.2s ease;
	}
//-- End Initial Tab --//

//-- Accordion Item (parent) --//
	.accordion-item {
		display: inline-block;
		box-sizing: border-box;
		transition: all 0.5s ease;
		white-space: nowrap;
		background-color: transparent;
		border-width: 1px;
		border-style: solid;
		border-color: $gray-900;
	}

	.accordion-item + .accordion-item {
		border-top: none;
	}

	.accordion-item-header {
		align-content: center;
		justify-content: space-between;
		font-size: 0.85em;
		vertical-align: bottom;
	}

	.accordion-item-header,
	.accordion-item-body {
		transition: all 0.5s ease;
		white-space: nowrap;
	}

	.accordion-item-header:hover {
		cursor: pointer;
	}

	.accordion-item:first-child {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	.accordion-item:last-child {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	.accordion-item-visible {
		display: block;
		opacity: 2;
		height: 100%;
		transition: all 0.3s ease;
		pointer-events:initial !important;
		z-index: 1000;
	}

	.accordion-item-hidden {
		display:none;
		opacity: 0;
		height: 0;
		transition: all 0.3s ease;
		pointer-events: none !important;
		z-index: -1000;
	}
//-- End Accordion Item (parent) --//

//-- Accordion Item (child) --//
	.accordion-child-item {
		display: inline-block;
		box-sizing: border-box;
		transition: all 0.5s ease;
		white-space: nowrap;
		background-color: transparent;
	}

	.accordion-child-item + .accordion-child-item {
		border-top: none;
	}

	.accordion-child-item-header {
		align-content: center;
		justify-content: space-between;
		font-size: 0.85em;
		vertical-align: bottom;
		white-space: nowrap;
	}

	.accordion-child-item-header,
	.accordion-child-item-body {
		transition: all 0.5s ease;
		white-space: nowrap;
	}

	.accordion-child-item-header:hover {
		cursor: pointer;
	}

	.accordion-child-item:first-child {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	.accordion-child-item:last-child {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	.accordion-child-item-visible {
		display: block;
		opacity: 2;
		height: 100%;
		transition: all 0.3s ease;
		pointer-events:initial !important;
		z-index: 1000;
	}

	.accordion-child-item-hidden {
		display:none;
		opacity: 0;
		height: 0;
		transition: all 0.3s ease;
		pointer-events: none !important;
		z-index: -1000;
	}
//-- End Accordion Item (child) --//

//-- Accordion Item (grandchild) --//
.accordion-grandchild-item {
	display: inline-block;
	box-sizing: border-box;
	transition: all 0.5s ease;
	white-space: nowrap;
	background-color: transparent;
}

.accordion-grandchild-item + .accordion-grandchild-item {
	border-top: none;
}

.accordion-grandchild-item-header {
	align-content: center;
	justify-content: space-between;
	font-size: 0.85em;
	vertical-align: bottom;
	white-space: nowrap;
}

.accordion-grandchild-item-header,
.accordion-grandchild-item-body {
	transition: all 0.5s ease;
	white-space: nowrap;
}

.accordion-grandchild-item-header:hover {
	cursor: pointer;
}

.accordion-grandchild-item:first-child {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.accordion-grandchild-item:last-child {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.accordion-grandchild-item-visible {
	display: block;
	opacity: 2;
	height: 100%;
	transition: all 0.3s ease;
	pointer-events:initial !important;
	z-index: 1000;
}

.accordion-grandchild-item-hidden {
	display:none;
	opacity: 0;
	height: 0;
	transition: all 0.3s ease;
	pointer-events: none !important;
	z-index: -1000;
}
//-- End Accordion Item (grandchild) --//

//-- Accordion Item (great-grandchild) --//
.accordion-great-grandchild-item {
	display: inline-block;
	box-sizing: border-box;
	transition: all 0.5s ease;
	white-space: nowrap;
	background-color: transparent;
}

.accordion-great-grandchild-item + .accordion-great-grandchild-item {
	border-top: none;
}

.accordion-great-grandchild-item-header {
	align-content: center;
	justify-content: space-between;
	font-size: 0.85em;
	vertical-align: bottom;
	white-space: nowrap;
}

.accordion-great-grandchild-item-header,
.accordion-great-grandchild-item-body {
	transition: all 0.5s ease;
	white-space: nowrap;
}

.accordion-great-grandchild-item-header:hover {
	cursor: pointer;
}

.accordion-great-grandchild-item:first-child {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.accordion-great-grandchild-item:last-child {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.accordion-great-grandchild-item-visible {
	display: block;
	opacity: 2;
	height: 100%;
	transition: all 0.3s ease;
	pointer-events:initial !important;
	z-index: 1000;
}

.accordion-great-grandchild-item-hidden {
	display:none;
	opacity: 0;
	height: 0;
	transition: all 0.3s ease;
	pointer-events: none !important;
	z-index: -1000;
}
//-- End Accordion Item (great-grandchild) --//


//-- Default colors (dark theme) --//
	.accordion-item {
		color: white;
		border-color: $gray-900;
	}

	.accordion-item-selected {
		background-color:$gray-200;
		color: black;
	}


	.accordion-item-header:hover {
		background-color: $gray-100;
		color: black;
	}

	.accordion-child-item-selected {
		background-color:$gray-500;
		color: black;
	}


	.accordion-child-item-header:hover {
		background-color: $gray-400;
		color: black;
	}

	.accordion-grandchild-item-selected {
		background-color:$gray-600;
		color: white;
	}


	.accordion-grandchild-item-header:hover {
		background-color: $gray-500;
		color: white;
	}

	.accordion-great-grandchild-item-selected {
		background-color:$gray-800;
		color: white;
	}


	.accordion-great-grandchild-item-header:hover {
		background-color: $gray-700;
		color: white;
	}

	//-- End Default colors (dark theme) --//

//-- Default colors (light theme) --//
.white-content {

	.navigation-tab {
		color: black;
	}

	.navigation-content {
		color: black;
	}

	.accordion-item {
		border-color: $gray-200;
		color: black;
	}

	.vertical-text {
		background-color: $gray-500;
	}

	.accordion-item-selected {
		background-color:$gray-300;
	}

	.accordion-item-header:hover {
		background-color: $gray-400;
	}
}
//-- End default colors (light theme) --//
