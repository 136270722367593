/*!

 =========================================================
 * Black Dashboard Pro Angular - v1.0.0
 =========================================================

 * Product Page: http://creative-tim.com/product/black-dashboard-pro-angular
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)


 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

// Core

@import "black-dashboard/custom/functions";
@import "black-dashboard/bootstrap/functions";

@import "black-dashboard/custom/variables";
@import "black-dashboard/bootstrap/variables";
//Our created variables - Dylan Richard
@import "black-dashboard/jbt/variables";

@import "black-dashboard/custom/mixins";
@import "black-dashboard/bootstrap/mixins";

// Bootstrap components

@import "black-dashboard/bootstrap/root";
@import "black-dashboard/bootstrap/reboot";
@import "black-dashboard/bootstrap/type";
@import "black-dashboard/bootstrap/images";
@import "black-dashboard/bootstrap/code";
@import "black-dashboard/bootstrap/grid";
@import "black-dashboard/bootstrap/tables";
@import "black-dashboard/bootstrap/forms";
@import "black-dashboard/bootstrap/buttons";
@import "black-dashboard/bootstrap/transitions";
@import "black-dashboard/bootstrap/dropdown";
@import "black-dashboard/bootstrap/button-group";
@import "black-dashboard/bootstrap/input-group";
@import "black-dashboard/bootstrap/custom-forms";
@import "black-dashboard/bootstrap/nav";
@import "black-dashboard/bootstrap/navbar";
@import "black-dashboard/bootstrap/card";
@import "black-dashboard/bootstrap/breadcrumb";
@import "black-dashboard/bootstrap/pagination";
@import "black-dashboard/bootstrap/badge";
@import "black-dashboard/bootstrap/jumbotron";
@import "black-dashboard/bootstrap/alert";
@import "black-dashboard/bootstrap/progress";
@import "black-dashboard/bootstrap/media";
@import "black-dashboard/bootstrap/list-group";
@import "black-dashboard/bootstrap/close";
@import "black-dashboard/bootstrap/modal";
@import "black-dashboard/bootstrap/tooltip";
@import "black-dashboard/bootstrap/popover";
@import "black-dashboard/bootstrap/carousel";
@import "black-dashboard/bootstrap/utilities";
@import "black-dashboard/bootstrap/print";

// Custom components

@import "black-dashboard/custom/reboot.scss";
@import "black-dashboard/custom/global.scss";
@import "black-dashboard/custom/utilities.scss";
@import "black-dashboard/custom/alerts.scss";
@import "black-dashboard/custom/avatars.scss";
@import "black-dashboard/custom/badge.scss";
@import "black-dashboard/custom/buttons.scss";
@import "black-dashboard/custom/close.scss";
@import "black-dashboard/custom/custom-forms.scss";
@import "black-dashboard/custom/dropdown.scss";
@import "black-dashboard/custom/footer.scss";
@import "black-dashboard/custom/forms.scss";
@import "black-dashboard/custom/grid.scss";
@import "black-dashboard/custom/icons.scss";
@import "black-dashboard/custom/images.scss";
@import "black-dashboard/custom/input-group.scss";
@import "black-dashboard/custom/info-areas.scss";
@import "black-dashboard/custom/modal.scss";
@import "black-dashboard/custom/nav.scss";
@import "black-dashboard/custom/navbar.scss";
@import "black-dashboard/custom/pagination.scss";
@import "black-dashboard/custom/tabs.scss";
@import "black-dashboard/custom/pills.scss";
@import "black-dashboard/custom/popover.scss";
@import "black-dashboard/custom/progress.scss";
@import "black-dashboard/custom/type.scss";
@import "black-dashboard/custom/tables";
@import "black-dashboard/custom/checkboxes-radio";
@import "black-dashboard/custom/fixed-plugin";
@import "black-dashboard/custom/sidebar-and-main-panel.scss";
@import "black-dashboard/custom/misc.scss";
@import "black-dashboard/custom/rtl.scss";
@import "black-dashboard/custom/timeline.scss";
@import "black-dashboard/custom/login";

// Vendor / Plugins

@import "black-dashboard/custom/vendor/plugin-datetimepicker.scss";
@import "black-dashboard/custom/vendor/plugin-nouislider.scss";
@import "black-dashboard/custom/vendor/plugin-fullcalendar.scss";
@import "black-dashboard/custom/vendor/plugin-sweetalert2";
@import "black-dashboard/custom/vendor/plugin-bootstrap-switch.scss";
@import "black-dashboard/custom/vendor/plugin-bootstrap-select.scss";
@import "black-dashboard/custom/vendor/plugin-jasny-fileupload.scss";
@import "black-dashboard/custom/vendor/plugin-tagsinput.scss";
@import "black-dashboard/custom/vendor/plugin-perfect-scrollbar.scss";
@import "black-dashboard/custom/vendor/plugin-jquery.jvectormap.scss";
@import "black-dashboard/custom/vendor/plugin-datatables.net.scss";
@import "black-dashboard/custom/vendor/plugin-animate-bootstrap-notify.scss";
@import "black-dashboard/custom/vendor/plugin-card-wizard.scss";
@import "black-dashboard/custom/vendor/plugin-chartjs.scss";

// example pages and sections

@import "black-dashboard/custom/example-pages.scss";

// light mode

@import "black-dashboard/custom/white-content.scss";

// Cards

@import "black-dashboard/custom/card";
@import "black-dashboard/custom/cards/card-chart";
@import "black-dashboard/custom/cards/card-map";
@import "black-dashboard/custom/cards/card-user";
@import "black-dashboard/custom/cards/card-task";
@import "black-dashboard/custom/cards/card-animation-on-hover";
@import "black-dashboard/custom/cards/card-collapse";
@import "black-dashboard/custom/cards/card-contributions";
@import "black-dashboard/custom/cards/card-lock";
@import "black-dashboard/custom/cards/card-pricing";
@import "black-dashboard/custom/cards/card-profile";
@import "black-dashboard/custom/cards/card-plain";
@import "black-dashboard/custom/cards/card-register";
@import "black-dashboard/custom/cards/card-stats";
@import "black-dashboard/custom/cards/card-subcategories";
@import "black-dashboard/custom/cards/card-testimonials";
@import "black-dashboard/custom/cards/card-wizard";

// Angular Differences
@import "black-dashboard/angular-differences/angular-differences";

//----------------------------------------------------------------
// JBT AeroTech Changes
//----------------------------------------------------------------

//-- Context Menu
@import "black-dashboard/jbt/context-menu";

//-- Scrollbar
@import "black-dashboard/jbt/scrollbar";
//-- JBT logo
@import "black-dashboard/jbt/company-logo";
//-- card
@import "black-dashboard/jbt/card";
//-- table
@import "black-dashboard/jbt/table";
//-- kendo blue opal
@import "black-dashboard/jbt/kendoBlueOpal/kendoBlueOpal";
//-- kendo
@import "black-dashboard/jbt/kendoUI/kendoUI";
//-- Angular Material
@import "black-dashboard/jbt/mat/mat-card";
@import "black-dashboard/jbt/mat/mat-dialog";
@import "black-dashboard/jbt/mat/mat-drawer";
@import "black-dashboard/jbt/mat/mat-form-field";
@import "black-dashboard/jbt/mat/mat-sidenav";
@import "black-dashboard/jbt/mat/mat-tab";
@import "black-dashboard/jbt/mat/mat-table";
@import "black-dashboard/jbt/mat/mat-toggle";
@import "black-dashboard/jbt/mat/mat-expansion";
@import "black-dashboard/jbt/mat/mat-option";

//-- cameras
@import "black-dashboard/jbt/camera/camera-button";
@import "black-dashboard/jbt/camera/camera-container";
@import "black-dashboard/jbt/camera/camera-image";
//-- dashboard / widgets / gridster
@import "black-dashboard/jbt/dashboard/gridster";
@import "black-dashboard/jbt/dashboard/cdk";
@import "black-dashboard/jbt/dashboard/widget-card";
//-- buttons
@import "black-dashboard/jbt/button/buttons";
//-- chart
@import "black-dashboard/jbt/chart/chart";
@import "black-dashboard/jbt/comm-loss";
//-- input
@import "black-dashboard/jbt/input";
//-- slider
@import "black-dashboard/jbt/slider";
//-- form-control option for dark theme dropdown - list must come in dark, otherwise it is a light background with light text. --Kirk T. Sherer, May 7, 2020.
@import "black-dashboard/jbt/form-control";
//-- file upload
@import "black-dashboard/jbt/file-upload/drop-area";
@import "black-dashboard/jbt/file-upload/file-upload";
//-- asset styles
@import "black-dashboard/jbt/asset-styles";
//-- GSE Specific styles
@import "black-dashboard/jbt/gse/gse";
//--dynamic form
@import "black-dashboard/jbt/dynamic-form";
//-- user issues
@import "black-dashboard/jbt/user-issues";
//-- modal styling for standard HTML modal dialog forms
@import "black-dashboard/jbt/modals";
//-- Recursive Data Navigator (RDN) Styling
@import "black-dashboard/jbt/recursive-data-navigator";
//-- Release Notes
@import "black-dashboard/jbt/release-notes";
//-- Broadcast Message
@import "black-dashboard/jbt/broadcast-message";
//-- Summary Widget Styling
@import "black-dashboard/jbt/summary/pca";
@import "black-dashboard/jbt/summary/pbb";
@import "black-dashboard/jbt/summary/gpu";
@import "black-dashboard/jbt/summary/gse";
//-- Quick View Styling
@import "black-dashboard/jbt/quick-view/pca";
@import "black-dashboard/jbt/quick-view/pbb";
@import "black-dashboard/jbt/quick-view/gpu";
//-- Navigation
@import "black-dashboard/jbt/navigation";
@import "black-dashboard/jbt/tactical-navigation";
//-- Perfect Turn
@import "black-dashboard/jbt/perfect-turn";
//-- Tactical
@import "black-dashboard/jbt/tactical";
//-- SignalR Test
@import "black-dashboard/jbt/signalr-test";
//-- Video Player	
@import "black-dashboard/jbt/video-player"
