.parent-table-container {
    overflow: auto;
    height  : calc(100%);
}

.resizeContainer {
    display        : flex;
    justify-content: flex-start;
    flex-direction : row;
    height         : 100%;
    overflow-y     : auto;
}

#regularMap {
    position: relative;
    width   : 100%;
}

.card-header {
    min-height: 75px;
}

.linear-gauge-container {
    display   : block;
    text-align: center;
}

h4,
h5 {
    margin: 2px;
}

.asset-summary-left {
    display       : inline-block;
    vertical-align: top;
    height        : 100%;
    position      : relative;
}

.asset-summary-right {
    display : inline-block;
    position: relative;
}

.centered-img {
    display     : block;
    margin-left : auto;
    margin-right: auto;
    width       : 100%;
    height      : 100%;
}



.k-gauge {
    display: block;
}
