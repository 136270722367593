.camera-container {
    flex-basis: 100% !important;
    border: none !important; 
    overflow-y: auto !important;
    max-width: 100% !important;
    max-height: 100% !important;
}

.camera-iframe {
    height: 100% !important;
    width: 100% !important;
}

.clear-css-styling {
    all: initial;
    * {
      all: unset;
    }
}