.underlineWarning {
	border-bottom: 2px solid rgb(214, 226, 0);
}

.underlineAlarm {
	border-bottom: 2px solid rgb(255, 0, 0);
}

.underlineRunning {
	border-bottom: 2px solid rgb(21, 255, 0);
}

@-webkit-keyframes underlineBlinkCritical {
	from,
	to {
		border-bottom: 4px solid transparent;
	}
	50% {
		border-bottom: 4px solid rgba(255, 0, 0, 0.5);
	}
	100% {
		border-bottom: 4px solid rgba(255, 0, 0, 1);
	}
}
@keyframes underlineBlinkCritical {
	from,
	to {
		border-bottom: 4px solid transparent;
	}
	50% {
		border-bottom: 4px solid rgba(255, 0, 0, 0.5);
	}
	100% {
		border-bottom: 4px solid rgba(255, 0, 0, 1);
	}
}
.underlineBlinkCritical {
	border-bottom: 4px solid rgba(255, 0, 0, 1);
	-webkit-animation: underlineBlinkCritical 0.5s step-end infinite;
	animation: underlineBlinkCritical 0.5s step-end infinite;
	/* add 'border-color: transparent' if you wish no border to show initially */
}

.white-content{
	.gseLegend {
		background-color: #fff;
	}

	.gseLegendLabel{
		color: black;
	}
}

.gseLegendLabel{
	color: white;
}
.gseLegend {
	font-family: Arial, sans-serif;
	background-color: #27304c;
	padding: 10px;
	margin: 10px;
	border: 3px solid #000;
}
.gseLegend h3 {
	margin-top: 0;
	font-size: 14px;
}
.gseLegend img {
	vertical-align: middle;
}

@-moz-keyframes blink {
    0% {
        opacity:1;
    }
    50% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-webkit-keyframes blink {
    0% {
        opacity:1;
    }
    50% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}
/* IE */
@-ms-keyframes blink {
    0% {
        opacity:1;
    }
    50% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}
/* Opera and prob css3 final iteration */
@keyframes blink {
    0% {
        opacity:1;
    }
    50% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

.blink-image {
    -moz-animation: blink normal 2s infinite ease-in-out; /* Firefox */
    -webkit-animation: blink normal 2s infinite ease-in-out; /* Webkit */
    -ms-animation: blink normal 2s infinite ease-in-out; /* IE */
    animation: blink normal 2s infinite ease-in-out; /* Opera and prob css3 final iteration */
}

.bounce-image-container{
    margin-top: 10px;
}
.bounce-image {
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
.bounce-1 {
    animation-name: bounce-1;
    animation-timing-function: linear;
}
@keyframes bounce-1 {
    0%   { transform: translateY(0); }
    50%  { transform: translateY(-10px); }
    100% { transform: translateY(0); }
}

