.cdk-overlay-container {
    position: fixed;
	z-index: 999 !important;
 }

  .cdk-global-overlay-wrapper,
  .cdk-overlay-container {
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
 }
