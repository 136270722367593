.camera-button {
    width:65px !important; 
    font-size:0.6rem !important;
    white-space: nowrap !important; 
    text-align: center !important; 
    text-transform: capitalize !important; 
}

.calendar-button {
    width      : 25px !important;
    height     : 20px !important;
    font-size  : 0.6rem !important;
    white-space: nowrap !important;
    text-align : center !important;
}