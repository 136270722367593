//dark content css here
.mat-expansion-panel-content, .mat-expansion-panel-header-title, .mat-expansion-panel-header-description {
  font-size: 0.7rem !important;
}


.mat-expansion-panel {
  background: #000;
}

.mat-expansion-panel-header-title {
  color: rgba(255, 255, 255, 0.8);
}
.mat-expansion-panel-header-description {
	color: rgba(255, 255, 255, 0.8);
}
.mat-expansion-panel-content {
  color: rgba(255, 255, 255, 0.8);
}

.white-content {
  .mat-expansion-panel {
    background: #fff;
  }
  .mat-expansion-panel-header-title {
    color: rgba(0, 0, 0, 0.87);
  }
  .mat-expansion-panel-header-description {
    color: rgba(0, 0, 0, 0.87);
  }
  .mat-expansion-panel-content {
    color: rgba(0, 0, 0, 0.87);
  }
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
  	background: #fff;
  }

  }

  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
  	background: #000;
}
