
.table-coloring {
    tr :nth-child(even):not(.rdn-input, .rdn-header-row, .typeheader) {
        background-color:darken($background-black, 3%);
    }
    
    tr :hover:not(.rdn-input, .rdn-header-row, .typeheader) {
        background-color: lighten(#1e1e2f, 12%) !important;   
    }
    .selected-row:not(.rdn-input, .rdn-header-row, .typeheader) {
        background-color:lighten(#1e1e2f, 12%) !important;
    }

    .white-content {
        tr :nth-child(even):not(.rdn-input, .rdn-header-row, .typeheader) {
            background-color: darken($light-bg, 3%);
        }
        tr :hover:not(.rdn-input, .rdn-header-row, .typeheader) {
            background-color: lighten(#1e1e2f, 12%) !important;   
        }
        .selected-row:not(.rdn-input, .rdn-header-row, .typeheader) {
            background-color:lighten(#1e1e2f, 12%) !important;
        }
    }
}

// .even-numbered-row-for-table {
//     background-color:darken(#f5f6fa, 3%) !important;
// }

// .selected-row {
//     background-color:darken(#f5f6fa, 12%) !important;
// }

// tr :hover {
//     background-color:darken(#f5f6fa, 12%) !important;
// }

.rdn-input {
    background-color: white !important;
    color           : black !important;
}

.recursive-data-navigator {
    .even-numbered-row-for-table:not(.rdn-input, .rdn-header-row, .typeheader) {
        background-color: lighten($background-black, 3%) !important;   
    }
    tr :hover:not(.rdn-input, .rdn-header-row, .typeheader) {
        background-color: lighten($background-black, 12%) !important;   
    }
    .selected-row-for-rdn:not(.rdn-input, .rdn-header-row, .typeheader) {
        background-color:lighten($background-black, 15%) !important;
    }
}

.form-control-static {
    margin-top: 19px !important;
}

.white-content {
    .recursive-data-navigator {
        .even-numbered-row-for-table:not(.rdn-input, .rdn-header-row, .typeheader) {
            background-color: darken($light-bg, 3%) !important;   
        }
        tr :hover:not(.rdn-input, .rdn-header-row, .typeheader) {
            background-color: darken($light-bg, 12%) !important;   
        }
        .selected-row-for-rdn:not(.rdn-input, .rdn-header-row, .typeheader) {
            background-color:darken($light-bg, 15%) !important;
        }
    }   
}

.add-button {
    border           : 1px solid gray;
    background-color : lightblue !important;
    width            : 100%;
    min-height       : 35px;
    cursor           : pointer;
    padding          : 7px;
    -ms-border-radius: 4px;
    border-radius    : 4px;
    margin-bottom    : 7px;
}

.opaque-backdrop {
    pointer-events: none;
    z-index       : 1060;
    opacity       : 0.35;
}

.add-new-record {
    z-index       : 1080;
}

.ordinal-list {
    // overflow-y: scroll;
}

.ordinal-list-box {
    cursor: move;
}

.cdk-drag-preview {
    box-sizing   : border-box;
    border-radius: 4px;
    box-shadow   : 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
    opacity: 0;
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.ordinal-list-box:last-child {
    border: none;
}

.ordinal-list .cdk-drop-list-dragging .ordinal-list-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.table-container {
    height    : calc(100vh - 199px) !important;
    max-height: calc(100vh - 199px);
    overflow-y: auto;
}

.table-container thead th {
    position: sticky;
    top     : 0;
}

.k-grid tr.dragging {
    background-color: #f45c42;
}

.btn-small {
    font-size: 0.575rem !important;
}