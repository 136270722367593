// Global settings

$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: true !default;
$enable-gradients: true !default;
$enable-transitions: true !default;
$enable-grid-classes: true !default;
$enable-print-styles: true !default;

// Color system

$white: #ffffff !default;
$gray-100: #f6f9fc !default;
$gray-200: #e9ecef !default;
$gray-300: #e3e3e3 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default; // Line footer color
$gray-700: #212427 !default; // Line p color
$gray-800: #32325d !default; // Line heading color
$gray-900: #212529 !default;
$link-disabled: #666666 !default;
$transparent-bg: transparent !default;
$light-bg: #f5f6fa !default;
$dark-background: #555555 !default;

$white-company-logo: url("/projects/mobile/src/assets/scss/oshkosh_logo_white.png");
$color-company-logo: url("/projects/mobile/src/assets/scss/oshkosh_logo.png");

$grays: () !default;
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900,
  ),
  $grays
);

$blue: #5e72e4 !default;
$indigo: #5603ad !default;
$purple: #8965e0 !default;
$pink: #f3a4b5 !default;
$red: #f5365c !default;
$orange: #fb6340 !default;
$yellow: #ffd600 !default;
$green: #2dce89 !default;
$teal: #11cdef !default;
$cyan: #2bffc6 !default;

$line-height-lg: 1.625rem !default;
$line-height-sm: 1.5 !default;
$line-height: 1.35em !default;
$border-radius-lg: 0.3rem !default;

$opacity-gray-3: rgba(222, 222, 222, 0.3) !default;
$opacity-gray-5: rgba(222, 222, 222, 0.5) !default;
$opacity-gray-8: rgba(222, 222, 222, 0.8) !default;

$opacity-5: rgba(255, 255, 255, 0.5) !default;
$opacity-6: rgba(255, 255, 255, 0.6) !default;
$opacity-8: rgba(255, 255, 255, 0.8) !default;

$opacity-1: rgba(255, 255, 255, 0.1) !default;
$opacity-2: rgba(255, 255, 255, 0.2) !default;

// end beniamin

$colors: () !default;
$colors: map-merge(
  (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "light": $gray-400,
    "lighter": $gray-200,
    "gray-dark": $gray-800,
  ),
  $colors
);

$default-color-opacity: rgba(182, 182, 182, 0.6) !default;
$orange-color: #f96332 !default;

$default: #344675 !default;
$primary: #e14eca !default;
$secondary: #f4f5f7 !default;
$success: #00f2c3 !default;
$info: #1d8cf8 !default;
$warning: #ff8d72 !default;
$danger: #fd5d93 !default;
$black: #222a42 !default;

//-- menu colors -- Kirk T. Sherer, April 30, 2020
//-- dark theme colors ---
$dark-gray: #696868 !default;
$dark-red: #7d0f0f !default;
$dark-blue: #102a8d !default;
$dark-green: #075013 !default;
//-- light theme colors ---
$light-gray: #a4a3a3 !default;
$light-red: #fa5c5c !default;
$fire-red: #Ce0B15 !default;
$light-blue: #a5b6f3 !default;
$bright-green: #c0f10d !default;
$yellow-gold: #edc025 !default;
$tan: #d2b48c !default;

//-- menu gradients -- Kirk T. Sherer, April 30, 2020
//-- dark theme colors ---
$dark-gray-states: #867e7e !default;
$dark-red-states: #f15757 !default;
$dark-blue-states: #6986f7 !default;
$dark-green-states: #12b32d !default;
//-- light theme colors ---
$light-gray-states: #cccaca !default;
$light-red-states: #f8a7a7 !default;
$fire-red-states: #Ce0B15 !default;
$light-blue-states: #b9c8ff !default;
$bright-green-states: #e7ff91 !default;
$yellow-gold-states: #f7db77 !default;
$tan-states: #daccba !default;

// gradient
$default-states: #263148 !default;
$primary-states: #ba54f5 !default;
$success-states: #0098f0 !default;
$info-states: #3358f4 !default;
$warning-states: #ff6491 !default;
$danger-states: #ec250d !default;
$black-states: #212427 !default;

$background-black: #1e1e2f !default;
$background-states-black: #1e1e24 !default;

// opacity
$default-opacity: rgba(182, 182, 182, 0.6) !default;
$primary-opacity: rgba(249, 99, 50, 0.3) !default;
$success-opacity: rgba(24, 206, 15, 0.3) !default;
$info-opacity: rgba(44, 168, 255, 0.3) !default;
$warning-opacity: rgba(255, 178, 54, 0.3) !default;
$danger-opacity: rgba(255, 54, 54, 0.3) !default;

$light-gray: #e3e3e3 !default;
$medium-gray: #dddddd !default;
$dark-gray: #9a9a9a !default;
$card-stats-gray: #808080 !default;
$active-gray: #777777 !default;
$nav-gray: #444444 !default;
$search-gray: #b7b7b7 !default;
$calendar-gray: #9499a7 !default;
$active-blue: #00bbff !default;

$light: $gray-500 !default;
$dark: $gray-900 !default;
$darker: darken($gray-900, 15%) !default;

$facebook: #3b5998 !default;
$facebook-states: darken($facebook, 5%) !default;
$twitter: #55acee !default;
$twitter-states: darken($twitter, 5%) !default;
$google-plus: #dd4b39 !default;
$google-plus-states: darken($google-plus, 5%) !default;
$instagram: #125688 !default;
$instagram-states: lighten($instagram, 6%) !default;
$linkedin: #0077b5 !default;
$linkedin-states: darken($linkedin, 5%) !default;
$pinterest: #cc2127 !default;
$pinterest-states: lighten($pinterest, 6%) !default;
$youtube: #e52d27 !default;
$youtube-states: lighten($youtube, 6%) !default;
$dribbble: #ea4c89 !default;
$dribbble-states: lighten($dribbble, 6%) !default;
$github: #333333 !default;
$github-states: #ccc !default;
$reddit: #ff4500 !default;
$reddit-states: lighten($reddit, 6%) !default;
$tumblr: #35465c !default;
$tumblr-states: lighten($tumblr, 6%) !default;
$behance: #1769ff !default;
$behance-states: lighten($behance, 6%) !default;

//custom colors$body-text-color-light: "white";

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "default": $default,
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "white": $white,
    "neutral": $white,
    "dark": $dark,
    "darker": $darker,
  ),
  $theme-colors
);

$brand-colors: () !default;
$brand-colors: map-merge(
  (
    "facebook": $facebook,
    "twitter": $twitter,
    "google-plus": $google-plus,
    "instagram": $instagram,
    "pinterest": $pinterest,
    "youtube": $youtube,
    "dribbble": $dribbble,
    "github": $github,
    "reddit": $reddit,
    "behance": $behance,
    "tumblr": $tumblr,
    "linkedin": $linkedin,
  ),
  $brand-colors
);

/*     brand Colors              */
$brand-primary: $primary !default;
$brand-info: $info !default;
$brand-success: $success !default;
$brand-warning: $warning !default;
$brand-danger: $danger !default;
$brand-inverse: $black !default;

$topbar-x: topbar-x !default;
$topbar-back: topbar-back !default;
$bottombar-x: bottombar-x !default;
$bottombar-back: bottombar-back !default;

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 200 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-900 !default;
$yiq-text-light: $white !default;

// Action colors

$star-rating-color: $gray-400 !default;
$star-rating-color-active: $yellow !default;

$favorite-color: $yellow !default;
$like-color: $blue !default;
$love-color: $red !default;

// Body

$body-bg: $white !default;
$body-color: $gray-100 !default;

// Sections

$section-colors: () !default;
$section-colors: map-merge(
  (
    "primary": $body-bg,
    "secondary": $secondary,
    "light": $gray-400,
    "dark": $dark,
    "darker": $darker,
  ),
  $section-colors
);

// Links

$link-color: $primary !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none !default;

// Grid breakpoints

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// Spacing

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: $spacer,
    4: (
      $spacer * 1.5,
    ),
    5: (
      $spacer * 3,
    ),
    "sm": (
      $spacer * 2,
    ),
    "md": (
      $spacer * 4,
    ),
    "lg": (
      $spacer * 6,
    ),
    "xl": (
      $spacer * 8,
    ),
  ),
  $spacers
);

$padding-base-vertical: 0.5rem !default;
$padding-base-horizontal: 0.7rem !default;

// $padding-btn-vertical: 11px !default;
// $padding-btn-horizontal: 40px !default;
$padding-btn-vertical: 2px !default;
$padding-btn-horizontal: 5px !default;

$padding-round-horizontal: 23px !default;

$padding-lg-vertical: 15px !default;

$padding-input-vertical: 11px !default;
$padding-input-horizontal: 19px !default;

$margin-lg-vertical: 30px !default;
$margin-base-vertical: 15px !default;

// This variable affects the `.h-*` and `.w-*` classes.

$sizes: () !default;
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
  ),
  $sizes
);

// Components

$shape-height-xl: 1.5 !default;
$shape-height-lg: 1.5 !default;
$shape-height-sm: 1.5 !default;

// border
$none: 0 !default;
$border-thin: 1px !default;
$border-thick: 2px !default;
$border-width: 0.0625rem !default;
$border-color: $gray-200 !default;

$border-radius: 0.25rem !default;
$border-radius-xl: 0.875rem !default;
$border-radius-lg: 0.4285rem !default;
$border-radius-sm: 0.2857rem !default;
$border-radius-xs: 0.1428rem !default;

$margin-bottom: 10px !default;
$border: 1px solid !default;

$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075);
$box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175);

$dropdown-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.125);
$box-shadow-raised: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
$box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.1);

$component-active-color: $white !default;
$component-active-bg: theme-color("primary") !default;
$component-active-border-color: theme-color("primary") !default;

$component-hover-color: $gray-300 !default;
$component-hover-bg: $gray-300 !default;
$component-hover-border-color: $gray-300 !default;

$caret-width: 0.3em !default;

$transition-base: all 0.15s ease !default;
$transition-fade: opacity 0.15s linear !default;
$transition-collapse: height 0.35s ease !default;

$fast-transition-time: 150ms !default;

$transition-linear: linear !default;
$transition-bezier: cubic-bezier(0.34, 1.61, 0.7, 1) !default;
$transition-ease: ease 0s;

$select-coordinates: 50% -40px !default;
$general-transition-time: 300ms !default;

// Fonts

$font-family-base: "Roboto", "Helvetica Neue", sans-serif;
$font-family-alt: "Roboto", "Helvetica Neue", sans-serif;

$font-size-dash: 0.875rem !default;

$font-size-base: $font-size-dash !default; // Assumes the browser default, typically `16px`
$font-size-xl: ($font-size-base * 1.428);
$font-size-lg: ($font-size-base * 1.142);
$font-size-sm: ($font-size-base * 0.85714286);
$font-size-xs: ($font-size-base * 0.714);

$h1-font-size: $font-size-base * 1.75714285 !default; //2.1875rem
$h2-font-size: $font-size-base * 1.5285714 !default;
$h3-font-size: $font-size-base * 1.242857 !default;
$h4-font-size: $font-size-base * 0.8142857 !default;
$h5-font-size: $font-size-base * 0.7285714 !default;
$h6-font-size: $font-size-base * 0.6571428 !default;

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 600 !default;
$font-weight-extra-bold: 700 !default;

$font-paragraph: 1rem !default;

$font-weight-base: $font-weight-normal !default;
$shape-height-base: 1.428571 !default;

$headings-margin-bottom: ($spacer * 0.5);
$headings-font-family: inherit !default;
$headings-font-weight: $font-weight-normal !default;
$headings-line-height: 1.2 !default;
$headings-color: $gray-800 !default;

$heading-letter-spacing: 0.025em !default;
$heading-font-size: 0.95rem !default;
$heading-text-transform: uppercase !default;
$heading-font-weight: $headings-font-weight !default;

$heading-title-letter-spacing: 0.025em !default;
$heading-title-font-size: 1.375rem !default;
$heading-title-font-weight: $font-weight-bold !default;
$heading-title-text-transform: uppercase !default;

$heading-section-letter-spacing: 0.025em !default;
$heading-section-font-size: 1.375rem !default;
$heading-section-font-weight: $font-weight-bold !default;
$heading-section-text-transform: uppercase !default;

$display1-size: 3.3rem !default;
$display2-size: 2.75rem !default;
$display3-size: 2.1875rem !default;
$display4-size: 1.6275rem !default;

$display1-weight: $font-weight-bold !default;
$display2-weight: $font-weight-bold !default;
$display3-weight: $font-weight-bold !default;
$display4-weight: $font-weight-bold !default;
$display-line-height: $headings-line-height !default;

$paragraph-font-size: 0.625rem !default;
$paragraph-font-weight: 300 !default;
$paragraph-line-height: 1.7 !default;

$lead-font-size: ($paragraph-font-size * 1.25);
$lead-font-weight: 300 !default;

$small-font-size: 80% !default;

$text-muted: $gray-600 !default;

$blockquote-small-color: $gray-600 !default;
$blockquote-font-size: ($font-size-base * 1.1);

$hr-border-color: rgba($black, 0.1);
$hr-border-width: $border-width !default;

$mark-padding: 0.2em !default;

$dt-font-weight: $font-weight-bold !default;

$list-inline-padding: 0.5rem !default;

$mark-bg: #fcf8e3 !default;

$hr-margin-y: $spacer * 2 !default;

// Icons

$icon-size: 1rem !default;
$icon-size-regular: 2.375rem !default;
$icon-font-size-regular: 0.9375rem !default;
$icon-font-size-sm: 0.6875rem !default;
$icon-size-sm: 1.875rem !default;
$icon-font-size-lg: 1.325rem !default;
$icon-size-lg: 3.6rem !default;
$icon-size-xl: 5rem !default;

// Tables

$table-cell-padding: 1rem !default;
$table-cell-padding-sm: 0.3rem !default;

$table-bg: transparent !default;
$table-accent-bg: rgba($black, 0.05);
$table-hover-bg: rgba($black, 0.075);
$table-active-bg: $table-hover-bg !default;

$table-border-width: $border-width !default;
$table-border-color: $gray-300 !default;

$table-head-bg: $gray-200 !default;
$table-head-color: $gray-700 !default;

$table-dark-bg: $gray-900 !default;
$table-dark-accent-bg: rgba($white, 0.05);
$table-dark-hover-bg: rgba($white, 0.075);
$table-dark-border-color: lighten($gray-900, 7.5%);
$table-dark-color: $body-bg !default;

// Buttons + Forms

$input-btn-padding-y: 0.5rem !default;
$input-btn-padding-x: 0.7rem !default;
$input-btn-line-height: $shape-height-base !default;

$input-btn-focus-width: 0 !default;
$input-btn-focus-color: rgba($component-active-bg, 1);
//$input-btn-focus-box-shadow:  0 4px 10px 0 rgba(0, 0, 0, 0.04);
$input-btn-focus-box-shadow: none !default;

$input-btn-padding-y-sm: 0.25rem !default;
$input-btn-padding-x-sm: 0.5rem !default;
$input-btn-line-height-sm: 1.35 !default;

$input-btn-padding-y-lg: 0.875rem !default;
$input-btn-padding-x-lg: 1rem !default;
$input-btn-line-height-lg: 1.35 !default;

$input-btn-border-width: 1px !default;

$input-btn-font-size-sm: 0.75rem !default;
$input-btn-font-size: 0.875rem !default;
$input-btn-font-size-lg: 0.875rem !default;

$input-padding-vertical: 11px !default;
$input-padding-horizontal: 19px !default;

// Buttons

// $btn-padding-y: 11px !default;
// $btn-padding-x: 40px !default;
$btn-padding-y: 7px !default;
$btn-padding-x: 15px !default;
$btn-line-height: $line-height !default;

$btn-padding-y-sm: 5px !default;
$btn-padding-x-sm: 15px !default;
$btn-line-height-sm: $input-btn-line-height-sm !default;

$btn-padding-y-lg: 15px !default;
$btn-padding-x-lg: 48px !default;
$btn-line-height-lg: $input-btn-line-height-lg !default;

$btn-border-width: $input-btn-border-width !default;

$btn-font-size-lg: 1em !default;
$btn-font-weight: 600 !default;
$btn-text-transform: uppercase !default;
$btn-letter-spacing: 0.025em !default;
$btn-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
$btn-hover-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1),
  0 3px 6px rgba(0, 0, 0, 0.08);
$btn-focus-box-shadow: $btn-hover-box-shadow !default;
$btn-focus-width: $input-btn-focus-width !default;

$btn-active-box-shadow: none !default;
$btn-round-radius: 30px !default;

// Forms

$input-padding-y: $input-btn-padding-y !default;
$input-padding-x: $input-btn-padding-x !default;
$input-line-height: $input-btn-line-height !default;

$input-padding-y-sm: $input-btn-padding-y-sm !default;
$input-padding-x-sm: $input-btn-padding-x-sm !default;
$input-line-height-sm: $input-btn-line-height-sm !default;

$input-padding-y-lg: $input-btn-padding-y-lg !default;
$input-padding-x-lg: $input-btn-padding-x-lg !default;
$input-line-height-lg: $input-btn-line-height-lg !default;

$input-border-radius: $border-radius !default;
$input-border-radius-xl: $border-radius-xl !default;
$input-border-radius-lg: $border-radius-lg !default;
$input-border-radius-sm: $border-radius-sm !default;

$input-bg: $transparent-bg !default;
$input-disabled-bg: $gray-200 !default;

$input-muted-bg: #edf0f5 !default;

$input-alternative-box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15),
  0 1px 0 rgba(0, 0, 0, 0.02);
$input-focus-alternative-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
  0 1px 3px rgba(0, 0, 0, 0.08);

$input-color: $opacity-8 !default;
$input-border-color: #cad1d7 !default;
$input-border-width: $input-btn-border-width !default;
$input-box-shadow: none !default; // 0 1px 3px 0 $gray-400 !default;

$input-focus-bg: $white !default;
$input-focus-border-color: rgba(50, 151, 211, 0.25);
$input-focus-color: $input-color !default;
$input-focus-width: 0 !default;
$input-focus-box-shadow: none !default; //0 1px 3px 0 $gray-500 !default;

$input-placeholder-color: $gray-500 !default;
$input-focus-placeholder-color: $gray-500 !default;

$input-height-border: $input-border-width * 2 !default;
$input-height-inner: ($font-size-base * $input-btn-line-height) +
  ($input-btn-padding-y * 2) !default;
$input-height: calc(#{$input-height-inner} + #{$input-height-border}) !default;

$input-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);

// Input groups

$input-group-addon-color: $input-placeholder-color !default;
$input-group-addon-bg: $input-bg !default;
$input-group-addon-border-color: $input-border-color !default;

$input-group-addon-focus-color: $input-focus-color !default;
$input-group-addon-focus-bg: $input-focus-bg !default;
$input-group-addon-focus-border-color: $input-focus-border-color !default;

// Custom forms

$custom-control-gutter: 1.75rem !default;
$custom-control-spacer-x: 1rem !default;
$custom-control-indicator-size: 1.25rem !default;

$custom-control-indicator-bg: $input-bg !default;
$custom-control-indicator-border-width: 1px !default;
$custom-control-indicator-border-color: $input-border-color !default;
$custom-control-indicator-box-shadow: none !default;

$custom-control-indicator-focus-box-shadow: $custom-control-indicator-box-shadow !default;

$custom-control-indicator-hover-color: $component-hover-color !default;
$custom-control-indicator-hover-bg: $component-hover-bg !default;
$custom-control-indicator-hover-border-color: $component-hover-border-color !default;

$custom-control-indicator-active-color: $component-active-color !default;
$custom-control-indicator-active-bg: $component-active-bg !default;
$custom-control-indicator-active-border-color: $component-active-border-color !default;
$custom-control-indicator-active-box-shadow: $custom-control-indicator-box-shadow !default;

$custom-control-indicator-checked-color: $component-active-color !default;
$custom-control-indicator-checked-bg: $component-active-bg !default;
$custom-control-indicator-checked-border-color: $component-active-border-color !default;
$custom-control-indicator-checked-box-shadow: $custom-control-indicator-box-shadow !default;
$custom-control-indicator-checked-disabled-bg: rgba(
  theme-color("primary"),
  0.5
);

$custom-control-indicator-disabled-bg: $gray-200 !default;
$custom-control-label-disabled-color: $gray-600 !default;

$custom-checkbox-indicator-border-radius: $border-radius-sm !default;
//$custom-checkbox-indicator-icon-checked:        str-replace(url("data:image/svg+xml !default;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23") !default;

$custom-toggle-slider-bg: #ddd !default;
$custom-toggle-checked-bg: theme-color("primary") !default;

// Form validation

$form-feedback-valid-bg: lighten($success, 15%);
$form-feedback-valid-color: theme-color("success") !default;
$form-feedback-invalid-bg: lighten($warning, 15%);
$form-feedback-invalid-color: theme-color("warning") !default;

// Allows for customizing button radius independently from global border radius

$btn-border-radius: $input-border-radius !default;
$btn-border-radius-xl: $input-border-radius-xl !default;
$btn-border-radius-lg: $input-border-radius-lg !default;
$btn-border-radius-sm: $input-border-radius !default;

// No UI Slider

$noui-target-bg: #eceeef !default;
$noui-target-thickness: 5px !default;
$noui-target-border-radius: 5px !default;
$noui-target-border-color: 0 !default;
$noui-target-box-shadow: inset 0 1px 2px rgba(90, 97, 105, 0.1);

$noui-slider-connect-bg: $primary !default;
$noui-slider-connect-disabled-bg: #b2b2b2 !default;

$noui-handle-width: 15px !default;
$noui-handle-bg: theme-color("primary") !default;
$noui-handle-border: 0 !default;
$noui-handle-border-radius: 100% !default;

$noui-origin-border-radius: 2px !default;

// Dropdown

$dropdown-bg: $white !default;
$dropdown-border-width: 0 !default;
$dropdown-border-color: rgba($black, 0.15);
$dropdown-border-radius: $border-radius-xs !default;
$dropdown-box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1),
  0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);

$dropdown-link-active-color: $component-active-color !default;
$dropdown-link-active-bg: $component-active-bg !default;

// Navs

$nav-link-padding-y: 0.5rem !default;
$nav-link-padding-x: 1rem !default;
$nav-link-color: $gray-700 !default;
$nav-link-disabled-color: $gray-600 !default;

$nav-pills-padding-y: 0.75rem !default;
$nav-pills-padding-x: 1rem !default;

$nav-pills-space-x: 1rem !default;

$nav-pills-bg: $white;
$nav-pills-border-width: 1px !default;
$nav-pills-border-color: theme-color("primary") !default;
$nav-pills-border-radius: $border-radius !default;

$nav-pills-link-color: theme-color("primary") !default;
$nav-pills-link-hover-color: darken(theme-color("primary"), 5%);
$nav-pills-link-active-color: color-yiq(theme-color("primary"));
$nav-pills-link-active-bg: theme-color("primary") !default;
$nav-pills-box-shadow: $btn-box-shadow !default;

// Navbar

$navbar-transition: all 0.15s linear !default;

// $navbar-padding-y: 0.625rem !default; -- Original height padding on navbar
// $navbar-padding-x: 0.9375rem !default; -- Original width padding on navbar

$navbar-padding-y: 0.05rem !default;
$navbar-padding-x: 0.05rem !default;

$navbar-nav-link-padding-x: 1rem !default;
$navbar-nav-link-padding-y: 1rem !default;

$navbar-nav-link-font-family: $font-family-alt !default;
$navbar-nav-link-font-size: 0.9rem !default;
$navbar-nav-link-font-weight: 400 !default;
$navbar-nav-link-text-transform: normal !default;
$navbar-nav-link-letter-spacing: 0 !default;
$navbar-nav-link-border-radius: $border-radius !default;

/* navbar color */
$navbar-color: #1a1e34 !default;
$navbar-dark-bg: transparent !default;
$navbar-dark-hover-bg: rgba(255, 255, 255, 0.1);
$navbar-dark-active-bg: rgba(255, 255, 255, 0.1);
$navbar-dark-color: rgba($white, 0.95);
$navbar-dark-hover-color: rgba($white, 0.65);
$navbar-dark-active-color: rgba($white, 0.65);
$navbar-dark-disabled-color: rgba($white, 0.25);
$navbar-dark-toggler-border-color: transparent !default;

$navbar-light-bg: transparent !default;
$navbar-light-hover-bg: rgba(0, 0, 0, 0.1);
$navbar-light-active-bg: rgba(0, 0, 0, 0.1);
$navbar-light-color: rgba($black, 0.5);
$navbar-light-hover-color: #212427;
$navbar-light-active-color: #212427;
$navbar-light-disabled-color: rgba($black, 0.3);
$navbar-light-toggler-icon-bg: str-replace(url("data:image/svg+xml !default;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-light-toggler-border-color: transparent !default;
$full-width: 100% !default;
$navbar-padding: 10px 15px !default;

// Alerts

$alert-padding-y: 0.9rem !default;
$alert-padding-x: 1.25rem !default;
$alert-border-radius: $border-radius-sm !default;

$alert-bg-level: -2 !default;
$alert-border-level: -2 !default;
$alert-color-level: 0 !default;

// List group

$list-group-bg: $white !default;
$list-group-border-color: $border-color !default; //rgba($black, .125);
$list-group-border-width: $border-width !default;
$list-group-border-radius: $border-radius !default;

$list-group-item-padding-y: 1rem !default;
$list-group-item-padding-x: 1rem !default;

$list-group-hover-bg: $gray-100 !default;
$list-group-active-color: $component-active-color !default;
$list-group-active-bg: $component-active-bg !default;
$list-group-active-border-color: $list-group-active-bg !default;

$list-group-disabled-color: $gray-600 !default;
$list-group-disabled-bg: $list-group-bg !default;

$list-group-action-color: $gray-700 !default;
$list-group-action-hover-color: $list-group-action-color !default;

$list-group-action-active-color: $list-group-action-color !default;
$list-group-action-active-bg: $gray-200 !default;

// Close

$close-font-size: $font-size-base * 1.5 !default;
$close-font-weight: $font-weight-bold !default;
$close-bg: transparent !default;
$close-hover-bg: transparent !default;
$close-color: rgba(0, 0, 0, 0.6);
$close-hover-color: rgba(0, 0, 0, 0.9);
$close-text-shadow: none !default;

// Popovers

$popover-font-size: $font-size-sm !default;
$popover-bg: $white !default;
$popover-max-width: 276px !default;
$popover-border-width: 1px !default;
$popover-border-color: rgba($black, 0.05);
$popover-border-radius: $border-radius-lg !default;
$popover-box-shadow: 0px 0.5rem 2rem 0px rgba($black, 0.2);

$popover-header-bg: $popover-bg !default;
$popover-header-color: $headings-color !default;
$popover-header-padding-y: 0.75rem !default;
$popover-header-padding-x: 0.75rem !default;

$popover-body-color: $body-color !default;
$popover-body-padding-y: $popover-header-padding-y !default;
$popover-body-padding-x: $popover-header-padding-x !default;

$popover-arrow-width: 1.5rem !default;
$popover-arrow-height: 0.75rem !default;
$popover-arrow-color: $popover-bg !default;

$popover-arrow-outer-color: transparent !default;

// Breadcrumbs

$breadcrumb-bg: $black-states !default;
$breadcrumb-divider-color: $white !default;
$breadcrumb-active-color: $white !default;

// Badges

$badge-font-size: $font-size-xs !default;
$badge-font-weight: $font-weight-extra-bold !default;
$badge-padding-y: 0.25rem !default;
$badge-padding-x: 0.5rem !default;
$badge-border-radius: $border-radius !default;
$badge-text-transfom: uppercase;

$badge-pill-padding-x: 0.875em !default;
$badge-pill-border-radius: $border-radius-xl !default;

$badge-circle-size: 2rem;

// Pagination

$pagination-padding-y: 0 !default;
$pagination-padding-x: 0.6875rem !default;

$pagination-color: $white !default;
$pagination-bg: transparent !default;
$pagination-border-width: $border-width !default;
$pagination-border-color: $gray-300 !default;

//$pagination-focus-box-shadow:       $btn-hover-box-shadow !default;

$pagination-hover-color: $gray-600 !default;
$pagination-hover-bg: $gray-300 !default;
$pagination-hover-border-color: $gray-300 !default;

$pagination-active-color: $component-active-color !default;
$pagination-active-bg: $component-active-bg !default;
$pagination-active-border-color: $pagination-active-bg !default;
$pagination-active-box-shadow: $btn-hover-box-shadow !default;

$pagination-disabled-color: $gray-600 !default;
$pagination-disabled-bg: transparent !default;
$pagination-disabled-border-color: $gray-300 !default;

// Cards

$card-spacer-y: 1.25rem !default;
$card-spacer-x: 1.5rem !default;
$card-border-width: $border-width !default;
$card-border-radius: $border-radius-sm !default;
$card-border-color: rgba($black, 0.05);
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width});
$card-cap-bg: $gray-100 !default;
$card-bg: $white !default;
$card-black-background: #27293d !default;
$card-img-overlay-padding: 1.25rem !default;

//$card-group-margin:                 ($grid-gutter-width / 2);
//$card-deck-margin:                  $card-group-margin !default;

$card-columns-count: 3 !default;
$card-columns-gap: 1.25rem !default;
$card-columns-margin: $card-spacer-y !default;

// Tooltips

$tooltip-font-size: $font-size-sm !default;
$tooltip-max-width: 200px !default;
$tooltip-color: $black !default;
$tooltip-bg: $white !default;
$tooltip-border-radius: $border-radius !default;
$tooltip-opacity: 0.9 !default;
$tooltip-padding-y: 0.25rem !default;
$tooltip-padding-x: 0.5rem !default;
$tooltip-margin: 0 !default;

$tooltip-arrow-width: 0.8rem !default;
$tooltip-arrow-height: 0.4rem !default;
$tooltip-arrow-color: $tooltip-bg !default;

// Modals

$modal-inner-padding: 24px 24px 16px 24px !default;

$modal-lg: 800px !default;
$modal-md: 500px !default;
$modal-sm: 380px !default;
$modal-title-line-height: 1.1;
$modal-content-bg: $white !default;
$modal-content-border-color: rgba($black, 0.2);
$modal-content-border-width: 1px !default;
$modal-content-border-radius: $border-radius-sm !default;
$modal-content-box-shadow-xs: 0px 10px 50px 0px rgba(0, 0, 0, 0.5);
$modal-content-box-shadow-sm-up: 0 15px 35px rgba(50, 50, 93, 0.2),
  0 5px 15px rgba(0, 0, 0, 0.17);

$modal-backdrop-bg: $black !default;
$modal-backdrop-opacity: 0.16 !default;
$modal-header-border-color: $gray-200 !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-header-border-width: $modal-content-border-width !default;
$modal-footer-border-width: $modal-header-border-width !default;
$modal-header-padding: 24px 24px 0 24px !default;

// Progress bars

$progress-height: 0.5rem !default;
$progress-bg: rgba(0, 0, 0, 0.3) !default;
$progress-border-radius: $border-radius-xl !default;
$progress-box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.3) !default;
$progress-bar-color: $white !default;

// Datepicker

$datepicker-border-radius: $card-border-radius !default;
$datepicker-dropdown-padding: 20px 22px !default;

$datepicker-cell-transition: $transition-base !default;
$datepicker-cell-hover-background: lighten($gray-400, 55%);
$datepicker-cell-border-radius: 50% !default;
$datepicker-cell-width: 36px !default;
$datepicker-cell-height: 36px !default;

$datepicker-disabled-cell-color: $gray-300 !default;
$datepicker-disabled-old-new-color: $gray-500 !default;

$datepicker-header-cell-border-radius: $border-radius !default;

$datepicker-active-color: $white !default;
$datepicker-active-background: theme-color("primary") !default;
$datepicker-active-box-shadow: none !default;

$datepicker-range-background: theme-color("primary") !default;
$datepicker-range-cell-focused-background: darken(
  $datepicker-range-background,
  5%
);
$datepicker-range-color: $white !default;
$datepicker-range-highlighted-bg: $gray-200 !default;

$datepicker-dropdown-border: lighten($gray-400, 40%);
$datepicker-dropdown-bg: $white !default;
$datepicker-highlighted-bg: $datepicker-active-background !default;

// Sidebar

$sidebar-width: 260px;
$sidebar-transition: 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
$sidebar-box-shadow: 0px 2px 22px 0 rgba(0, 0, 0, 0.2),
  0px 2px 30px 0 rgba(0, 0, 0, 0.35);

// Footer

$footer-link-font-size: 0.85rem !default;
$footer-bg: theme-color("secondary") !default;
$footer-color: $gray-600 !default;
$footer-link-color: $gray-600 !default;
$footer-link-hover-color: $gray-700 !default;
$footer-heading-color: $gray-600 !default;
$footer-heading-font-size: $font-size-sm !default;

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800
  ),
  $colors
);

$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark
  ),
  $theme-colors
);

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-900 !default;
$yiq-text-light: $white !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: true !default;
$enable-gradients: true !default;
$enable-transitions: true !default;
$enable-prefers-reduced-motion-media-query: true !default;
$enable-hover-media-query: false !default; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes: true !default;
$enable-pointer-cursor-for-buttons: true !default;
$enable-print-styles: true !default;
$enable-responsive-font-sizes: false !default;
$enable-validation-icons: true !default;
$enable-deprecation-messages: true !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25
    ),
    2: (
      $spacer * 0.5
    ),
    3: $spacer,
    4: (
      $spacer * 1.5
    ),
    5: (
      $spacer * 3
    )
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto
  ),
  $sizes
);

// Body
//
// Settings for the `<body>` element.

$body-bg: $white !default;
$body-color: $gray-900 !default;

// Links
//
// Style anchor elements.

$link-color: theme-color("primary") !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15% !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1rem !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg: 1.5 !default;
$line-height-sm: 1.5 !default;

$border-width: 1px !default;
$border-color: $gray-300 !default;

$border-radius: 0.25rem !default;
$border-radius-lg: 0.3rem !default;
$border-radius-sm: 0.2rem !default;

$rounded-pill: 50rem !default;

$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow: 0 0.5rem 1rem rgba($black, 0.15) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175) !default;

$component-active-color: $white !default;
$component-active-bg: theme-color("primary") !default;

$caret-width: 0.3em !default;
$caret-vertical-align: $caret-width * 0.85 !default;
$caret-spacing: $caret-width * 0.85 !default;

$transition-base: all 0.2s ease-in-out !default;
$transition-fade: opacity 0.15s linear !default;
$transition-collapse: height 0.35s ease !default;

$embed-responsive-aspect-ratios: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$embed-responsive-aspect-ratios: join(
  ((21 9), (16 9), (4 3), (1 1)),
  $embed-responsive-aspect-ratios
);

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;
// stylelint-enable value-keyword-case

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 1.25 !default;
$font-size-sm: $font-size-base * 0.875 !default;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

$headings-margin-bottom: $spacer * 0.5 !default;
$headings-margin-top: $spacer * 0.5 !default;
$headings-font-family: null !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.2 !default;
$headings-color: null !default;

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.5rem !default;

$display1-weight: 300 !default;
$display2-weight: 300 !default;
$display3-weight: 300 !default;
$display4-weight: 300 !default;
$display-line-height: $headings-line-height !default;

$lead-font-size: $font-size-base * 1.25 !default;
$lead-font-weight: 300 !default;

$small-font-size: 80% !default;

$text-muted: $gray-600 !default;

$blockquote-small-color: $gray-600 !default;
$blockquote-small-font-size: $small-font-size !default;
$blockquote-font-size: $font-size-base * 1.25 !default;

$hr-border-color: rgba($black, 0.1) !default;
$hr-border-width: $border-width !default;

$mark-padding: 0.2em !default;

$dt-font-weight: $font-weight-bold !default;

$kbd-box-shadow: inset 0 -0.1rem 0 rgba($black, 0.25) !default;
$nested-kbd-font-weight: $font-weight-bold !default;

$list-inline-padding: 0.5rem !default;

$mark-bg: #fcf8e3 !default;

$hr-margin-y: $spacer !default;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.3rem !default;

$table-color: $body-color !default;
$table-bg: null !default;
$table-accent-bg: rgba($black, 0.05) !default;
$table-hover-color: $table-color !default;
$table-hover-bg: rgba($black, 0.075) !default;
$table-active-bg: $table-hover-bg !default;

$table-border-width: $border-width !default;
$table-border-color: $border-color !default;

$table-head-bg: $gray-200 !default;
$table-head-color: $gray-700 !default;

$table-dark-color: $white !default;
$table-dark-bg: $gray-800 !default;
$table-dark-accent-bg: rgba($white, 0.05) !default;
$table-dark-hover-color: $table-dark-color !default;
$table-dark-hover-bg: rgba($white, 0.075) !default;
$table-dark-border-color: lighten($table-dark-bg, 7.5%) !default;
$table-dark-color: $white !default;

$table-striped-order: odd !default;

$table-caption-color: $text-muted !default;

$table-bg-level: -9 !default;
$table-border-level: -6 !default;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.375rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-font-family: null !default;
$input-btn-font-size: $font-size-base !default;
$input-btn-line-height: $line-height-base !default;

$input-btn-focus-width: 0.2rem !default;
$input-btn-focus-color: rgba($component-active-bg, 0.25) !default;
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm: 0.25rem !default;
$input-btn-padding-x-sm: 0.5rem !default;
$input-btn-font-size-sm: $font-size-sm !default;
$input-btn-line-height-sm: $line-height-sm !default;

$input-btn-padding-y-lg: 0.5rem !default;
$input-btn-padding-x-lg: 1rem !default;
$input-btn-font-size-lg: $font-size-lg !default;
$input-btn-line-height-lg: $line-height-lg !default;

$input-btn-border-width: $border-width !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: $input-btn-padding-y !default;
$btn-padding-x: $input-btn-padding-x !default;
$btn-font-family: $input-btn-font-family !default;
$btn-font-size: $input-btn-font-size !default;
$btn-line-height: $input-btn-line-height !default;

$btn-padding-y-sm: $input-btn-padding-y-sm !default;
$btn-padding-x-sm: $input-btn-padding-x-sm !default;
$btn-font-size-sm: $input-btn-font-size-sm !default;
$btn-line-height-sm: $input-btn-line-height-sm !default;

$btn-padding-y-lg: $input-btn-padding-y-lg !default;
$btn-padding-x-lg: $input-btn-padding-x-lg !default;
$btn-font-size-lg: $input-btn-font-size-lg !default;
$btn-line-height-lg: $input-btn-line-height-lg !default;

$btn-border-width: $input-btn-border-width !default;

$btn-font-weight: $font-weight-normal !default;
$btn-box-shadow: inset 0 1px 0 rgba($white, 0.15), 0 1px 1px rgba($black, 0.075) !default;
$btn-focus-width: $input-btn-focus-width !default;
$btn-focus-box-shadow: $input-btn-focus-box-shadow !default;
$btn-disabled-opacity: 0.65 !default;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125) !default;

$btn-link-disabled-color: $gray-600 !default;

$btn-block-spacing-y: 0.5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius !default;
$btn-border-radius-lg: $border-radius-lg !default;
$btn-border-radius-sm: $border-radius-sm !default;

$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

// Forms

$label-margin-bottom: 0.5rem !default;

$input-padding-y: $input-btn-padding-y !default;
$input-padding-x: $input-btn-padding-x !default;
$input-font-family: $input-btn-font-family !default;
$input-font-size: $input-btn-font-size !default;
$input-font-weight: $font-weight-base !default;
$input-line-height: $input-btn-line-height !default;

$input-padding-y-sm: $input-btn-padding-y-sm !default;
$input-padding-x-sm: $input-btn-padding-x-sm !default;
$input-font-size-sm: $input-btn-font-size-sm !default;
$input-line-height-sm: $input-btn-line-height-sm !default;

$input-padding-y-lg: $input-btn-padding-y-lg !default;
$input-padding-x-lg: $input-btn-padding-x-lg !default;
$input-font-size-lg: $input-btn-font-size-lg !default;
$input-line-height-lg: $input-btn-line-height-lg !default;

$input-bg: $white !default;
$input-disabled-bg: $gray-200 !default;

$input-color: $gray-700 !default;
$input-border-color: $gray-400 !default;
$input-border-width: $input-btn-border-width !default;
$input-box-shadow: inset 0 1px 1px rgba($black, 0.075) !default;

$input-border-radius: $border-radius !default;
$input-border-radius-lg: $border-radius-lg !default;
$input-border-radius-sm: $border-radius-sm !default;

$input-focus-bg: $input-bg !default;
$input-focus-border-color: lighten($component-active-bg, 25%) !default;
$input-focus-color: $input-color !default;
$input-focus-width: $input-btn-focus-width !default;
$input-focus-box-shadow: $input-btn-focus-box-shadow !default;

$input-placeholder-color: $gray-600 !default;
$input-plaintext-color: $body-color !default;

$input-height-border: $input-border-width * 2 !default;

$input-height-inner: calc(
  #{$input-line-height * 1em} + #{$input-padding-y * 2}
) !default;
$input-height-inner-half: calc(
  #{$input-line-height * 0.5em} + #{$input-padding-y}
) !default;
$input-height-inner-quarter: calc(
  #{$input-line-height * 0.25em} + #{$input-padding-y * 0.5}
) !default;

$input-height: calc(
  #{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border}
) !default;
$input-height-sm: calc(
  #{$input-line-height-sm * 1em} + #{$input-btn-padding-y-sm * 2} + #{$input-height-border}
) !default;
$input-height-lg: calc(
  #{$input-line-height-lg * 1em} + #{$input-btn-padding-y-lg * 2} + #{$input-height-border}
) !default;

$input-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

$form-text-margin-top: 0.25rem !default;

$form-check-input-gutter: 1.25rem !default;
$form-check-input-margin-y: 0.3rem !default;
$form-check-input-margin-x: 0.25rem !default;

$form-check-inline-margin-x: 0.75rem !default;
$form-check-inline-input-margin-x: 0.3125rem !default;

$form-grid-gutter-width: 10px !default;
$form-group-margin-bottom: 1rem !default;

$input-group-addon-color: $input-color !default;
$input-group-addon-bg: $gray-200 !default;
$input-group-addon-border-color: $input-border-color !default;

$custom-forms-transition: background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

$custom-control-gutter: 0.5rem !default;
$custom-control-spacer-x: 1rem !default;

$custom-control-indicator-size: 1rem !default;
$custom-control-indicator-bg: $input-bg !default;

$custom-control-indicator-bg-size: 50% 50% !default;
$custom-control-indicator-box-shadow: $input-box-shadow !default;
$custom-control-indicator-border-color: $gray-500 !default;
$custom-control-indicator-border-width: $input-border-width !default;

$custom-control-indicator-disabled-bg: $input-disabled-bg !default;
$custom-control-label-disabled-color: $gray-600 !default;

$custom-control-indicator-checked-color: $component-active-color !default;
$custom-control-indicator-checked-bg: $component-active-bg !default;
$custom-control-indicator-checked-disabled-bg: rgba(
  theme-color("primary"),
  0.5
) !default;
$custom-control-indicator-checked-box-shadow: none !default;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg !default;

$custom-control-indicator-focus-box-shadow: $input-focus-box-shadow !default;
$custom-control-indicator-focus-border-color: $input-focus-border-color !default;

$custom-control-indicator-active-color: $component-active-color !default;
$custom-control-indicator-active-bg: lighten(
  $component-active-bg,
  35%
) !default;
$custom-control-indicator-active-box-shadow: none !default;
$custom-control-indicator-active-border-color: $custom-control-indicator-active-bg !default;

$custom-checkbox-indicator-border-radius: $border-radius !default;
$custom-checkbox-indicator-icon-checked: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"),
  "#",
  "%23"
) !default;

$custom-checkbox-indicator-indeterminate-bg: $component-active-bg !default;
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color !default;
$custom-checkbox-indicator-icon-indeterminate: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/%3e%3c/svg%3e"),
  "#",
  "%23"
) !default;
$custom-checkbox-indicator-indeterminate-box-shadow: none !default;
$custom-checkbox-indicator-indeterminate-border-color: $custom-checkbox-indicator-indeterminate-bg !default;

$custom-radio-indicator-border-radius: 50% !default;
$custom-radio-indicator-icon-checked: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='#{$custom-control-indicator-checked-color}'/%3e%3c/svg%3e"),
  "#",
  "%23"
) !default;

$custom-switch-width: $custom-control-indicator-size * 1.75 !default;
$custom-switch-indicator-border-radius: $custom-control-indicator-size * 0.5 !default;
$custom-switch-indicator-size: calc(
  #{$custom-control-indicator-size} - #{$custom-control-indicator-border-width *
    4}
) !default;

$custom-select-padding-y: $input-padding-y !default;
$custom-select-padding-x: $input-padding-x !default;
$custom-select-font-family: $input-font-family !default;
$custom-select-font-size: $input-font-size !default;
$custom-select-height: $input-height !default;
$custom-select-indicator-padding: 1rem !default; // Extra padding to account for the presence of the background-image based indicator
$custom-select-font-weight: $input-font-weight !default;
$custom-select-line-height: $input-line-height !default;
$custom-select-color: $input-color !default;
$custom-select-disabled-color: $gray-600 !default;
$custom-select-bg: $input-bg !default;
$custom-select-disabled-bg: $gray-200 !default;
$custom-select-bg-size: 8px 10px !default; // In pixels because image dimensions
$custom-select-indicator-color: $gray-800 !default;
$custom-select-indicator: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e"),
  "#",
  "%23"
) !default;
$custom-select-background: $custom-select-indicator no-repeat right
  $custom-select-padding-x center / $custom-select-bg-size !default; // Used so we can have multiple background elements (e.g., arrow and feedback icon)

$custom-select-feedback-icon-padding-right: calc(
  (1em + #{2 * $custom-select-padding-y}) * 3 / 4 + #{$custom-select-padding-x +
    $custom-select-indicator-padding}
) !default;
$custom-select-feedback-icon-position: center right
  ($custom-select-padding-x + $custom-select-indicator-padding) !default;
$custom-select-feedback-icon-size: $input-height-inner-half
  $input-height-inner-half !default;

$custom-select-border-width: $input-border-width !default;
$custom-select-border-color: $input-border-color !default;
$custom-select-border-radius: $border-radius !default;
$custom-select-box-shadow: inset 0 1px 2px rgba($black, 0.075) !default;

$custom-select-focus-border-color: $input-focus-border-color !default;
$custom-select-focus-width: $input-focus-width !default;
$custom-select-focus-box-shadow: 0 0 0 $custom-select-focus-width
  $input-btn-focus-color !default;

$custom-select-padding-y-sm: $input-padding-y-sm !default;
$custom-select-padding-x-sm: $input-padding-x-sm !default;
$custom-select-font-size-sm: $input-font-size-sm !default;
$custom-select-height-sm: $input-height-sm !default;

$custom-select-padding-y-lg: $input-padding-y-lg !default;
$custom-select-padding-x-lg: $input-padding-x-lg !default;
$custom-select-font-size-lg: $input-font-size-lg !default;
$custom-select-height-lg: $input-height-lg !default;

$custom-range-track-width: 100% !default;
$custom-range-track-height: 0.5rem !default;
$custom-range-track-cursor: pointer !default;
$custom-range-track-bg: $gray-300 !default;
$custom-range-track-border-radius: 1rem !default;
$custom-range-track-box-shadow: inset 0 0.25rem 0.25rem rgba($black, 0.1) !default;

$custom-range-thumb-width: 1rem !default;
$custom-range-thumb-height: $custom-range-thumb-width !default;
$custom-range-thumb-bg: $component-active-bg !default;
$custom-range-thumb-border: 0 !default;
$custom-range-thumb-border-radius: 1rem !default;
$custom-range-thumb-box-shadow: 0 0.1rem 0.25rem rgba($black, 0.1) !default;
$custom-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg,
  $input-focus-box-shadow !default;
$custom-range-thumb-focus-box-shadow-width: $input-focus-width !default; // For focus box shadow issue in IE/Edge
$custom-range-thumb-active-bg: lighten($component-active-bg, 35%) !default;
$custom-range-thumb-disabled-bg: $gray-500 !default;

$custom-file-height: $input-height !default;
$custom-file-height-inner: $input-height-inner !default;
$custom-file-focus-border-color: $input-focus-border-color !default;
$custom-file-focus-box-shadow: $input-focus-box-shadow !default;
$custom-file-disabled-bg: $input-disabled-bg !default;

$custom-file-padding-y: $input-padding-y !default;
$custom-file-padding-x: $input-padding-x !default;
$custom-file-line-height: $input-line-height !default;
$custom-file-font-family: $input-font-family !default;
$custom-file-font-weight: $input-font-weight !default;
$custom-file-color: $input-color !default;
$custom-file-bg: $input-bg !default;
$custom-file-border-width: $input-border-width !default;
$custom-file-border-color: $input-border-color !default;
$custom-file-border-radius: $input-border-radius !default;
$custom-file-box-shadow: $input-box-shadow !default;
$custom-file-button-color: $custom-file-color !default;
$custom-file-button-bg: $input-group-addon-bg !default;
$custom-file-text: (
  en: "Browse"
) !default;

// Form validation

$form-feedback-margin-top: $form-text-margin-top !default;
$form-feedback-font-size: $small-font-size !default;
$form-feedback-valid-color: theme-color("success") !default;
$form-feedback-invalid-color: theme-color("danger") !default;

$form-feedback-icon-valid-color: $form-feedback-valid-color !default;
$form-feedback-icon-valid: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"),
  "#",
  "%23"
) !default;
$form-feedback-icon-invalid-color: $form-feedback-invalid-color !default;
$form-feedback-icon-invalid: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$form-feedback-icon-invalid-color}' viewBox='-2 -2 7 7'%3e%3cpath stroke='#{$form-feedback-icon-invalid-color}' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E"),
  "#",
  "%23"
) !default;

$form-validation-states: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$form-validation-states: map-merge(
  (
    "valid": (
      "color": $form-feedback-valid-color,
      "icon": $form-feedback-icon-valid
    ),
    "invalid": (
      "color": $form-feedback-invalid-color,
      "icon": $form-feedback-icon-invalid
    )
  ),
  $form-validation-states
);

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;

// Navs

$nav-link-padding-y: 0.5rem !default;
$nav-link-padding-x: 1rem !default;
$nav-link-disabled-color: $gray-600 !default;

$nav-tabs-border-color: $gray-300 !default;
$nav-tabs-border-width: $border-width !default;
$nav-tabs-border-radius: $border-radius !default;
$nav-tabs-link-hover-border-color: $gray-200 $gray-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color: $gray-700 !default;
$nav-tabs-link-active-bg: $body-bg !default;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;

$nav-pills-border-radius: $border-radius !default;
$nav-pills-link-active-color: $component-active-color !default;
$nav-pills-link-active-bg: $component-active-bg !default;

$nav-divider-color: $gray-200 !default;
$nav-divider-margin-y: $spacer * 0.5 !default;

// Navbar

$navbar-padding-y: $spacer * 0.5 !default;
$navbar-padding-x: $spacer !default;

$navbar-nav-link-padding-x: 0.5rem !default;

$navbar-brand-font-size: $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height: $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
$navbar-brand-height: $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y: ($nav-link-height - $navbar-brand-height) * 0.5 !default;

$navbar-toggler-padding-y: 0.25rem !default;
$navbar-toggler-padding-x: 0.75rem !default;
$navbar-toggler-font-size: $font-size-lg !default;
$navbar-toggler-border-radius: $btn-border-radius !default;

$navbar-dark-color: rgba($white, 0.5) !default;
$navbar-dark-hover-color: rgba($white, 0.75) !default;
$navbar-dark-active-color: $white !default;
$navbar-dark-disabled-color: rgba($white, 0.25) !default;
$navbar-dark-toggler-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"),
  "#",
  "%23"
) !default;
$navbar-dark-toggler-border-color: rgba($white, 0.1) !default;

$navbar-light-color: rgba($black, 0.5) !default;
$navbar-light-hover-color: rgba($black, 0.7) !default;
$navbar-light-active-color: rgba($black, 0.9) !default;
$navbar-light-disabled-color: rgba($black, 0.3) !default;
$navbar-light-toggler-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"),
  "#",
  "%23"
) !default;
$navbar-light-toggler-border-color: rgba($black, 0.1) !default;

$navbar-light-brand-color: $navbar-light-active-color !default;
$navbar-light-brand-hover-color: $navbar-light-active-color !default;
$navbar-dark-brand-color: $navbar-dark-active-color !default;
$navbar-dark-brand-hover-color: $navbar-dark-active-color !default;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-font-size: $font-size-base !default;
$dropdown-color: $body-color !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;
$dropdown-border-radius: $border-radius !default;
$dropdown-border-width: $border-width !default;
$dropdown-inner-border-radius: calc(
  #{$dropdown-border-radius} - #{$dropdown-border-width}
) !default;
$dropdown-divider-bg: $gray-200 !default;
$dropdown-divider-margin-y: $nav-divider-margin-y !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;

$dropdown-link-color: $gray-900 !default;
$dropdown-link-hover-color: darken($gray-900, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;

$dropdown-link-active-color: $component-active-color !default;
$dropdown-link-active-bg: $component-active-bg !default;

$dropdown-link-disabled-color: $gray-600 !default;

$dropdown-item-padding-y: 0.25rem !default;
$dropdown-item-padding-x: 1.5rem !default;

$dropdown-header-color: $gray-600 !default;

// Pagination

$pagination-padding-y: 0.5rem !default;
$pagination-padding-x: 0.75rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $link-color !default;
$pagination-bg: $white !default;
$pagination-border-width: $border-width !default;
$pagination-border-color: $gray-300 !default;

$pagination-focus-box-shadow: $input-btn-focus-box-shadow !default;
$pagination-focus-outline: 0 !default;

$pagination-hover-color: $link-hover-color !default;
$pagination-hover-bg: $gray-200 !default;
$pagination-hover-border-color: $gray-300 !default;

$pagination-active-color: $component-active-color !default;
$pagination-active-bg: $component-active-bg !default;
$pagination-active-border-color: $pagination-active-bg !default;

$pagination-disabled-color: $gray-600 !default;
$pagination-disabled-bg: $white !default;
$pagination-disabled-border-color: $gray-300 !default;

// Jumbotron

$jumbotron-padding: 2rem !default;
$jumbotron-color: null !default;
$jumbotron-bg: $gray-200 !default;

// Cards

$card-spacer-y: 0.75rem !default;
$card-spacer-x: 1.25rem !default;
$card-border-width: $border-width !default;
$card-border-radius: $border-radius !default;
$card-border-color: rgba($black, 0.125) !default;
$card-inner-border-radius: calc(
  #{$card-border-radius} - #{$card-border-width}
) !default;
$card-cap-bg: rgba($black, 0.03) !default;
$card-cap-color: null !default;
$card-color: null !default;
$card-bg: $white !default;

$card-img-overlay-padding: 1.25rem !default;

$card-group-margin: $grid-gutter-width * 0.5 !default;
$card-deck-margin: $card-group-margin !default;

$card-columns-count: 3 !default;
$card-columns-gap: 1.25rem !default;
$card-columns-margin: $card-spacer-y !default;

// Tooltips

$tooltip-font-size: $font-size-sm !default;
$tooltip-max-width: 200px !default;
$tooltip-color: $white !default;
$tooltip-bg: $black !default;
$tooltip-border-radius: $border-radius !default;
$tooltip-opacity: 0.9 !default;
$tooltip-padding-y: 0.25rem !default;
$tooltip-padding-x: 0.5rem !default;
$tooltip-margin: 0 !default;

$tooltip-arrow-width: 0.8rem !default;
$tooltip-arrow-height: 0.4rem !default;
$tooltip-arrow-color: $tooltip-bg !default;

// Form tooltips must come after regular tooltips
$form-feedback-tooltip-padding-y: $tooltip-padding-y !default;
$form-feedback-tooltip-padding-x: $tooltip-padding-x !default;
$form-feedback-tooltip-font-size: $tooltip-font-size !default;
$form-feedback-tooltip-line-height: $line-height-base !default;
$form-feedback-tooltip-opacity: $tooltip-opacity !default;
$form-feedback-tooltip-border-radius: $tooltip-border-radius !default;

// Popovers

$popover-font-size: $font-size-sm !default;
$popover-bg: $white !default;
$popover-max-width: 276px !default;
$popover-border-width: $border-width !default;
$popover-border-color: rgba($black, 0.2) !default;
$popover-border-radius: $border-radius-lg !default;
$popover-box-shadow: 0 0.25rem 0.5rem rgba($black, 0.2) !default;

$popover-header-bg: darken($popover-bg, 3%) !default;
$popover-header-color: $headings-color !default;
$popover-header-padding-y: 0.5rem !default;
$popover-header-padding-x: 0.75rem !default;

$popover-body-color: $body-color !default;
$popover-body-padding-y: $popover-header-padding-y !default;
$popover-body-padding-x: $popover-header-padding-x !default;

$popover-arrow-width: 1rem !default;
$popover-arrow-height: 0.5rem !default;
$popover-arrow-color: $popover-bg !default;

$popover-arrow-outer-color: fade-in($popover-border-color, 0.05) !default;

// Toasts

$toast-max-width: 350px !default;
$toast-padding-x: 0.75rem !default;
$toast-padding-y: 0.25rem !default;
$toast-font-size: 0.875rem !default;
$toast-color: null !default;
$toast-background-color: rgba($white, 0.85) !default;
$toast-border-width: 1px !default;
$toast-border-color: rgba(0, 0, 0, 0.1) !default;
$toast-border-radius: 0.25rem !default;
$toast-box-shadow: 0 0.25rem 0.75rem rgba($black, 0.1) !default;

$toast-header-color: $gray-600 !default;
$toast-header-background-color: rgba($white, 0.85) !default;
$toast-header-border-color: rgba(0, 0, 0, 0.05) !default;

// Badges

$badge-font-size: 75% !default;
$badge-font-weight: $font-weight-bold !default;
$badge-padding-y: 0.25em !default;
$badge-padding-x: 0.4em !default;
$badge-border-radius: $border-radius !default;

$badge-transition: $btn-transition !default;
$badge-focus-width: $input-btn-focus-width !default;

$badge-pill-padding-x: 0.6em !default;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius: 10rem !default;

// Modals

// Padding applied to the modal body
$modal-inner-padding: 1rem !default;

$modal-dialog-margin: 0.5rem !default;
$modal-dialog-margin-y-sm-up: 1.75rem !default;

$modal-title-line-height: $line-height-base !default;

$modal-content-color: null !default;
$modal-content-bg: $white !default;
$modal-content-border-color: rgba($black, 0.2) !default;
$modal-content-border-width: $border-width !default;
$modal-content-border-radius: $border-radius-lg !default;
$modal-content-box-shadow-xs: 0 0.25rem 0.5rem rgba($black, 0.5) !default;
$modal-content-box-shadow-sm-up: 0 0.5rem 1rem rgba($black, 0.5) !default;

$modal-backdrop-bg: $black !default;
$modal-backdrop-opacity: 0.5 !default;
$modal-header-border-color: $border-color !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-header-border-width: $modal-content-border-width !default;
$modal-footer-border-width: $modal-header-border-width !default;
$modal-header-padding-y: 1rem !default;
$modal-header-padding-x: 1rem !default;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 500px !default;
$modal-sm: 300px !default;

$modal-fade-transform: translate(0, -50px) !default;
$modal-show-transform: none !default;
$modal-transition: transform 0.3s ease-out !default;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y: 0.75rem !default;
$alert-padding-x: 1.25rem !default;
$alert-margin-bottom: 1rem !default;
$alert-border-radius: $border-radius !default;
$alert-link-font-weight: $font-weight-bold !default;
$alert-border-width: $border-width !default;

$alert-bg-level: -10 !default;
$alert-border-level: -9 !default;
$alert-color-level: 6 !default;

// Progress bars

$progress-height: 1rem !default;
$progress-font-size: $font-size-base * 0.75 !default;
$progress-bg: $gray-200 !default;
$progress-border-radius: $border-radius !default;
$progress-box-shadow: inset 0 0.1rem 0.1rem rgba($black, 0.1) !default;
$progress-bar-color: $white !default;
$progress-bar-bg: theme-color("primary") !default;
$progress-bar-animation-timing: 1s linear infinite !default;
$progress-bar-transition: width 0.6s ease !default;

// List group

$list-group-color: null !default;
$list-group-bg: $white !default;
$list-group-border-color: rgba($black, 0.125) !default;
$list-group-border-width: $border-width !default;
$list-group-border-radius: $border-radius !default;

$list-group-item-padding-y: 0.75rem !default;
$list-group-item-padding-x: 1.25rem !default;

$list-group-hover-bg: $gray-100 !default;
$list-group-active-color: $component-active-color !default;
$list-group-active-bg: $component-active-bg !default;
$list-group-active-border-color: $list-group-active-bg !default;

$list-group-disabled-color: $gray-600 !default;
$list-group-disabled-bg: $list-group-bg !default;

$list-group-action-color: $gray-700 !default;
$list-group-action-hover-color: $list-group-action-color !default;

$list-group-action-active-color: $body-color !default;
$list-group-action-active-bg: $gray-200 !default;

// Image thumbnails

$thumbnail-padding: 0.25rem !default;
$thumbnail-bg: $body-bg !default;
$thumbnail-border-width: $border-width !default;
$thumbnail-border-color: $gray-300 !default;
$thumbnail-border-radius: $border-radius !default;
$thumbnail-box-shadow: 0 1px 2px rgba($black, 0.075) !default;

// Figures

$figure-caption-font-size: 90% !default;
$figure-caption-color: $gray-600 !default;

// Breadcrumbs

$breadcrumb-padding-y: 0.75rem !default;
$breadcrumb-padding-x: 1rem !default;
$breadcrumb-item-padding: 0.5rem !default;

$breadcrumb-margin-bottom: 1rem !default;

$breadcrumb-bg: $gray-200 !default;
$breadcrumb-divider-color: $gray-600 !default;
$breadcrumb-active-color: $gray-600 !default;
$breadcrumb-divider: quote("/") !default;

$breadcrumb-border-radius: $border-radius !default;

// Carousel

$carousel-control-color: $white !default;
$carousel-control-width: 15% !default;
$carousel-control-opacity: 0.5 !default;
$carousel-control-hover-opacity: 0.9 !default;
$carousel-control-transition: opacity 0.15s ease !default;

$carousel-indicator-width: 30px !default;
$carousel-indicator-height: 3px !default;
$carousel-indicator-hit-area-height: 10px !default;
$carousel-indicator-spacer: 3px !default;
$carousel-indicator-active-bg: $white !default;
$carousel-indicator-transition: opacity 0.6s ease !default;

$carousel-caption-width: 70% !default;
$carousel-caption-color: $white !default;

$carousel-control-icon-width: 20px !default;

$carousel-control-prev-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"),
  "#",
  "%23"
) !default;
$carousel-control-next-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"),
  "#",
  "%23"
) !default;

$carousel-transition-duration: 0.6s !default;
$carousel-transition: transform $carousel-transition-duration ease-in-out !default; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)

// Spinners

$spinner-width: 2rem !default;
$spinner-height: $spinner-width !default;
$spinner-border-width: 0.25em !default;

$spinner-width-sm: 1rem !default;
$spinner-height-sm: $spinner-width-sm !default;
$spinner-border-width-sm: 0.2em !default;

// Close

$close-font-size: $font-size-base * 1.5 !default;
$close-font-weight: $font-weight-bold !default;
$close-color: $black !default;
$close-text-shadow: 0 1px 0 $white !default;

// Code

$code-font-size: 87.5% !default;
$code-color: $pink !default;

$kbd-padding-y: 0.2rem !default;
$kbd-padding-x: 0.4rem !default;
$kbd-font-size: $code-font-size !default;
$kbd-color: $white !default;
$kbd-bg: $gray-900 !default;

$pre-color: $gray-900 !default;
$pre-scrollable-max-height: 340px !default;

// Utilities

$displays: none, inline, inline-block, block, table, table-row, table-cell, flex,
  inline-flex !default;
$overflows: auto, hidden !default;
$positions: static, relative, absolute, fixed, sticky !default;

// Printing

$print-page-size: a3 !default;
$print-body-min-width: map-get($grid-breakpoints, "lg") !default;
