.dataElement {
	background-color: transparent;
	color: white;
	padding-left: 5px;
	font-size   : .65em;
}

table {
	border-collapse  : collapse;
	border-spacing   : 0;
	border           : 1px solid #4C74B2;
	//margin         : 0.3em 0.1em 0.2em 0.1em
}

th.dataLabel {
	line-height: 1.25;
	padding    : 0.3em;
	font-size   : .65em;
	background-color  : transparent;
	color             : white;
	border-collapse  : collapse;
	border-spacing   : 0;
	border           : 1px solid #4C74B2;
}

td.signalr-header {
	font-size       : .65em;
	background-color: #4C74B2;
	color           : white;
	padding         : 0.4em
}
td.dataElement {
	border-collapse  : collapse;
	border-spacing   : 0;
	border           : 1px solid #4C74B2;
}

a:link.signalr-header,
a:visited.signalr-header {
	font-family    : Segoe UI Semibold, sans-serif;
	font-size      : .65em;
	text-decoration: none;
	color          : white;
	margin-bottom  : -0.1em;
	float          : left
}


td.single-message {
	background-color  : #DAEAFA;
	color             : black;
	font-size         : .65em;
	text-align		  : center;
	//padding         : 0.05em 0.3em 0.2em 0.3em
}


.white-content {
	.dataElement {
		background-color: transparent;
		color: black;
	}

	table {
		border-collapse  : collapse;
		border-spacing   : 0;
		border           : 1px solid #4C74B2;
		//margin         : 0.3em 0.1em 0.2em 0.1em
	}

	th.dataLabel {
		line-height: 1.25;
		padding    : 0.3em;
		background-color  : lightgray;
		color             : black;
	}

	td.signalr-header {
		font-size       : .65em;
		background-color: #4C74B2;
		color           : white;
		padding         : 0.4em
	}

	a:link.signalr-header,
	a:visited.signalr-header {
		font-family    : Segoe UI Semibold, sans-serif;
		font-size      : .65em;
		font-weight    : bold;
		text-decoration: none;
		color          : white;
		margin-bottom  : -0.1em;
		float          : left
	}

}